<template>
  <button
    class="input-button"
    :type="type"
    :disabled="isLoading || disabled"
    @click="onClick"
    :class="color === 'blueCTA' ? 'blueCTA' : color === 'primary' ? 'cyanCTA' : 'cancelCTA'"
  >
    <span class="is-loading" v-if="isLoading">
      <i class="spinner" />
      Loading...
    </span>
    <span v-else>
      {{ label }}
    </span>
  </button>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/_variables.scss';
  .is-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner {
      border: 3px solid rgba(0, 0, 0, 0.1);
      border-left-color: #333;
      animation: spin 1s linear infinite;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .cyanCTA,
  .cancelCTA {
    margin: 0 auto;
    display: block;
  }
</style>

<script>
  export default {
    name: 'InputButton',
    props: {
      type: {
        type: String,
        validator(type) {
          return ['button', 'reset', 'submit'].includes(type);
        },
        default: 'button',
      },
      label: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        required: true,
        validator(color) {
          return ['primary', 'secondary', 'blueCTA'].includes(color);
        },
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onClick() {
        if (this.isLoading) {
          return;
        }
        this.$emit('click');
      },
    },
  };
</script>
