<template>
  <div v-if="loaded">
    <div class="overlay" />
    <!--Transfer modal-->
    <div class="modalWrapper transfer">
      <i class="icon-close" @click="$emit('cancel')" />

      <!--Active Transfer inner content-->
      <div class="transferActive" v-if="!success">
        <div class="modalTitle">
          <div class="big">Transfer</div>
        </div>

        <div class="transferCurrency">
          <span>FROM</span>
          <div class="currencyAmount">
            <i :class="`icon-${currencies[currency].icon}`" />
            {{ balance }} <em>{{ currencies[currency].display }}</em>
          </div>
          <span>TO</span>
        </div>

        <form @submit.prevent>
          <InputDropDown_V2
            v-model.trim="selectedWalletId"
            label="Select from address book"
            class="currencyStyle"
            placeholder="Select from address book"
            :items="walletList"
          />
          <span>AMOUNT</span>
          <InputText type="number" placeholder="0 BTC" v-model="transferAmount" />

          <div class="fees">
            <!--            <span>Fees: $3.5 </span>-->
            <span>
              Estimated Gas {{ gasFees[currency].toFixed(currencies[currency].decimalPlaces) }}
              {{ currencies[currency].display }}
            </span>
            <span>
              Estimated Transfer After Gas
              {{ (transferAmount - gasFees[currency]).toFixed(currencies[currency].decimalPlaces) }}
              {{ currencies[currency].display }}
            </span>
            <!--            <span>Rate: X ETH/ USD</span>-->
          </div>
          <div class="fieldGroup">
            <InputButton
              :isLoading="isLoading"
              type="submit"
              color="primary"
              label="Start Transfer"
              :disabled="disableNext"
              @click="submitTransfer"
            />
            <InputButton type="button" color="secondary" label="Cancel" @click="$emit('cancel')" />
          </div>
        </form>
      </div>

      <!--Transfer success inner modal-->
      <div class="transferSuccess" v-if="success">
        <div class="modalTitle">
          <div class="big">Success</div>
          <div class="small">Your transfer has been successful</div>
        </div>
        <div class="transferCurrency">
          <div class="currencyAmount">
            <i :class="`icon-${currencies[currency].icon}`" />
            {{ this.transferAmount }}<em>&nbsp;{{ currencies[currency].display }}</em>
          </div>
          <span>TO</span>
        </div>
        <div class="transferedTo">
          <i class="initials">{{ selectedWallet.name.substring(0, 2) }}</i>
          <span>{{ selectedWallet.name }}</span>
          <em>{{ selectedWallet.address }}</em>
          <div class="addressCurrency">
            <i :class="`icon-${currencies[currency].icon}`" />
            <span>{{ currencies[currency].display }}</span>
          </div>
        </div>
        <div class="fieldGroup">
          <InputButton
            label="Finish"
            color="primary"
            type="submit"
            :disabled="disableNext"
            @click="$emit('cancel')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';

  .transferSuccess .transferedTo {
    width: 100%;
    text-align: center;
    float: left;
    margin: 60px 0;

    .initials {
      font-style: normal;
      font-size: 25px;
      background: #e8eefe;
      color: $lightblue;
      text-align: center;
      width: 70px;
      height: 70px;
      border-radius: 100px;
      display: inline-block;
      padding-top: 15px;
    }

    span {
      float: left;
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 0px 0;
    }

    em {
      font-size: 17px;
      font-style: normal;
      float: left;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .transferSuccess .addressCurrency {
    margin: auto;
    display: block;
    width: auto;

    i {
      float: none;
      display: inline-block;
      width: 30px;
      height: 30px;
      padding-top: 7px;
      text-align: center;
      background: $white;
      box-shadow: 10px 2px 20px 0px rgba(5, 29, 86, 0.1);
      border-radius: 50px;
      border: 1px solid $greyblue;
      position: relative;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 0px;
      margin-left: 7px;
      display: inline-block;
      float: none;
      width: auto;
    }

    i.icon-bitcoin {
      transform: rotate(-12deg);
    }

    i.icon-bitcoin:before {
      transform: rotate(-80deg);
      color: $white;
      z-index: 1;
      position: relative;
      top: 0px;
    }

    i.icon-bitcoin:after {
      width: 20px;
      height: 20px;
      background: #ff9800;
      position: absolute;
      content: '';
      transform: rotate(12deg);
      left: 4px;
      top: 4px;
      border-radius: 50px;
    }
  }

  .transferSuccess .transferCurrency {
    text-align: center;
  }

  .transferSuccess .transferCurrency span {
    position: relative;
    float: none;
    width: auto;
    background: #f7f9fc;
    z-index: 1;
    padding: 5px 0;
    top: 10px;
  }

  .transferSuccess .transferCurrency span:before {
    content: '';
    position: absolute;
    top: -27px;
    left: 7px;
    z-index: -1;
    opacity: 0.4;
    background: $grey;
    height: 74px;
    width: 1px;
  }

  .transfer {
    height: 780px;
    margin-top: -390px;
  }

  .modalWrapper .cyanCTA,
  .modalWrapper .cancelCTA {
    margin: auto;
    display: block;
  }

  .fees {
    float: left;
    width: 100%;
    margin: 30px 0;

    span {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  form span {
    width: 100%;
    float: left;
    text-align: center;
    color: $grey;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  form .input {
    float: left;
    width: 100%;
  }

  .dropdown-list {
    margin-top: 10px;
  }

  .transferCurrency {
    float: left;
    width: 100%;

    span {
      width: 100%;
      float: left;
      text-align: center;
      color: $grey;
      font-size: 12px;
      font-weight: 600;
    }

    .currencyAmount {
      width: 100%;
      float: left;
      text-align: center;
      position: relative;
      font-size: 37px;
      font-weight: 300;
      margin: 20px 0;

      em {
        font-size: 16px;
        font-style: normal;
        position: relative;
        top: -7px;
      }

      i {
        font-size: 27px;
        top: 5px;
        position: relative;
        width: 40px;
        height: 40px;
        display: inline-block;
      }

      i:after {
        content: '';
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 100px;
        left: 0px;
        top: 0px;
        display: inline-block;
        z-index: 0;
      }

      i:before {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 1;
      }

      i.icon-bitcoin {
        transform: rotate(-15deg);
        display: inline-block;
      }

      i.icon-bitcoin:after {
        background: #f7931a;
      }

      i.icon-bitcoin:before {
        color: $white;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .modalWrapper.transfer {
      height: 90vh;
    }
  }
</style>

<script>
  import InputButton from '../../@components/InputButton.vue';
  import InputText from '../../@components/InputText.vue';
  import InputDropDown_V2 from '../../@components/InputDropDown_V2.vue';
  import services from '../../../@libs/services';
  import httpClient from '../../../@libs/httpClient';
  import { apiEndpoints, currencies } from '../../../@enums';
  import Currencies from '../../../@enums/currencies';

  export default {
    name: 'Transfer',
    components: { InputText, InputDropDown_V2, InputButton },
    props: {
      balance: {
        type: String,
        required: true,
      },
      currency: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isLoading: false,
        loaded: false,
        success: false,
        transferAmount: '0',
        selectedWalletId: null,
        savedWallets: [],
        currencies: Currencies,
        gasFees: null,
      };
    },
    computed: {
      selectedWallet() {
        if (this.selectedWalletId === null) return null;
        return this.savedWallets.find((a) => a.id === this.selectedWalletId);
      },
      userId() {
        return this.$store.state.userDetails.userDetail.id;
      },
      walletList() {
        const filteredWalletList = this.savedWallets.filter(
          (a) => a.currency.toLowerCase() === this.currency,
        );
        return filteredWalletList.map((a) => ({
          text: `${a.name} - ${a.address}`,
          prepend: `icon-${currencies[a.currency.toLowerCase()].icon}`,
          value: a.id,
        }));
      },
      disableNext() {
        const decimalPlaces = this.transferAmount.split('.')[1] || '';
        const gasFee = this.gasFees[this.currency];

        const tooManyDecimals = decimalPlaces.length > this.currencies[this.currency].decimalPlaces;

        return (
          tooManyDecimals ||
          this.selectedWalletId === null ||
          parseFloat(this.transferAmount) > parseFloat(this.balance) ||
          parseFloat(this.transferAmount) === 0 ||
          gasFee > this.balance
        );
      },
    },
    methods: {
      async submitTransfer() {
        try {
          this.isLoading = true;
          const result = await httpClient.post(apiEndpoints.userDetails.withdrawalCrypto, {
            replace: {
              id: this.userId,
            },
            body: {
              currency: this.currency,
              amount: this.transferAmount,
              address: this.selectedWallet.address,
            },
            parseJson: false,
          });
          this.success = true;
        } finally {
          this.isLoading = false;
        }
      },
      async loadData() {
        const userDetails = await services.userDetailService.findById(this.userId);
        const gasFees = await services.userDetailService.getGasFees(this.userId);
        this.savedWallets = userDetails.savedWallets;
        this.gasFees = gasFees;
        this.loaded = true;
      },
    },
    async beforeMount() {
      await this.loadData();

      const decimalPlaces = currencies[this.currency].decimalPlaces;
      this.transferAmount = parseFloat(this.balance).toFixed(decimalPlaces);

      if (this.savedWallets.length === 0) await this.$router.push({ path: '/address-book' });
    },
  };
</script>
