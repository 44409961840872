<template>
  <div class="page-content">
    <section id="hero">
      <div class="row inner-section">
        <div class="text col">
          <h3>Welcome to BitJem, the Gift Card Redeemable for Bitcoin!</h3>
          <ul style="margin-left: 20px">
            <li><p>Redeem your gift code for Bitcoin</p></li>
            <li><p>The Bitcoin gets safely stored in your free BitJem wallet</p></li>
            <li><p>Enjoy the freedom of using your Bitcoin how you like</p></li>
          </ul>
          <div class="sign-up-button">
            <a @click="signUpRedirect" class="sign-up" type="button">SIGN UP TO BITJEM</a>
          </div>
        </div>
        <div class="image col">
          <img src="../../@assets/img/home/section-1-image.png" />
        </div>
      </div>
    </section>
    <section id="section-1">
      <div class="inner-section">
        <div class="text">
          <h3>What can I do with a BitJem</h3>
          <p>The simplest way to get into Bitcoin</p>
        </div>
        <div class="icons row">
          <div class="col col-1-3">
            <img src="../../@assets/img/home/section-2-icon-1.svg" />
            <h6>Instant Payout</h6>
          </div>
          <div class="col col-1-3">
            <img src="../../@assets/img/home/section-2-icon-2.svg" />
            <h6>Send to a friend</h6>
          </div>
          <div class="col col-1-3">
            <img src="../../@assets/img/home/section-2-icon-3.svg" />
            <h6>Buy now, Redeem later</h6>
          </div>
        </div>
      </div>
    </section>
    <section id="section-2">
      <div class="inner-section">
        <div class="row">
          <div class="col col-1-2 text">
            <h3>Straightforward KYC</h3>
            <p>A simple automated process and you are done.</p>
          </div>
          <div class="col col-1-2 image">
            <img src="../../@assets/img/home/section-3-image-1.png" />
          </div>
        </div>

        <div class="row">
          <div class="col col-1-2 image">
            <img src="../../@assets/img/home/section-3-image-2.png" />
          </div>

          <div class="col col-1-2 text">
            <h3>Instant payout to a Free and Secure Bitcoin Wallet</h3>
            <p>Redeem for Bitcoin instantly!</p>
          </div>
        </div>

        <div class="row">
          <div class="col col-1-2 text">
            <h3>Available in 100+ countries</h3>
            <p>People worldwide can now get Bitcoin through BitJem!</p>
          </div>
          <div class="col col-1-2 image">
            <img src="../../@assets/img/home/section-3-image-3.png" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../@assets/styles/_home.scss';

  .sign-up {
    display: inline-block;
    background-color: $default_bg_color;
    border: 0.3rem solid $heading_color;
    border-radius: 5px;
    padding: 1rem 2rem;
    margin-right: 1.2rem;
    font-weight: bold;
    font-size: 1.5rem;
    // line-height: 96.24%;
    text-align: center;
    text-transform: uppercase;
    color: $heading_color;
    text-decoration: none;
    transition: 0.5s ease-in-out;
    &:hover {
      border-color: $hover_border_color;
      transform: scale(1.1);
    }
  }
  .sign-up-button {
    margin: 3rem 0rem 0rem 0.2rem;
  }

  li {
    list-style-type: disc;
  }
</style>

<script>
  export default {
    name: 'Home',
    computed: {
      loggedIn() {
        return this.$store.state.userDetails.loggedIn || localStorage.getItem('refreshToken');
      },
    },
    methods: {
      signUpRedirect() {
        this.$router.push({ path: '/login', query: { registerNew: true } });
      },
    },
    created() {
      // this logic throws an error so we catch not to confuse user. This belongs in the router.
      if (this.loggedIn) {
        this.$router.replace({ path: '/dashboard' }).catch((e) => console.error(e));
      }
    },
  };
</script>
