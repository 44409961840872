<template>
  <div class="mainContainer createGC">
    <Banner
      :show.sync="showBanner"
      :autohide="true"
      :color="bannerData.color"
      :label="bannerData.label"
      :message="bannerData.message"
    />
    <div class="innerContainer">
      <div class="fullRow titleBox">
        <img alt="present" src="../../../@assets/img/dashboard/redeem-1.svg" />
        <h2>Create New Gift Card<span>Please enter currency and value below </span></h2>
      </div>
      <div class="form">
        <InputDropDown_V2
          v-model="currency"
          label="Currency"
          class="currencyStyle"
          placeholder="Currency"
          :items="[{ text: 'BTC Bitcoin', prepend: 'icon-bitcoin', value: 'BTC' }]"
        />

        <div v-if="newGiftCardCode && newGiftCardPin" class="fullRow code-pin">
          <h3>
            Code: <span>{{ newGiftCardCode }}</span>
          </h3>
          <h3>
            PIN: <span>{{ newGiftCardPin }}</span>
          </h3>
          <h3>
            Value: <span>{{ value }} $</span>
          </h3>
        </div>

        <InputText v-model.trim="value" label="Value" placeholder="Value (100.00)" type="number" />
        <InputButton
          :isLoading="isLoading"
          color="primary"
          @click="createNew()"
          type="submit"
          label="Create"
          :disabled="disabledSumbitButton"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';

  .code-pin {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-top: 0;
    padding: 2rem;
    margin-bottom: 4rem;
    h3 {
      margin-bottom: 0px;
      // padding: 0px;
      font-size: 2rem;
      color: $black;
      color: $lightblue;
      span {
        color: $lightblue;
        color: $black;
        font-style: italic;
        font-weight: 900;
      }
    }
  }
  .createGC img {
    display: inline-block;
    margin-right: 20px;
    top: 10px;
    position: absolute;
    left: 0;
  }

  .createGC .form {
    margin-top: 20px;
    float: left;
    width: 100%;
    .input {
      margin-bottom: 30px;
      float: left;
      width: 100%;
    }
    .currencyStyle {
      margin-bottom: 3rem;
    }
  }
</style>

<script>
  import services from '../../../@libs/services';
  import InputText from '../../@components/InputText.vue';
  import InputDropDown_V2 from '../../@components/InputDropDown_V2.vue';
  import InputButton from '../../@components/InputButton.vue';
  import Banner from '../../@components/Banner.vue';

  export default {
    name: 'GCCreateNew',
    components: { Banner, InputButton, InputText, InputDropDown_V2 },
    data() {
      return {
        currency: 'BTC',
        value: 0.0,
        newGiftCardCode: null,
        newGiftCardPin: null,
        isLoading: false,
        showBanner: false,
        bannerData: {
          label: '',
          message: '',
          color: 'success',
        },
      };
    },
    computed: {
      disabledSumbitButton() {
        return this.value === 0.0;
      },
    },
    methods: {
      async createNew() {
        try {
          this.isLoading = true;

          const result = await services.giftCardService.createNew(this.currency, this.value);
          this.newGiftCardCode = result.code;
          this.newGiftCardPin = result.pin;

          this.currency = 'BTC';

          this.bannerData.color = 'success';
          this.bannerData.label = 'Create Gift Card Success';
          this.bannerData.message = "Success! You've created a new  Gift Code and PIN!";
        } catch (error) {
          this.bannerData.color = 'error';
          this.bannerData.label = 'Create Gift Card Error';
          this.bannerData.message =
            // eslint-disable-next-line no-multi-str
            'Something went wrong creating gift card, please verify the information you entered is\
        correct!';
        } finally {
          this.isLoading = false;
          this.showBanner = true;
        }
      },
    },
  };
</script>
