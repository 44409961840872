<template>
  <div class="flex-row sum-row">
    <div class="flex-col col-1-2">
      <span>
        <h6>{{ label }}</h6>
      </span>
      <img v-if="showHelp" src="../../@assets/img/dashboard/question.svg" alt="help" />
    </div>
    <div class="flex-col col-1-2" @click="$emit('input', !value)">
      <div :class="['checkbox', { checked: value }]"><span /></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/_variables.scss';

  .checkbox {
    width: 5.8rem;
    padding: 0.1rem;
    background: #dde7ff;
    border-radius: 2.6rem;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;

    span {
      display: inline-block;
      width: 2.3rem;
      height: 2.3rem;
      color: red;
      background: #b1c3ee;
      border-radius: 50%;
      margin: 0.2rem;
    }
  }

  h6 {
    font-size: 2.3rem !important;
    margin: 0.5rem 0rem;
    color: $petrol;
    text-shadow: 0.5px 0.5px black;
  }
  .checkbox.checked {
    justify-content: flex-end;

    span {
      background: $petrol;
    }
  }

  @media screen and (max-width: 500px) {
    h6 {
      font-size: 1.9rem !important;
    }
    .flex-row {
      .flex-col {
        padding: 0 1rem;
      }
    }
  }
</style>

<script>
  export default {
    name: 'InputCheck',
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: Boolean,
        required: true,
      },
      showHelp: {
        default: true,
        type: Boolean,
      },
    },
  };
</script>
