<template>
  <div v-if="hasPendingCards" class="pending-cards-box">
    <div v-if="isLevel2">
      You have card(s) pending redemption. These cards will automatically redeem within a few
      minutes. There is no need to attempt to redeem them again.
    </div>
    <div v-else>
      You have cards pending redemption. Please complete the
      <router-link to="/giftCard/pii">verification</router-link> process.
    </div>
  </div>
</template>

<style scoped lang="scss">
  .pending-cards-box {
    color: white;
    font-size: 1.8rem;
    margin: 10px 10px 10px 10px;
    padding: 20px;
    background: orange;
    border-radius: 15px;
    box-shadow: 0px 5px 10px 0px #ccc;
    border: 2px;
  }
</style>

<script>
  import giftCardService from '../../@libs/services/giftCardService';

  export default {
    name: 'PendingCardsBanner',
    computed: {
      hasPendingCards() {
        return this.$store.state.userDetails.hasPendingCards;
      },
      isLevel2() {
        return this.$store.state.userDetails.userDetail.isLevel2;
      },
    },
    watch: {
      async isLevel2() {
        await giftCardService.listAllUsersPendingCardsCards();
      },
    },
    async mounted() {
      await giftCardService.listAllUsersPendingCardsCards();
    },
  };
</script>
