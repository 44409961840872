<template>
  <div class="generalMainContainer">
    <Banner
      @dismiss="showBanner = !showBanner"
      :show.sync="showBanner"
      :autohide="bannerData.autohide"
      :label="bannerData.label"
      :message="bannerData.message"
      :color="bannerData.color"
    />
    <form v-if="!resetSuccess" id="resetPasswordForm" @submit.prevent="handlePasswordReset">
      <div>
        <h2>Reset Password</h2>
      </div>
      <div class="loginInnerContainer">
        <div class="resetPasswordInner">
          <div class="inputText">
            <InputText
              placeholder="New Password"
              id="password"
              v-model.trim="password"
              label="New Password"
              type="password"
            />
          </div>
          <div class="inputText">
            <InputText
              placeholder="Confirm Password"
              id="confirmPassword"
              v-model.trim="confirmPassword"
              label="Confirm Password"
              type="password"
            />
          </div>

          <InputButton
            :isLoading="isLoading"
            label="Reset"
            :disabled="resetButtonEnabled"
            color="primary"
            type="submit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';
</style>

<script>
  import services from '../../@libs/services';
  import InputText from '../@components/InputText.vue';
  import InputButton from '../@components/InputButton.vue';
  import Banner from '../@components/Banner.vue';

  export default {
    name: 'PasswordReset',
    components: { InputText, InputButton, Banner },
    data() {
      return {
        resetSuccess: false,
        password: '',
        confirmPassword: '',
        isLoading: false,
        showBanner: false,
        bannerData: {
          color: null,
          label: '',
          message: '',
          autohide: 5000,
        },
      };
    },
    computed: {
      resetButtonEnabled() {
        return this.password.length < 8 || this.password !== this.confirmPassword;
      },
    },
    methods: {
      async handlePasswordReset() {
        try {
          this.isLoading = true;

          const resetCode = this.$route.query.resetCode;
          await services.userDetailService.resetPasswordWithCode({
            resetCode,
            password: this.password,
          });

          this.resetSuccess = true;

          this.bannerData = {
            color: 'success',
            label: 'Password Reset Success',
            message: 'Your password has successfully changed!',
            autohide: this.bannerData.autohide,
          };
        } catch (error) {
          this.bannerData = {
            color: 'error',
            label: 'Password Reset Error',
            message: error.toString(),
            autohide: this.bannerData.autohide,
          };
        } finally {
          this.showBanner = true;
          this.isLoading = false;
          setTimeout(() => {
            this.$router.push({ path: '/login' });
          }, this.bannerData.autohide);
        }
      },
    },
  };
</script>
