import Vue from 'vue';
import VueMask from 'v-mask';
import VCalendar from 'v-calendar';
import VueGtag from 'vue-gtag';
import App from './App/App.vue';
import router from './@libs/router';
import store from './@store';
import vuetify from './plugins/vuetify';
import registerGlobalEventHandlers from './@libs/eventBus/globalEventHandlers';
import './vue.filters';

import './@@layouts';
import eventBus from './@libs/eventBus';

Vue.use(
  VueGtag,
  {
    config: { id: 'G-8V4J907WJX' },
  },
  router,
);

Vue.use(VueMask);
Vue.use(VCalendar, { componentPrefix: 'vc' });

Vue.config.productionTip = false;

Vue.config.errorHandler = function (_error) {
  const error = _error || new Error('Unknown Error: Vue errorHandler');
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  console.error(error);
  eventBus.raiseEvent({ name: 'error', payload: error });
};

window.onerror = function (message, source, line, column, _error) {
  const error = _error || new Error('Unknown Error: onError listener');
  console.error(error);
  eventBus.raiseEvent({ name: 'error', payload: error });
};

window.addEventListener('unhandledrejection', (promiseRejectionEvent) => {
  promiseRejectionEvent.promise.catch((_error) => {
    const error = _error || new Error('Unknown Error: unhandledrejection listener');
    console.error(error);
    eventBus.raiseEvent({ name: 'error', payload: error });
  });
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

registerGlobalEventHandlers();
