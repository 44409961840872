<template>
  <ul id="sideMenu" :class="{ mobileOpen }" @click="$emit('update:mobileOpen', !mobileOpen)">
    <li class="active">
      <span>Wallet</span>
      <router-link to="/dashboard"><i class="icon-wallet" />Wallet</router-link>
    </li>
    <li>
      <span>Gift Cards</span>
      <router-link v-if="containsPermission('CreateGiftCard')" to="/giftCard/create">
        <i class="icon-create" />Create
      </router-link>
      <router-link v-if="containsPermission('RedeemGiftCard')" to="/giftCard/redemption">
        <i class="icon-redeem" />Redeem
      </router-link>
      <router-link v-if="containsPermission('RedeemGiftCard')" to="/giftCard/balance-check">
        <i class="icon-balance" />Balance
      </router-link>
      <router-link v-if="containsPermission('RedeemGiftCard')" to="/giftCard/send">
        <i class="icon-transfer" />Send
      </router-link>
      <router-link v-if="containsPermission('RecreateGiftCard')" to="/giftCard/recreate">
        <i class="icon-recreate" />Recreate
      </router-link>
      <router-link v-if="containsPermission('ViewGroupLedgers')" to="/ledger">
        <i class="icon-history" />Ledger
      </router-link>
    </li>
    <li>
      <span>Personal</span>
      <router-link to="/account-details"><i class="icon-profile" />Profile</router-link>
      <router-link to="/address-book">
        <i class="icon-addressbook" />
        <div class="longLink">Address Book</div></router-link
      >
      <router-link to="/tickets"><i class="icon-help" />Help</router-link>
    </li>
  </ul>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables';

  .transBox.canceled i:after {
    background: rgba(1, 14, 45, 0.05);
    border: 1px solid rgba(1, 14, 45, 0.1);
  }

  .transBox.canceled i:before {
    color: rgba(1, 14, 45, 0.3);
  }

  .mobileOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background: $black;
  }

  /*----Sidemenu CSS------*/
  #sideMenu {
    float: left;
    width: 153px;
    padding: 35px 15px;
    height: calc(100vh - 50px);
    position: fixed;
    top: 100px;
    left: 0;
    transition: $small;
    overflow-y: auto;
    overflow-x: hidden;
    li {
      float: left;
      width: 100%;
      margin-bottom: 3.5vh;
      a {
        font-size: 18px;
        font-weight: 600;
        color: $black;

        opacity: 0.5;
        padding: 10px 15px;
        float: left;
        border-radius: 5px;
        letter-spacing: -0.7px;
        transition: $small;
        i {
          color: $black;
          opacity: 0.5;
          font-size: 26px;
          position: relative;
          top: 4px;
          margin-right: 10px;
          transition: $small;
        }
        i.icon-recreate {
          font-size: 20px;
        }
        .longLink {
          font-size: 18px;
          line-height: 18px;
          opacity: 1;
          width: 39px;
          display: inline-block;
          position: relative;
          top: 7px;
        }
      }
      a:hover {
        opacity: 0.85;
        i {
          opacity: 0.85;
        }
      }
      span {
        float: left;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        color: $black;
        opacity: 0.5;
        margin-bottom: 10px;
        transition: $small;
      }
    }
    li.active {
      opacity: 1;
      span {
        opacity: 1;
      }
      a.activeLink {
        background: $white;
        color: $blue;
        opacity: 1;
        box-shadow: 1px 10px 40px 0px rgba(1, 14, 45, 0.09);
        i {
          color: $blue;
          opacity: 1;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .hasPromo #sideMenu {
      top: 180px;
    }
  }
</style>

<script>
  export default {
    name: 'Navigation',
    props: {
      mobileOpen: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      containsPermission(permission) {
        return this.$store.getters['userDetails/permissions'].some(
          (a) => a.toLowerCase() === permission.toLowerCase(),
        );
      },
    },
  };
</script>
