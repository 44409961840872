<template>
  <div class="mainContainer balanceGC">
    <ClosingBanner />
    <Banner
      @dismiss="showBanner = !showBanner"
      :show.sync="showBanner"
      :autohide="true"
      :label="bannerData.label"
      :message="bannerData.message"
      :color="bannerData.color"
    />
    <div class="innerContainer">
      <div class="fullRow titleBox">
        <img alt="present" src="../../../@assets/img/dashboard/redeem-1.svg" />
        <h2>
          Balance Check Gift Card
          <span> Please enter details </span>
        </h2>
      </div>
      <div class="form">
        <InputText v-model.trim="code" label="Code" placeholder="Code" />
        <InputText v-model.trim="pin" label="PIN" placeholder="PIN" />
        <InputButton
          type="submit"
          @click="checkBalance"
          label="Check Balance"
          :disabled="disableSubmitButton"
          color="primary"
          :isLoading="isLoading"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';

  .balanceGC img {
    display: inline-block;
    margin-right: 20px;
    top: 10px;
    position: absolute;
    left: 0;
  }

  .balanceGC .form {
    margin-top: 50px;
    float: left;
    width: 100%;
    .input {
      margin-bottom: 30px;
    }
  }
</style>

<script>
  import _ from 'lodash';
  import services from '../../../@libs/services';
  import InputText from '../../@components/InputText.vue';
  import InputButton from '../../@components/InputButton.vue';
  import Banner from '../../@components/Banner.vue';
  import ClosingBanner from '../../../@@layouts/Default/ClosingBanner/ClosingBanner.vue';

  export default {
    name: 'BalanceCheck',
    components: { ClosingBanner, InputButton, InputText, Banner },
    data() {
      return {
        code: '',
        pin: '',
        value: 0,
        isLoading: false,
        showBanner: false,
        bannerData: {
          label: '',
          message: '',
          color: 'success',
        },
      };
    },
    computed: {
      disableSubmitButton() {
        return this.code === '' || this.pin === '';
      },
    },
    methods: {
      async checkBalance() {
        try {
          this.isLoading = true;
          this.value = 0;
          const result = await services.giftCardService.checkBalance(this.code, this.pin);

          this.value = result.value;

          this.bannerData.label = 'Balance Check Success';
          this.bannerData.message = `Your Gift Card Balance is "${this.value}$"`;
          this.bannerData.color = 'success';
        } catch (error) {
          this.bannerData.label = 'Balance Check Error';
          this.bannerData.message = error.toString();
          this.bannerData.color = 'error';
        } finally {
          this.showBanner = true;
          this.isLoading = false;
        }
      },
    },
  };
</script>
