import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '../../@store';
import services from '../services';
import Dashboard from '../../App/Dashboard';
import Home from '../../App/Home';
import Login from '../../App/Login';
import GCRedemption from '../../App/GiftCards/Redeem';
import GCCreateNew from '../../App/GiftCards/CreateNew';
import GroupDetails from '../../App/GroupDetails';
import AccountDetails from '../../App/AccountDetails';
import GCTransfer from '../../App/GiftCards/Send';
import GCRecreate from '../../App/GiftCards/Recreate';
import eventBus from '../eventBus';
import BalanceCheck from '../../App/GiftCards/BalanceCheck';
import Ledger from '../../App/Ledger';
import HtmlTemplates from '../../App/HtmlTemplates';
import Verification from '../../App/Verification';
import RedeemedCards from '../../App/GiftCards/RedeemedCards';
import GroupDashboard from '../../App/GroupDashboard';
import PII from '../../App/GiftCards/Redeem/PII';
import PasswordReset from '../../App/PasswordReset';
import Disable2fa from '../../App/Disable2fa';
import AddressBook from '../../App/GiftCards/AddressBook';
import TicketList from '../../App/Tickets/TicketList';
import TicketDetails from '../../App/Tickets/TicketDetails';
import Transfer from '../../App/GiftCards/Transfer';
import TermsOfService from '../../App/TermsOfService';
import PrivacyPolicy from '../../App/PrivacyPolicy';
import Faq from '../../App/Faq';
import Purchase from '../../App/GiftCards/Purchase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      allowAnonymous: true,
      layout: 'empty-layout',
      title: 'BitJem',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/groupDashboard',
    name: 'Group Dashboard',
    component: GroupDashboard,
  },
  {
    path: '/giftCard/redeemed',
    name: 'Redeemed Cards',
    component: RedeemedCards,
  },
  {
    path: '/templates/:componentName',
    name: 'HTML Templates',
    component: HtmlTemplates,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/ledger',
    name: 'Ledgers',
    component: Ledger,
  },
  {
    path: '/giftCard/redemption',
    name: 'Redeem Gift Card',
    component: GCRedemption,
  },
  {
    path: '/giftCard/pii',
    name: 'Personal Information',
    component: PII,
  },
  {
    path: '/giftCard/create',
    name: 'Create Gift Card',
    component: GCCreateNew,
  },
  {
    path: '/giftCard/send',
    name: 'Send Gift Card',
    component: GCTransfer,
  },
  {
    path: '/giftCard/recreate',
    name: 'Recreate Gift Card',
    component: GCRecreate,
  },
  {
    path: '/giftCard/balance-check',
    name: 'Balance Check Gift Card',
    component: BalanceCheck,
  },
  {
    path: '/group-details',
    name: 'Group Details',
    component: GroupDetails,
  },
  {
    path: '/account-details',
    name: 'Account Details',
    component: AccountDetails,
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer,
  },
  {
    path: '/purchase-crypto',
    name: 'Purchase',
    component: Purchase,
  },
  {
    path: '/address-book',
    name: 'Address Book',
    component: AddressBook,
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification,
  },
  {
    path: '/tickets',
    name: 'TicketList',
    component: TicketList,
  },
  {
    path: '/tickets/:id',
    name: 'TicketDetails',
    component: TicketDetails,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/disable2fa',
    name: 'Disable 2fa',
    component: Disable2fa,
  },
  {
    path: '/passwordReset',
    name: 'Reset Password',
    component: PasswordReset,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/termsOfService',
    name: 'Terms of Service',
    component: TermsOfService,
    meta: {
      allowAnonymous: true,
      title: 'Terms of Service',
    },
  },
  {
    path: '/privacyPolicy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: {
      allowAnonymous: true,
      title: 'Privacy Policy',
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: Faq,
    meta: {
      allowAnonymous: true,
      title: 'FAQ',
    },
  },
  {
    path: '/*',
    name: 'Redirect',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let initialized = false;
router.beforeEach(async (to, from, next) => {
  if (!initialized) {
    await services.userDetailService.initialize();
    initialized = true;
  }

  const {
    state: {
      userDetails: {
        loggedIn,
        userDetail: { phoneVerified = false },
      },
    },
    getters: { 'userDetails/permissions': permissions },
  } = Store;
  const {
    name: pageName,
    meta: { allowAnonymous, title },
  } = to;

  const otpRequired = permissions?.some((a) => a === 'OtpRequired') || false;
  const requiresVerification =
    permissions?.some((a) => a === 'VerifyPhone' || a === 'VerifyEmailAddress') ||
    !phoneVerified ||
    false;

  document.title = title || `BitJem - ${pageName}`;

  if (!allowAnonymous && requiresVerification && to.fullPath !== '/verification') {
    next({
      path: '/verification',
    });
  }

  if (!allowAnonymous && (!loggedIn || otpRequired)) {
    next({
      path: '/login',
      query: { redirect: to.fullPath, otpRequired },
    });
  } else {
    eventBus.raiseEvent({
      name: eventBus.eventTypes.navigation.succeeded,
      payload: to,
    });
    next();
  }
});

export default router;
