import baseService from '../baseService';
import { apiEndpoints } from '../../../@enums';

export default {
  ...baseService,
  apiEndpoints: apiEndpoints.contactUs,
  /**
   * Post a new entity
   * @param payload Required an object payload to post
   * {emailAddress, subject, body}
   * @returns {Promise<string|*>}
   */
  async contactUs(payload) {
    const result = await this.httpClient.post(apiEndpoints.contactUs.contactUs, {
      payload: {
        emailAddress: payload.emailAddress,
        body: payload.body,
        subject: payload.subject,
      },
      parseJson: false,
    });
    return result;
  },
};
