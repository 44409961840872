import _forEach from 'lodash/forEach';
import eventBus from '../eventBus';

let bearerToken = null;

/**
 * Default set of options for all http requests
 * @type {{parseJson: boolean, displayLoading: boolean}}
 */
const defaultOptions = {
  parseJson: true,
  displayLoading: true,
  includeContentType: true,
};

/**
 *  Get Bearer Token from localStorage
 * @returns {string}
 */
function getBearerToken() {
  return bearerToken;
}

/**
 * Set the bearer token
 * @param newToken
 */
function setBearerToken(newToken) {
  bearerToken = newToken;
}

/**
 *  Generates headers for http request
 * @returns {Headers}
 */
function getHeaders(includeContentType) {
  const token = getBearerToken();
  const headers = new Headers([]);

  if (includeContentType) headers.append('Content-Type', 'application/json');

  if (token !== undefined && token !== null) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  return headers;
}

/**
 *  Replace parameters in the url with actual values
 * @param url Required url that contains parameter place holders
 * @param replace Required object that contains parameter values
 * @returns {*} URL with params filled in
 */
function replaceParams(url, replace) {
  let result = url;

  if (replace) {
    _forEach(replace, (param, key) => {
      result = result.replace(`{${key}}`, param);
    });
  }

  return result;
}

/**
 *  Make an httpClient request
 * @param url Required url to make request to
 * @param method Required httpClient action
 * @param options Required options for making httpClient request
 * @returns {Promise<string|any>}
 */
async function makeRequest(url, method, options) {
  const {
    body,
    includeContentType,
    payload,
    replace,
    displayLoading,
    parseJson,
    file,
    form,
  } = options;

  if (displayLoading) {
    eventBus.raiseEvent({ name: eventBus.eventTypes.http.loadingStarted, payload: null });
  }

  const response = await fetch(replaceParams(url, replace), {
    headers: getHeaders(includeContentType),
    method,
    body: file || form || JSON.stringify(body || payload),
  });

  if (displayLoading) {
    eventBus.raiseEvent({ name: eventBus.eventTypes.http.loadingFinished, payload: null });
  }

  let responseBody;
  let data = {};

  if (response && response.ok) {
    if (parseJson) {
      data = await response.json();
    } else {
      data = await response.text();
    }
  } else if (response && (response.status === 401 || response.status === 403)) {
    responseBody = await response.json();
    responseBody = responseBody.error.msg;

    throw new Error(responseBody);
  } else {
    try {
      responseBody = await response.json();
      responseBody = responseBody.error.msg;
    } catch {
      responseBody = 'Something went wrong, please try again';
    }

    throw new Error(responseBody);
  }

  return data;
}

/**
 *  Make a request with a delay of X milliseconds
 * @param url Required url to make request to
 * @param method Required httpClient action
 * @param options Required options for making httpClient request
 * @param delayMs optional delay in MS to wait before returning the result
 * @returns {Promise<string|*>}
 */
async function makeRequestWithDelay(url, method, options, delayMs = 500) {
  const startTime = Date.now();

  const result = await makeRequest(url, method, options); // Execute the code block

  const elapsed = Date.now() - startTime;
  const remaining = delayMs - elapsed;

  if (remaining > 0) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, remaining));
  }

  return result;
}

/**
 *  Make an http get request
 * @param url Required url to make get request to
 * @param options Optional options to pass in
 * @returns {Promise<string|*>}
 */
async function get(url, options = {}) {
  return makeRequestWithDelay(url, 'get', { ...defaultOptions, ...options });
}

/**
 * Make an http post request
 * @param url Required url to make post request to
 * @param options optional options to pass in
 * @returns {Promise<string|*>}
 */
async function post(url, options = {}) {
  return makeRequestWithDelay(url, 'post', { ...defaultOptions, ...options });
}

/**
 * Make an http patch request
 * @param url Required url to make patch to
 * @param options Optional options to pass in
 * @returns {Promise<string|*>}
 */
async function patch(url, options = {}) {
  return makeRequestWithDelay(url, 'patch', { ...defaultOptions, ...options });
}

/**
 * Make an http delete request
 * @param url Required url to make delete request to
 * @param options Optional options to pass in
 * @returns {Promise<string|*>}
 */
async function del(url, options = {}) {
  return makeRequestWithDelay(url, 'delete', { ...defaultOptions, ...options });
}

/**
 * Make an http put request
 * @param url Required url to make put request to
 * @param options Optional options to pass in
 * @returns {Promise<string|*>}
 */
async function put(url, options = {}) {
  return makeRequestWithDelay(url, 'put', { ...defaultOptions, ...options });
}

export default {
  setBearerToken,
  get,
  post,
  patch,
  delete: del,
  put,
};
