<template>

  <header :class="['site-header', { scrolled }]" id="site-header">

    <a href="#" class="logo">
      <img src="../../@assets/img/logo-blue.svg" alt="Home" />
    </a>
    <div class="menu-part">
      <a href="#" class="about">ABOUT</a>

      <a href="/login" class="log-in">LOG IN</a>
      <a href="/giftCard/redemption" class="demo">REDEEM CARD</a>

    </div>
  </header>
</template>

<style lang="scss" scoped>
@import '../../@assets/styles/_header.scss';
</style>

<script>

  export default {
    name: 'Header',
    data() {
      return {
        scrolled: false,
      };
    },
    methods: {
      handleScroll() {
        if (window.scrollY > 100 && !this.scrolled) {
          this.scrolled = true;
        } else if (window.scrollY < 100) {
          this.scrolled = false;
        }
      },
      handleResize() {
        const root = document.querySelector(':root');
        let base = 62.5;
        const desktopMaxWidth = 1440;
        const tabletBreakPoint = 768;
        const w = window.innerWidth;
        if (window.innerWidth >= window.innerHeight && window.innerWidth < desktopMaxWidth) { // landscape
          base *= (w / desktopMaxWidth);
        } else if (window.innerWidth < window.innerHeight && window.innerWidth >= tabletBreakPoint) { // portrait tablet
          base *= (w / desktopMaxWidth);
        } else if (window.innerWidth < window.innerHeight && window.innerWidth < tabletBreakPoint) { // mobile
          base *= (w / desktopMaxWidth);
          base *= 2.3;
        }

        root.style.fontSize = `${base}%`;
      },
    },
    created() {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleResize);
    },
  };

</script>
