<template>
  <div class="mainContainer transferGC">
    <Banner
      :show.sync="showBanner"
      :autohide="true"
      :color="bannerData.color"
      :label="bannerData.label"
      :message="bannerData.message"
    />
    <div class="innerContainer">
      <div class="fullRow titleBox">
        <img alt="present" src="../../../@assets/img/dashboard/redeem-1.svg" />
        <h2>Send Gift Card<span>Please enter details below</span></h2>
      </div>
      <div class="fullRow">
        <div class="form">
          <InputText v-model.trim="code" label="Code" placeholder="Code" />
          <InputText v-model.trim="pin" label="PIN" placeholder="PIN" />
          <InputText
            v-model.trim="emailAddress"
            label="Email Address"
            placeholder="Email Address"
          />
          <InputText
            v-model.trim="confirmEmailAddress"
            label="Confirm Email Address"
            placeholder="Confirm Email Address"
          />
        </div>
        <InputButton
          :isLoading="isLoading"
          :disabled="disableSubmitButton"
          label="Transfer"
          color="primary"
          type="button"
          @click="transfer"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';

  .transferGC img {
    display: inline-block;
    margin-right: 20px;
    top: 10px;
    position: absolute;
    left: 0;
  }

  .transferGC .form {
    margin-top: 50px;
    .input {
      margin-bottom: 30px;
    }
  }
</style>

<script>
  import services from '../../../@libs/services';
  import InputText from '../../@components/InputText.vue';
  import InputButton from '../../@components/InputButton.vue';
  import Banner from '../../@components/Banner.vue';

  export default {
    components: { InputText, InputButton, Banner },
    data() {
      return {
        code: '',
        pin: '',
        emailAddress: '',
        confirmEmailAddress: '',
        isLoading: false,
        showBanner: false,
        bannerData: {
          label: '',
          message: '',
          color: 'success',
        },
      };
    },
    computed: {
      disableSubmitButton() {
        return (
          this.code === '' ||
          this.pin === '' ||
          this.emailAddress === '' ||
          this.emailAddress !== this.confirmEmailAddress
        );
      },
    },
    methods: {
      async transfer() {
        try {
          this.isLoading = true;

          await services.giftCardService.transferCard(this.code, this.pin, this.emailAddress);

          this.bannerData.color = 'success';
          this.bannerData.label = 'Send Gift Card Success';
          this.bannerData.message = `You successfully sent a BitJem gift card to ${this.emailAddress}!`;
        } catch (error) {
          this.bannerData.color = 'error';
          this.bannerData.label = 'Send Gift Card Error';
          this.bannerData.message =
            // eslint-disable-next-line no-multi-str
            'Something went wrong redeeming your gift card, please verify the information you entered is \
          correct!';
        } finally {
          this.isLoading = false;
          this.showBanner = true;
        }
      },
    },
    mounted() {
      if (!this.$store.state.userDetails.userDetail.isLevel2) {
        this.$router.push('/giftCard/pii');
      }
    },
  };
</script>
