import baseService from '../baseService';
import store from '../../../@store';
import { apiEndpoints } from '../../../@enums';

export default {
  ...baseService,
  apiEndpoints: apiEndpoints.giftCards,

  /**
   * Get current exchange rates
   * @returns {Promise<string|*>}
   */
  async getCurrentRates(currency) {
    const result = await this.httpClient.get(apiEndpoints.giftCards.currentRates, {
      replace: { currency },
    });

    return result;
  },

  /**
   *  Get current purchase rates
   * @param currency
   * @returns {Promise<string|*>}
   */
  async getCurrentPurchaseRates(currency) {
    const result = await this.httpClient.get(apiEndpoints.giftCards.currentPurchaseRates, {
      replace: { currency },
    });

    return result;
  },

  /**
   * Redeem gift card by code and pin
   * @param code Required code for gift card
   * @param pin Required pin for redeeming gift card
   * @param currency Required currency to redeem
   * @returns {Promise<string|*>}
   */
  async redeem(code, pin, currency) {
    const result = await this.httpClient.post(apiEndpoints.giftCards.redeem, {
      replace: {
        code,
        pin,
      },
      payload: {
        currency,
      },
    });

    return result;
  },

  /**
   * Bulk redeem gift cards
   * @param cardsAndPins Required object of cards/pins
   * @param currency Required currency
   * @returns {Promise<string|*>}
   */
  async bulkRedeem(cardsAndPins, currency) {
    const result = await this.httpClient.post(apiEndpoints.giftCards.combineAndRedeem, {
      payload: {
        cardList: cardsAndPins.reduce((a, b) => {
          // eslint-disable-next-line no-param-reassign
          a[`${b.code}`] = b.pin;
          return a;
        }, {}),
        currency,
        payFee: false,
      },
    });

    return result;
  },

  /**
   * Create new gift card
   * @param currency
   * @param value
   * @returns {Promise<string|*>}
   */
  async createNew(currency, value) {
    const newCard = { currency, value };

    const result = await this.postNew(newCard);

    return result;
  },

  /**
   * Recreate a gift card to get a new code and pin
   * @param code Required code of existing card
   * @param pin Required pin of existing card
   * @returns {Promise<{id}|string|*>}
   */
  async recreateCard(code, pin) {
    const result = await this.httpClient.post(apiEndpoints.giftCards.recreate, {
      replace: {
        code,
        pin,
      },
    });

    return result;
  },

  /**
   * Send a gift card to a new recipient
   * @param code Required code for card
   * @param pin Required pin number for card
   * @param emailAddress Required email address recipient
   * @returns {Promise<{id}|string|*>}
   */
  async transferCard(code, pin, emailAddress) {
    const result = await this.httpClient.post(apiEndpoints.giftCards.transfer, {
      replace: {
        code,
        pin,
      },
      payload: emailAddress,
    });

    return result;
  },

  /**
   * Cancel gift card
   * @param card Required existing card to pull id from
   * @returns {Promise<string|*>}
   */
  async cancelCard(card) {
    const result = await this.httpClient.put(apiEndpoints.giftCards.cancel, {
      replace: {
        id: card.id,
      },
    });

    return result;
  },

  async checkBalance(code, pin) {
    const result = await this.httpClient.get(apiEndpoints.giftCards.checkBalance, {
      replace: {
        code,
        pin,
      },
    });

    return result;
  },

  async listAllUsersPendingCardsCards() {
    const result = await this.httpClient.get(apiEndpoints.giftCards.pending, {});

    await store.commit('userDetails/updateHasPendingCards', result.length !== 0);

    return result;
  },

  async listAllUsersRedeemedCards() {
    const result = await this.httpClient.get(apiEndpoints.giftCards.redeemed, {});

    return result;
  },
};
