<template>
  <div>
    <div class="mainContainer addressBook">
      <ClosingBanner />
      <div class="innerContainer">
        <h2>Address Book</h2>
        <!--Empty State without any addresses-->
        <div class="emptyState" v-if="savedWallets.length === 0">
          <i class="icon-addressbook" />
          <div class="emptyTitle">
            You do not have any addresses yet
            <span>Start adding addresses in order make your transfers easier & faster </span>
          </div>
          <div class="blueCTA" @click="adding = true"><i class="icon-plus" />Add an address</div>
        </div>
        <!-- Active addresses layout after 1st addition-->
        <div class="activeAddresses" v-if="savedWallets.length > 0">
          <div class="topActions">
            <input-text placeholder="search" v-model="search" />
            <div class="addAddress" @click="adding = true">
              <i class="icon-plus" /> Add an address
            </div>
          </div>
          <div class="addressesContainer">
            <!--Address box repeat-->
            <div class="addressBox" v-for="savedWallet in filteredWallets" :key="savedWallet.id">
              <i class="initials">{{ (savedWallet.name || '').substring(0, 2) }}</i>
              <div class="friendlyName">{{ savedWallet.name }}</div>
              <div class="actions">
                <i class="icon-edit" @click="editWallet(savedWallet)" />
                <i class="icon-error" @click="confirmDelete(savedWallet)" />
              </div>
              <div class="addressNo">{{ savedWallet.address }}</div>
              <div class="addressCurrency">
                <i :class="`icon-${currencies[savedWallet.currency.toLowerCase()].icon}`" />
                <span>{{ currencies[savedWallet.currency.toLowerCase()].display }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Overlay-->
    <div class="overlay" v-if="adding || editing || deleting" />
    <!--Add and edit an address Modal(Add class "editAnAddress" for swap and change title)-->
    <div class="modalWrapper addAnAddress editAnAddress" v-if="adding || editing">
      <ExitIconButton @click="cancel()" />

      <div class="modalTitle">
        <div class="big">{{ adding === true ? 'Add' : 'Edit' }} an address</div>
        <div class="small">Enter the account address and a friendly name for it</div>
      </div>
      <div class="userAddress">
        <i v-if="name.length > 0">{{ name.substring(0, 2) }}</i>
        <span>{{ name }}</span>
      </div>
      <form @submit.prevent>
        <InputDropDown_V2
          v-model.trim="currency"
          label="Currency"
          class="currencyStyle"
          placeholder="Currency"
          :items="currencyList"
        />
        <InputText label="Wallet address" placeholder="Wallet address" v-model="address" />
        <InputText placeholder="Friendly name" v-model="name" />
        <div class="fieldGroup">
          <InputButton
            :isLoading="isLoading"
            color="primary"
            label="Submit"
            type="submit"
            @click="addOrEditAddress"
            :disabled="disableSubmit"
          />
          <InputButton color="secondary" label="Cancel" type="button" @click="cancel()" />
        </div>
      </form>
    </div>
    <!--Delete an Address-->
    <div class="modalWrapper deleteAnAddress" v-if="deleting">
      <ExitIconButton @click="cancel()" />
      <div class="modalTitle">
        <div class="big">Delete an address</div>
        <div class="small">Are you sure you want to delete this address?</div>
      </div>
      <div class="userAddress">
        <i class="initials">{{ name.substring(0, 2) }}</i>
        <span>{{ name }}</span>
        <div class="addressNumber">
          {{ address }}
        </div>
        <div class="userCurrency">
          <i class="icon-bitcoin" />
          <span>BTC Bitcoin</span>
        </div>
      </div>
      <div class="fieldGroup">
        <InputButton class="cyanCTA" label="Submit" type="submit" @click="deleteWallet()" />
        <InputButton
          :isLoading="isLoading"
          class="cancelCTA"
          label="Cancel"
          type="button"
          @click="cancel()"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';

  .mainContainer.addressBook .innerContainer {
    width: 60%;
  }

  .addressBook h2 {
    text-align: center;
    width: 100%;
    float: left;
  }

  .topActions {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 15px;

    .addAddress {
      float: right;
      font-weight: 600;
      font-size: 14px;
      color: $blue;
      margin-top: 20px;
      transition: $small;
      cursor: pointer;
    }

    .addAddress:hover {
      color: $petrol;
    }

    .input {
      width: 240px;
      float: left;
    }
  }

  .addressBox:hover {
    box-shadow: 10px 0px 40px 0px rgba(1, 52, 170, 0.2);
    z-index: 1;
  }

  .addressBox {
    float: left;
    width: 100%;
    z-index: 0;
    position: relative;
    margin-bottom: 5px;
    border-radius: 10px;
    background: $white;
    box-shadow: 10px 16px 40px 0px rgba(1, 52, 170, 0.06);
    padding: 15px 20px;
    transition: $small;

    .initials {
      color: $lightblue;
      width: 34px;
      height: 34px;
      background: $brokenwhite;
      border-radius: 100px;
      text-align: center;
      font-style: normal;
      font-size: 12px;
      font-weight: 600;
      float: left;
      border: 1px solid $greyblue;
      margin-right: 10px;
      padding-top: 8px;
    }

    .friendlyName {
      float: left;
      font-weight: 700;
      font-size: 16px;
      position: relative;
      top: 4px;
    }

    .addressCurrency {
      float: right;
      margin-right: 40px;
      width: 140px;
      text-align: left;

      i {
        float: left;
        width: 30px;
        height: 30px;
        padding-top: 7px;
        text-align: center;
        background: $white;
        box-shadow: 10px 2px 20px 0px rgba(5, 29, 86, 0.1);
        border-radius: 50px;
        border: 1px solid $greyblue;
        position: relative;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        position: relative;
        top: 5px;
        margin-left: 7px;
      }

      i:before {
        z-index: 1;
        position: relative;
        top: 0px;
      }

      i:after {
        width: 20px;
        height: 20px;
        position: absolute;
        content: '';
        transform: rotate(12deg);
        left: 4px;
        top: 4px;
        border-radius: 50px;
      }

      i.icon-bitcoin {
        transform: rotate(-12deg);
      }

      i.icon-bitcoin:before {
        color: $white;
      }

      i.icon-bitcoin:after {
        background: #ff9800;
      }
    }

    .addressNo {
      float: right;
      margin-right: 20px;
      font-size: 14px;
      font-weight: 500;
      position: relative;
      top: 5px;
      min-width: 280px;
      text-align: right;
    }

    .actions {
      float: right;

      i {
        font-size: 23px;
        margin-left: 20px;
      }

      .icon-edit {
        color: $lightblue;
        transition: $small;
      }

      .icon-edit:hover {
        color: $blue;
        cursor: pointer;
      }

      .icon-error {
        transition: $small;
        position: relative;
      }

      .icon-error:before {
        z-index: 1;
        position: relative;
      }

      .icon-error:after {
        content: '';
        width: 90%;
        height: 90%;
        background: $red;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100px;
        opacity: 0;
        transition: $small;
      }

      .icon-error:hover {
        cursor: pointer;
        color: $white;
      }

      .icon-error:hover:before {
        color: $white;
      }

      .icon-error:hover:after {
        opacity: 1;
      }
    }
  }

  .emptyState {
    background: #f9fbff;
    float: left;
    width: 100%;
    padding: 90px;
    text-align: center;
    margin-top: 20px;
    border-radius: 10px;

    i.icon-addressbook {
      color: $blue;
      font-size: 53px;
      position: relative;
      z-index: 1;
      width: 100px;
      height: 100px;
      display: block;
      margin: auto;
    }

    i.icon-addressbook:before {
      z-index: 1;
      position: relative;
      top: 24px;
      left: 2px;
    }

    i.icon-addressbook:after {
      content: '';
      width: 100px;
      height: 100px;
      box-shadow: 0px 16px 81px 0px rgba(1, 52, 170, 0.1);
      background: $white;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 100px;
      z-index: 0;
    }

    .emptyTitle {
      margin-top: 40px;
      font-size: 25px;
      font-weight: 600;
      color: $black;
      float: left;
      width: 100%;
      line-height: 25px;

      span {
        width: 100%;
        float: left;
        margin-top: 20px;
        font-size: 13px;
        color: $lightblue;
        line-height: 16px;
      }
    }

    .blueCTA {
      margin-top: 40px;

      i {
        margin-right: 10px;
      }
    }
  }

  .modalWrapper .input {
    margin-bottom: 25px;
    float: left;
    width: 100%;
  }

  .modalWrapper .fieldGroup {
    margin-top: 30px;
  }

  .modalWrapper .cyanCTA,
  .modalWrapper .cancelCTA {
    margin: auto;
    display: block;
  }

  .addAnAddress {
    height: 610px;
    margin-top: -305px;
    padding-left: 140px;
    padding-right: 140px;

    .dropdown-list {
      width: 100%;
    }
  }

  .editAnAddress {
    height: 710px;
    margin-top: -355px;

    .userAddress {
      float: left;
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
      display: block;

      i {
        font-style: normal;
        font-weight: 500;
        color: $lightblue;
        font-size: 18px;
        display: inline-block;
        background: $greyblue;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        padding-top: 12px;
      }

      span {
        font-size: 16px;
        font-weight: 600;
        float: left;
        width: 100%;
      }
    }
  }

  .modalWrapper.addAnAddress:not(.editAnAddress) .userAddress {
    display: none;
  }

  .deleteAnAddress {
    height: 580px;
    margin-top: -290px;

    .userAddress {
      float: left;
      width: 100%;
      text-align: center;
      margin: 30px 0;
      display: block;

      i.initials {
        font-style: normal;
        font-weight: 500;
        color: $lightblue;
        font-size: 18px;
        display: inline-block;
        background: $greyblue;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        padding-top: 12px;
      }

      span {
        font-size: 18px;
        font-weight: 600;
        float: left;
        margin-top: 5px;
        width: 100%;
      }

      .addressNumber {
        font-size: 15px;
        font-weight: 500;
        float: left;
        width: 100%;
      }

      .userCurrency {
        float: left;
        width: 100%;
        margin-top: 10px;

        span {
          display: inline-block;
          float: none;
          width: auto;
          font-size: 14px;
          margin-left: 5px;
        }

        i {
          display: inline-block;
          width: 30px;
          height: 30px;
          padding-top: 7px;
          text-align: center;
          background: $white;
          box-shadow: 10px 2px 20px 0px rgba(5, 29, 86, 0.1);
          border-radius: 50px;
          border: 1px solid $greyblue;
          position: relative;
        }

        i:before {
          z-index: 1;
          position: relative;
          top: 0px;
        }

        i:after {
          width: 20px;
          height: 20px;
          position: absolute;
          content: '';
          transform: rotate(12deg);
          left: 4px;
          top: 4px;
          border-radius: 50px;
        }

        i.icon-bitcoin {
          transform: rotate(-12deg);
        }

        i.icon-bitcoin:before {
          transform: rotate(-80deg);
          color: $white;
        }

        i.icon-bitcoin:after {
          background: #ff9800;
        }
      }
    }
  }

  @media screen and (max-width: 1995px) {
    .mainContainer.addressBook .innerContainer {
      width: 70%;
    }
  }

  @media screen and (max-width: 1750px) {
    .mainContainer.addressBook .innerContainer {
      width: 85%;
    }
    .addressBox .addressNo {
      margin-right: 0;
    }
    .addressBox .addressCurrency {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1400px) {
    .addressBox .addressCurrency span {
      display: none;
    }
    .addressBox .addressCurrency {
      width: auto;
      margin-right: 10px;
    }
    .mainContainer.addressBook .innerContainer {
      width: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    .mainContainer.addressBook .innerContainer {
      width: 100%;
    }
    .addressBox .addressCurrency i.icon-bitcoin {
      font-size: 13px;
    }
  }

  @media screen and (max-width: 840px) {
    .deleteAnAddress,
    .editAnAddress {
      width: 90%;
      padding: 90px 60px;
      margin-left: 0;
      left: 5%;
    }

    @media screen and (max-width: 768px) {
      .addressBox {
        height: 100px;
        margin-bottom: 10px;
      }

      .addressBox .addressCurrency {
        position: absolute;
        bottom: 15px;
        left: 20px;
      }
      .addressBox .addressNo {
        position: absolute;
        top: auto;
        bottom: 20px;
        font-size: 2.7vw;
        right: 0;
        padding-right: 2vw;
        overflow-wrap: anywhere;
        max-width: 80%;
        min-width: unset;
        max-height: 25%;
      }
    }
  }
</style>

<script>
  import _map from 'lodash/map';
  import ExitIconButton from '../../@components/ExitIconButton.vue';
  import InputButton from '../../@components/InputButton.vue';
  import InputDropDown_V2 from '../../@components/InputDropDown_V2.vue';
  import InputText from '../../@components/InputText.vue';
  import services from '../../../@libs/services';
  import httpClient from '../../../@libs/httpClient';
  import { apiEndpoints, currencies } from '../../../@enums';
  import ClosingBanner from '../../../@@layouts/Default/ClosingBanner/ClosingBanner.vue';

  export default {
    name: 'AddressBook',
    components: { ClosingBanner, InputText, InputButton, ExitIconButton, InputDropDown_V2 },
    data() {
      return {
        search: '',
        adding: false,
        editing: false,
        deleting: false,
        id: null,
        address: '',
        name: '',
        currency: 'btc',
        savedWallets: [],
        isLoading: false,
        currencies,
      };
    },
    computed: {
      currencyList() {
        return _map(currencies, (a) => ({
          text: a.display,
          prepend: `icon-${a.icon}`,
          value: a.currency,
        })).filter((a) => a.value !== 'usd');
      },
      userId() {
        return this.$store.state.userDetails.userDetail.id;
      },
      disableSubmit() {
        return this.name === '' || this.address === '';
      },
      filteredWallets() {
        const { search } = this;

        if (search === '') return this.savedWallets || [];

        return this.savedWallets?.filter(
          (a) =>
            a.name.toLowerCase().includes(search.toLowerCase()) ||
            a.address.toLowerCase().includes(search.toLowerCase()),
        );
      },
    },
    methods: {
      async loadData() {
        try {
          this.isLoading = true;
          const userDetails = await services.userDetailService.findById(this.userId);
          this.savedWallets = userDetails.savedWallets;
        } finally {
          this.isLoading = false;
        }
      },
      async addOrEditAddress() {
        const { id, name, address, currency } = this;
        try {
          this.isLoading = true;
          if (id !== null) {
            await httpClient.put(apiEndpoints.userDetails.editWallet, {
              replace: {
                id: this.userId,
                walletId: this.id,
              },
              body: {
                name,
                address,
                currency,
              },
            });
          } else {
            await httpClient.post(apiEndpoints.userDetails.addWallet, {
              replace: {
                id: this.userId,
              },
              body: {
                name,
                address,
                currency,
              },
            });
          }
        } finally {
          this.isLoading = false;
          await this.loadData();
          this.cancel();
        }
      },
      editWallet(wallet) {
        this.id = wallet.id;
        this.name = wallet.name;
        this.address = wallet.address;
        this.currency = wallet.currency.toLowerCase();
        this.editing = true;
      },
      confirmDelete(wallet) {
        this.id = wallet.id;
        this.name = wallet.name;
        this.address = wallet.address;
        this.currency = wallet.currency.toLowerCase();
        this.deleting = true;
      },
      async deleteWallet() {
        await httpClient.delete(apiEndpoints.userDetails.deleteWallet, {
          replace: { id: this.userId, walletId: this.id },
          parseJson: false,
        });
        await this.loadData();
        this.cancel();
      },
      cancel() {
        this.adding = false;
        this.editing = false;
        this.deleting = false;
        this.id = null;
        this.name = '';
        this.address = '';
        this.currency = 'btc';
      },
    },
    async mounted() {
      await this.loadData();
    },
  };
</script>
