<template>
  <div v-if="!exit">
    <div class="overlay" />
    <div class="modalWrapper">
      <ExitIconButton @click="exited()" />
      <div class="modalTitle">
        <div class="big">Terms and Conditions</div>
      </div>
      <div class="termsContainer">
        <TermsOfService class="termsOfService" />
      </div>
      <div>
        <InputButton
          :isLoading="isLoading"
          label="I Agree"
          color="primary"
          type="button"
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../../../@assets/styles/variables.scss';

  .modalWrapper {
    overflow-x: hidden;
  }

  .termsOfService {
    margin-top: -1rem;
  }

  .termsContainer {
    margin: 0rem -10rem;
  }
</style>

<script>
  import ExitIconButton from '../../../../@components/ExitIconButton.vue';
  import InputButton from '../../../../@components/InputButton.vue';
  import TermsOfService from '../../../../TermsOfService/TermsOfService.vue';

  export default {
    name: 'TermsStep',
    components: { TermsOfService, InputButton, ExitIconButton },
    props: {
      next: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        isLoading: false,
        exit: false,
        buttonDisabled: false,
      };
    },
    methods: {
      exited() {
        this.exit = !this.exit;
        // Special Case: Redirect to dashboard
        this.$router.push({ path: '/dashboard' });
      },
    },

    mounted() {
      this.email = this.$store.state.userDetails.userDetail.emailAddress || 'test@email.com';
      this.phone = this.$store.state.userDetails.userDetail.phoneNumber || '+15555555555';
    },
  };
</script>

<style scoped lang="scss">
  .input {
    width: 48.5%;
    float: left;
    margin-bottom: 20px;
    margin-right: 3%;
  }
  .input:nth-child(odd) {
    margin-right: 0;
  }

  .cancelCTA,
  .cyanCTA {
    margin: 0 auto;
    display: block;
  }

  .modalWrapper .fieldGroup:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-height: 960px) {
    .modalWrapper {
      margin-top: 0;
      top: 5vh;
      height: 90vh;
      overflow: auto;
      // margin-left: -40rem;
      width: 90vw;
    }
  }

  @media screen and (max-height: 550px) {
    .modalWrapper {
      margin-left: -48rem;
    }
  }

  @media screen and (max-height: 460px) {
    .modalWrapper {
      margin-left: -43rem;
    }
  }

  @media screen and (max-height: 400px) {
    .modalWrapper {
      margin-left: -36rem;
    }
  }

  @media screen and (max-height: 300px) {
    .modalWrapper {
      margin-left: -27rem;
    }
  }

  @media screen and (max-height: 250px) {
    .modalWrapper {
      margin-left: -21.65rem;
    }
  }
</style>
