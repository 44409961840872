<template>
  <div v-if="!exit">
    <div class="overlay" />
    <div class="modalWrapper">
      <Banner
        :show.sync="showTip"
        color="info"
        label="Info"
        message="Users must enter information matching the information on the documentation they submit for verification (i.e. passport, national license, etc.)."
        :autohide="false"
        @dismiss="showTip = !showTip"
      />
      <Banner
        :show.sync="showBanner"
        color="error"
        label="KYC Error"
        :message="bannerData.message || upgradeRequirements"
        :autohide="false"
        @dismiss="showBanner = !showBanner"
      />
      <br />
      <ExitIconButton @click="exited()" />
      <div class="modalTitle">
        <div class="big">Verify Your Identity</div>
        <div class="small">
          Please provide us with your Personal details in order for you to proceed
        </div>
      </div>

      <form @submit.prevent="postDetails">
        <div v-if="!country">
          <div class="fieldGroup">
            <span class="subtitle">Please select your country to begin</span>
            <InputDropDown_V2
              class="countrySelector"
              v-model.trim="country"
              label="Country"
              placeholder="Country"
              :items="countries"
            />
          </div>
        </div>
        <div v-else>
          <div class="fieldGroup">
            <span style="margin-bottom: 35px" class="subtitle">Personal Details</span>
            <InputText
              :required="true"
              v-model.trim="firstName"
              label="First Name"
              placeholder="Full Legal First Name"
            />
            <InputText
              :required="true"
              v-model.trim="lastName"
              label="Last Name"
              placeholder="Full Legal Last Name"
            />
            <InputText
              :required="true"
              v-model.trim="email"
              label="Email"
              placeholder="Email"
              :disabled="true"
            />
            <InputText
              :required="true"
              v-model.trim="phone"
              label="Phone"
              placeholder="Phone"
              :disabled="true"
            />
            <InputText
              :required="true"
              v-model.trim="ssn"
              mask="###-##-####"
              label="SSN (###-##-####)"
              placeholder="SSN"
              v-if="!international"
            />
            <div class="calendarContainer">
              <InputText
                ref="datePicker"
                @focus="toggleDatePicker"
                label="Date of Birth"
                :value="dateOfBirth.toLocaleDateString()"
                readonly="readonly"
              />
              <vc-date-picker
                class="date-picker"
                v-model="dateOfBirth"
                v-if="datePickerShown"
                is-expanded
              />
            </div>
          </div>
          <div class="fieldGroup">
            <span style="margin-bottom: 35px" class="subtitle">Address Details</span>
            <InputText
              :required="true"
              v-model.trim="street1"
              label="Street 1"
              placeholder="Street 1"
            />
            <InputText v-model.trim="street2" label="Street 2" placeholder="Street 2" />
            <InputText :required="true" v-model.trim="city" label="City" placeholder="City" />
            <InputDropDown_V2
              :required="!international"
              v-model.trim="state"
              label="State"
              placeholder="State"
              :items="states"
              v-if="!international"
            />
            <InputText
              :required="true"
              v-model.trim="postalCode"
              label="Zip Code"
              placeholder="Zip Code"
            />
            <InputDropDown_V2
              v-model.trim="country"
              label="Country"
              placeholder="Country"
              :items="countries"
            />
          </div>
          <div class="fieldGroup">
            <InputButton
              :isLoading="isLoading"
              color="primary"
              label="Next"
              type="submit"
              :disabled="emptyFormFields || disableNext"
            />
            <InputButton color="secondary" label="Back" type="button" @click="back" />
            <InputButton color="secondary" label="Cancel" type="button" @click="exited()" />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../../../@assets/styles/variables.scss';

  .input,
  .calendarContainer {
    width: 48.5%;
    float: left;
    margin-bottom: 20px;
    margin-right: 3%;
  }

  .input:nth-child(odd),
  .calendarContainer:nth-child(odd) {
    margin-right: 0;
  }

  .calendarContainer {
    position: relative;
  }

  .calendarContainer .input {
    width: 100%;
  }

  .calendarContainer .date-picker {
    z-index: 10;
    left: 0;
    right: unset;
  }

  .calendarContainer:nth-child(odd) .date-picker {
    right: 0;
    left: unset;
  }

  .cancelCTA,
  .cyanCTA {
    margin: 0 auto;
    display: block;
  }

  .modalWrapper .fieldGroup:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-height: 960px) {
    .modalWrapper {
      margin-top: 0;
      top: 5vh;
      height: 90vh;
      overflow: auto;
    }
  }
</style>

<script>
  import moment from 'moment';
  import ExitIconButton from '../../../../@components/ExitIconButton.vue';
  import InputButton from '../../../../@components/InputButton.vue';
  import InputText from '../../../../@components/InputText.vue';
  import InputDropDown_V2 from '../../../../@components/InputDropDown_V2.vue';
  import httpClient from '../../../../../@libs/httpClient';
  import { apiEndpoints, countries, states } from '../../../../../@enums';
  import Banner from '../../../../@components/Banner.vue';

  const EighteenYearsAgo = () => {
    const currentDate = new Date();

    const minDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate(),
    );

    return minDate;
  };

  export default {
    name: 'Step1',
    components: { Banner, InputText, InputDropDown_V2, InputButton, ExitIconButton },
    props: {
      back: {
        type: Function,
        default: () => {},
      },
      next: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        showTip: true,
        exit: false,
        firstName: null,
        lastName: null,
        ssn: null,
        dateOfBirth: EighteenYearsAgo(),
        datePickerShown: false,
        phone: null,
        email: null,
        street1: null,
        street2: null,
        city: null,
        state: null,
        postalCode: null,
        postalCodeMask: 'XXXXXXXXXX',
        country: null,
        countries,
        states,
        showBanner: false,
        isLoading: false,
        bannerData: {
          color: null,
          label: '',
          message: '',
          autohide: true,
        },
      };
    },
    watch: {
      upgradeRequirements(newValue) {
        this.showBanner = newValue !== undefined;
      },
      dateOfBirth() {
        this.datePickerShown = false;
      },
      country(newValue) {
        if (newValue !== 'US') {
          this.ssn = null;
          this.dateOfBirth = EighteenYearsAgo();
          this.street1 = null;
          this.street2 = null;
          this.city = null;
          this.state = null;
          this.postalCode = null;
        }
      },
    },
    computed: {
      formattedDateOfBirth() {
        if (!this.dateOfBirth) return '';
        const day = String(this.dateOfBirth.getDate()).padStart(2, '0');
        const month = String(this.dateOfBirth.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const year = this.dateOfBirth.getFullYear();

        return `${year}-${month}-${day}`;
      },
      unsupportedState() {
        return this.states.find((state) => state.value === this.state)?.unsupported ?? false;
      },
      isNot18() {
        const minDate = EighteenYearsAgo();

        return this.dateOfBirth > minDate;
      },
      upgradeRequirements() {
        const requirements =
          this.$store.state.userDetails.userDetail?.kyc?.upgradeRequirements || [];

        if (this.unsupportedState) {
          requirements.push('Invalid State');
        }

        if (this.isNot18) {
          requirements.push('Must be 18 years or older');
        }

        if (requirements.length === 0) return undefined;

        return requirements.join(', ');
      },
      international() {
        return this.country !== 'US';
      },
      disableNext() {
        return (
          this.isNot18 ||
          (this.states.find((state) => state.value === this.state)?.unsupported ?? false)
        );
      },
      emptyFormFields() {
        const isEmpty = (str) => !str || str.trim() === '';
        return (
          isEmpty(this.firstName) ||
          isEmpty(this.lastName) ||
          isEmpty(this.email) ||
          isEmpty(this.phone) ||
          isEmpty(this.street1) ||
          isEmpty(this.city) ||
          isEmpty(this.postalCode)
        );
      },
    },
    methods: {
      toggleDatePicker() {
        this.datePickerShown = !this.datePickerShown;
        this.$refs.datePicker.$refs.inputValue.blur();
      },
      async postDetails() {
        try {
          this.isLoading = true;

          const userDetail = await httpClient.post(apiEndpoints.userDetails.kycDetails, {
            replace: {
              id: this.$store.state.userDetails.userDetail.id,
            },
            body: {
              firstName: this.firstName,
              lastName: this.lastName,
              ssn: this.ssn,
              dateOfBirth: this.formattedDateOfBirth,
              phone: this.phone,
              email: this.email,
              street1: this.street1,
              street2: this.street2,
              city: this.city,
              state: this.state,
              postalCode: this.postalCode,
              country: this.country,
            },
          });

          this.$store.commit('userDetails/updateUser', { userDetail });

          if (userDetail.kyc.isInternational || userDetail.kyc.isLevel1) this.next();

          this.bannerData = {
            label: 'KYC Error',
            message: this.upgradeRequirements,
            color: 'error',
            autohide: false,
          };
          this.showBanner = this.upgradeRequirements;
        } catch (error) {
          if (error.toString().match('DateTime')) {
            this.bannerData = {
              label: 'KYC Error',
              message: error.toString(),
              color: 'error',
              autohide: false,
            };
            return;
          }
          const errorMsg = Object.values(JSON.parse(error?.message)?.errors);
          this.bannerData = {
            label: 'KYC Error',
            message: errorMsg.toString(),
            color: 'error',
            autohide: false,
          };
        } finally {
          this.showBanner = true;
          this.isLoading = false;
        }
      },
      exited() {
        this.exit = !this.exit;
        this.$router.push({ path: '/dashboard' });
      },
    },
    mounted() {
      this.email = this.$store.state.userDetails.userDetail.emailAddress || 'test@email.com';
      this.phone = this.$store.state.userDetails.userDetail.phoneNumber || '+15555555555';
    },
    beforeMount() {
      this.showBanner = this.upgradeRequirements !== undefined;
    },
  };
</script>
