<template>
  <div class="main-app">
    <AdminDashboard
      v-if="groupDashboardLoaded"
      :group-dashboard="groupDashboard"
      @cancel="cancelCard"
    />
  </div>
</template>

<style></style>

<script>
  import services from '../../@libs/services';
  import AdminDashboard from './@components/AdminDashboard.vue';

  export default {
    name: 'GroupDashboard',
    components: {
      AdminDashboard,
    },
    data() {
      return {
        groupDashboardLoaded: false,
        groupDashboard: {
          verifiedUsers: 0,
          totalUsers: 0,
          unRedeemedCount: 0,
          unRedeemedValue: 0,
          unRedeemedGiftCards: [],
        },
      };
    },
    async mounted() {
      const result = await services.projectionService.getGroupDashboard();

      if (!result.error) {
        this.groupDashboard = result.details;
        this.groupDashboardLoaded = true;
      }
    },
    methods: {
      async cancelCard(card) {
        await services.giftCardService.cancelCard(card);
      },
    },
  };
</script>
