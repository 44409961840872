<template>
  <div class="mainContainer ledgers">
    <div class="fullRow">
      <h2>Ledgers<span>Select a date range</span></h2>
    </div>
    <div class="fullRow">
      <ul class="tabber">
        <li :class="[selectedTab === 0 ? 'active' : '']" @click="selectedTab = 0">
          <button>User ledgers</button>
        </li>
        <li :class="[selectedTab === 1 ? 'active' : '']" @click="selectedTab = 1">
          <button>Wallet ledgers</button>
        </li>
      </ul>
      <div class="calendarContainer">
        <InputText
          @focus="showDatepicker"
          label="Enter a date range"
          placeholder="Enter a date range"
          :value="formattedRange"
        />
        <vc-date-picker v-model.range="range" v-if="datepickerShown" is-range is-expanded />
      </div>
    </div>
    <!---Table test--->
    <div class="tableLedger">
      <table v-if="selectedTab === 0" class="tableStyle" id="userLedger">
        <thead>
          <tr>
            <th scope="col" v-for="header in userHeaders" :key="header">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="emptyState" v-if="!userLedgerEntries || userLedgerEntries.length === 0">
            <td colspan="3">No Data to show <span>Please select a date range first</span></td>
          </tr>
          <tr v-for="entry in userLedgerEntries" :key="entry.id">
            <td data-label="Group Name">{{ entry.groupName }}</td>
            <td data-label="E-mail">{{ entry.emailAddress }}</td>
            <td data-label="Balance">{{ entry.balance }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="selectedTab === 1" class="tableStyle" id="walletLedger">
        <thead>
          <tr>
            <th scope="col">Group Name</th>
            <th scope="col">Wallet</th>
            <th scope="col">Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr class="emptyState" v-if="!walletLedgerEntries || walletLedgerEntries.length === 0">
            <td colspan="3">No Data to show <span>Please select a date range first</span></td>
          </tr>
          <tr v-for="entry in walletLedgerEntries" :key="entry.id">
            <td data-label="Group Name">{{ entry.groupName }}</td>
            <td data-label="Wallet">{{ entry.walletType }}</td>
            <td data-label="Balance">{{ entry.balance }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';

  .ledgers .fullRow,
  .ledgers h2,
  .ledgers h2 span {
    text-align: center;
  }

  .input {
    float: right;
    min-width: 310px;
  }

  .tableLedger {
    float: left;
    width: 100%;
    margin-top: 50px;
  }

  .tabber {
    margin-top: 10px;
  }

  table .emptyState {
    background: #f7f9ff;
    height: 460px;
    td {
      font-size: 25px;
      font-weight: bold;
      color: $black;
      text-align: center;
      border-radius: 10px;
      span {
        font-size: 13px;
        font-weight: 600;
        color: $lightblue;
        display: block;
      }
    }
  }

  .calendarContainer {
    float: right;
    position: relative;
  }
  @media  screen and (max-width:640px) {
    .ledgers{
      .calendarContainer {
        width:100%;
        margin:25px 0 0 0;
        .input{
          min-width: 100%;
          margin:0;
        }
      }
      .tabber{
        width:100%;
        li{
          width:50%;
          button{
            min-width: 100%;
          }
        }
      }
    }   
 }  
  
</style>

<script>
  import services from '../../@libs/services';
  import InputText from '../@components/InputText.vue';

  export default {
    name: 'Ledger',
    components: { InputText },
    data() {
      return {
        range: null,
        selectedTab: 0,
        datepickerShown: false,
        startModal: false,
        endModal: false,
        startDate: null,
        endDate: null,
        ledgers: [],
        userHeaders: ['Group Name', 'Email Address', 'Balance'],
        groupHeaders: ['Group Name', 'Wallet Type', 'Balance'],
      };
    },
    computed: {
      userLedgerEntries() {
        return this.ledgers.filter((ledger) => ledger.ledgerType === 'User');
      },
      walletLedgerEntries() {
        return this.ledgers.filter((ledger) => ledger.ledgerType === 'Wallet');
      },
      formattedRange() {
        if (this.range) {
          return `${this.range.start.toLocaleDateString()} - ${this.range.end.toLocaleDateString()}`;
        }
        return '';
      },
    },
    watch: {
      async range() {
        this.startDate = this.range.start.toISOString().substr(0, 10);
        this.endDate = this.range.end.toISOString().substr(0, 10);
        this.datepickerShown = false;
        await this.loadData();
      },
    },
    methods: {
      showDatepicker() {
        this.datepickerShown = true;
      },
      async loadData() {
        const startTimestamp = new Date(`${this.startDate}T00:00:00.000Z`);
        const endTimestamp = new Date(`${this.endDate}T23:59:59.999Z`);

        const ledgers = await services.ledgerService.searchLedgers(
          startTimestamp.getTime(),
          endTimestamp.getTime(),
        );

        this.ledgers = ledgers.data;
      },
    },
    async created() {
      // await this.loadData();
    },
  };
</script>
