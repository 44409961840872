import config from '../../config';

const root = config.apiPath;

/**
 * Generate the standard routes all domain objects contain
 * @param domain Required domain to fill in standard routes for
 * @returns object containing all standard routes
 */
function getStandardRoutes(domain) {
  const domainRoot = `${root}${domain}`;
  const domainRootWithId = `${domainRoot}/{id}`;

  return {
    root: `${domainRoot}/`,
    get: domainRootWithId,
    delete: domainRootWithId,
    post: domainRoot,
    put: domainRootWithId,
    listAll: domainRoot,
  };
}

const userDetailRoot = `${root}userDetails/`;
const userDetails = {
  ...getStandardRoutes('userDetails'),
  login: `${root}login`,
  loginWith2Fa: `${root}2fa`,
  loginWithRefreshToken: `${root}refresh`,
  verifyEmailAddress: `${root}verifyEmailAddress`,
  verifyPhoneNumber: `${root}verifyPhoneNumber`,
  updatePhoneNumber: `${userDetailRoot}{id}/phone`,
  resendSMS: `${userDetailRoot}{id}/phone/resend`,
  resetPassword: `${root}password/reset`,
  resetPasswordWithResetCode: `${root}password/reset?resetCode={resetCode}`,
  enable2Fa: `${userDetailRoot}{id}/enable2fa`,
  disable2Fa: `${userDetailRoot}{id}/disable2fa`,
  reset2fa: `${userDetailRoot}{id}/2fa/reset`,
  kycDetails: `${userDetailRoot}{id}/kyc/details`,
  kycDocument: `${userDetailRoot}{id}/kyc/document?documentType={documentType}`,
  balances: `${userDetailRoot}{id}/balances`,
  transactions: `${userDetailRoot}{id}/latest-transactions`,
  addWallet: `${userDetailRoot}{id}/savedWallets/`,
  editWallet: `${userDetailRoot}{id}/savedWallets/{walletId}`,
  deleteWallet: `${userDetailRoot}{id}/savedWallets/{walletId}`,
  withdrawalCrypto: `${userDetailRoot}{id}/withdrawal`,
  getGasFees: `${userDetailRoot}{id}/gas-fees`,
};

const giftCardRoot = `${root}giftCards/`;
const giftCards = {
  ...getStandardRoutes('giftCards'),
  redeem: `${giftCardRoot}{code}/{pin}`,
  cancel: `${giftCardRoot}{id}/cancel`,
  recreate: `${giftCardRoot}{code}/{pin}/recreate`,
  transfer: `${giftCardRoot}{code}/{pin}/transfer`,
  combine: `${giftCardRoot}combineCards`,
  combineAndRedeem: `${giftCardRoot}combineCardsAndRedeem?saveWalletAddress={saveWalletAddress}`,
  checkBalance: `${giftCardRoot}{code}/{pin}`,
  currentRates: `${giftCardRoot}rates?currency={currency}`,
  currentPurchaseRates: `${giftCardRoot}purchase-rates?currency={currency}`,
  purchaseCrypto: `${giftCardRoot}purchase`,
  redeemed: `${giftCardRoot}redeemed`,
  pending: `${giftCardRoot}pending`,
};

const groupDetails = {
  ...getStandardRoutes('groupDetails'),
  config: `${root}config`,
};

const projections = {
  ...getStandardRoutes('projections'),
  groupDashboard: `${root}projections/groupDashboard`,
};

const ledgers = {
  ...getStandardRoutes('ledgers'),
  searchLedgers: `${root}ledgers/search-ledgers`,
  searchLedgerEntries: `${root}ledgers/search-ledger-entries`,
};

const contactUs = {
  ...getStandardRoutes('contactUs'),
  contactUs: `${root}contactUs`,
};

const ticketsRoot = `${root}tickets`;
const tickets = {
  ...getStandardRoutes('tickets'),
  postTicketReply: `${ticketsRoot}/reply`,
  putTicketStatus: `${ticketsRoot}/{ticketId}/status`,
  putTicketFileUpload: `${ticketsRoot}/{ticketId}/files/upload`,
};
export default {
  root,
  userDetails,
  giftCards,
  groupDetails,
  projections,
  ledgers,
  contactUs,
  tickets,
};
