<template>
  <div :class="['transBox', txType, { canceled }]">
    <i :class="`icon-${txType}`" />
    <i v-if="canceled" class="icon-cancel" />
    <div class="transDetails">
      {{ card }}
      <span>{{ date }} | {{ description }} </span>
      <span>{{ comment }} </span>
    </div>
    <div class="transAmount">{{ parseFloat(amount) }} {{ Currencies[currency].display }}</div>
  </div>
</template>
<script>
  import Currencies from '../../@enums/currencies';

  export default {
    name: 'transactionRow',
    props: {
      txType: {
        type: String,
        required: true,
      },
      amount: {
        type: String,
        required: true,
      },
      card: {
        type: String,
        required: true,
      },
      date: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      canceled: {
        type: Boolean,
        required: false,
      },
      currency: {
        type: String,
        required: true,
      },
      comment: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        Currencies,
      };
    },
  };
</script>
<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';

  .transBox {
    float: left;
    width: 100%;
    margin-top: 30px;
    position: relative;

    .icon-cancel {
      display: none;
    }

    i {
      float: left;
      position: relative;
    }

    i:after {
      content: '';
      position: relative;
      float: left;
      border-radius: 100%;
      width: 47px;
      height: 47px;
      background: $greyblue;
      opacity: 0.5;
      margin-right: 10px;
      border: 1px solid $greyblue;
    }

    i:before {
      position: absolute;
      z-index: 1;
      font-size: 10px;
      top: 17px;
      left: 15px;
    }
    .transDetails {
      font-size: 18px;
      color: $black;
      font-weight: 600;
      line-height: 16px;
      float: left;
      width: 70%;
      margin-top: 5px;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        opacity: 0.6;
        color: $black;
        float: left;
        width: 100%;
        margin-top: 5px;
      }
    }

    .transAmount {
      float: right;
      font-size: 18px;
      font-weight: 600;
      line-height: 16px;
      margin-top: 14px;
      position: relative;
    }
  }

  .transBox.send .transAmount {
    color: $red;
  }

  .transBox.receive .transAmount {
    color: $green;
  }

  .transBox.canceled .transAmount {
    color: $grey;
    text-decoration: line-through;
  }

  .transBox.canceled .icon-cancel {
    display: block;
  }

  .transBox.canceled i:after {
    background: rgba(1, 14, 45, 0.05);
    border: 1px solid rgba(1, 14, 45, 0.1);
  }

  .transBox.canceled i:before {
    color: rgba(1, 14, 45, 0.3);
  }

  .transBox.canceled i.icon-cancel {
    position: absolute;
    top: 30px;
    left: 33px;
  }
  .transBox.canceled i.icon-cancel:after {
    background: #eb5757;
    opacity: 1;
    border: none;
    width: 16px;
    height: 16px;
    margin: 0;
  }
  .transBox.canceled i.icon-cancel:before {
    color: $white;
    opacity: 1;
    top: 5px;
    left: 5px;
    font-size: 6px;
  }

  .transBox.send .transAmount:before {
    content: '-';
    position: relative;
  }
  .transBox.receive .transAmount:before {
    content: '+';
    position: relative;
  }
</style>
