import config from './config';

const environments = {
  localhost: 'localhost',
  'staging.bitjem.com': 'staging',
  'staging.bitjems.com': 'staging',
  'legacy.bitjem.com': 'production',
  'bitjem.com': 'production',
  'bitjems.com': 'production',
  'www.bitjem.com': 'production',
  'www.bitjems.com': 'production',
  'preprod.bitjem.com': 'preprod',
  'preprod.bitjems.com': 'preprod',
};

// https://cli.vuejs.org/guide/mode-and-env.html#modes
let environment;

// NODE_ENV = test|development|production
if (process.env.NODE_ENV === 'test') {
  environment = 'test';
} else if (typeof window !== 'undefined') {
  environment = environments[window.location.hostname];
}

if (!environment) {
  throw new Error('Failed to set the environment');
}

let envConfig = require(`./config.${environment}`);
if (envConfig.__esModule) {
  envConfig = envConfig.default;
}

let configLocal = {};

// this code looks weird but is like this because of how webpack handles dynamic requires
try {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    configLocal = require('./config.local');
  } else if (process.env.NODE_ENV === 'test') {
    configLocal = require('./config.local.test');
  }
  if (configLocal.__esModule) {
    configLocal = configLocal.default;
  }
} catch (error) {
  if (error.code !== 'MODULE_NOT_FOUND') {
    throw error;
  }
}

export default {
  environment,
  ...config,
  ...envConfig,
  ...configLocal,
};
