<template>
  <component
    :is="currentStep"
    v-model="documentChoice"
    :documentChoice="documentChoice"
    :back="back"
    :next="next"
  />
</template>

<script>
  import Step1 from './Step1/Step1.vue';
  import Step2 from './Step2/Step2.vue';
  import Step3 from './Step3/Step3.vue';
  import Step4 from './Step4/Step4.vue';
  import TermsStep from './Terms/TermsStep.vue';

  export default {
    name: 'PII.vue',
    components: { Step1, Step2, Step3, Step4, TermsStep },
    data() {
      return {
        documentChoice: '',
        stepIndex: 0,
        availableSteps: [],
      };
    },
    computed: {
      userDetail() {
        return this.$store.state.userDetails?.userDetail || {};
      },
      kyc() {
        return this.$store.state.userDetails?.userDetail?.kyc || {};
      },
      currentStep() {
        return this.availableSteps[this.stepIndex];
      },
    },
    methods: {
      determineAvailableSteps() {
        const kyc = this.kyc;
        const steps = [];

        if (!kyc.started) {
          steps.push(TermsStep);
          steps.push(Step1);
          steps.push(Step2);
          steps.push(Step3);
        } else {
          if (kyc.isInternational) {
            if (kyc.documentsUploaded && kyc.documentsResubmit) {
              steps.push(Step2);
              steps.push(Step3);
            } else if (!kyc.documentsUploaded) {
              steps.push(Step1);
              steps.push(Step2);
              steps.push(Step3);
            }
          } else {
            if (!kyc.isLevel1) {
              steps.push(Step1);
              steps.push(Step2);
              steps.push(Step3);
            } else {
              if (!kyc.documentsUploaded || kyc.documentsResubmit) {
                steps.push(Step2);
                steps.push(Step3);
              }
            }
          }
        }

        steps.push(Step4);
        this.availableSteps = steps;
      },
      next() {
        //  if for some reason we're too high then go to the first available step
        if (this.stepIndex >= this.availableSteps.length) {
          this.stepIndex = 0;
          return;
        }

        if (this.stepIndex + 1 >= this.availableSteps.length) return;
        this.stepIndex += 1;
      },
      back() {
        if (this.stepIndex - 1 < 0) return;
        this.stepIndex -= 1;
      },
    },
    mounted() {
      if (!this.userDetail.isLevel2 && !this.$store.state.userDetails.hasPendingCards) {
        this.$router.push({ path: '/giftCard/redemption' });
      }

      if (this.userDetail.isLevel2) this.$router.push({ path: '/dashboard' });
      this.determineAvailableSteps();
    },
  };
</script>

<style scoped></style>
