<template>
  <div class="closing-banner">
    <h1>IMPORTANT UPDATE:</h1>
    <div class="banner-text">
      Because of recent developments with our custodian, BitJem will be pausing all operations after
      January 31, 2024. We will be back at a future date as we integrate a new custodian.
    </div>
    <div class="banner-text">
      In the meantime, it is critical that every user redeem BitJems and withdraw crypto from their
      wallet by <strong>January 30th, 2024</strong>.
    </div>
  </div>
</template>

<style scoped lang="scss">
  .closing-banner {
    h1 {
      text-align: center;
      font-weight: bold;
    }
    .banner-text {
      margin: 0 10px 20px 10px;
    }
    background-color: #f77;
    color: white;
    padding: 20px;
    font-size: 20px;
    margin-bottom: 30px;
  }
</style>

<script>
  export default {
    name: 'ClosingBanner',
    data() {
      return {
        intervalID: null,
      };
    },
    computed: {},
    watch: {
      async isLoggedIn(newValue) {
        if (newValue) await this.loadData();
      },
    },
    methods: {},
  };
</script>
