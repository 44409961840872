<template>
  <form v-if="!exit" @submit.prevent="uploadSelectedFiles">
    <div class="overlay" />
    <div class="modalWrapper step3">
      <Banner
        :show.sync="showTip"
        color="info"
        label="Info"
        message="Please upload clear pictures of your identification. Blurry pictures might be rejected."
        @dismiss="showTip = !showTip"
      />
      <Banner
        v-if="showBanner"
        :show.sync="showBanner"
        color="error"
        label="KYC Error"
        :message="errorMessage"
        @dismiss="showBanner = !showBanner"
      />
      <br />
      <ExitIconButton @click="exited()" />
      <div class="imageUpload active">
        <div class="modalTitle">
          <div class="big">Verify your identity</div>
          <div class="small">Upload verification document</div>
        </div>
        <div class="uploadBox">
          <div class="iconWrapper">
            <i class="icon-car" v-if="documentChoice === 'License'" />
            <i class="icon-passport" v-if="documentChoice === 'Passport'" />
          </div>
          <span>{{ documentChoice }} Upload</span>
          <div>
            <p v-if="!documentFront" style="cursor: pointer" @click="$refs.documentFront.click()">
              Upload front face photo page <i class="icon-plus" />
            </p>
            <!--Add class "upload1" to "step3" when 1st image is uploaded-->
            <img
              v-else
              style="cursor: pointer"
              :src="previewFront"
              alt="Preview"
              @click="$refs.documentFront.click()"
            />
            <input
              ref="documentFront"
              type="file"
              style="display: none"
              @change="updatePreview('Front')"
            />
          </div>
          <div v-if="documentChoice === 'License'">
            <p v-if="!documentBack" style="cursor: pointer" @click="$refs.documentBack.click()">
              Upload back barcode page <i class="icon-plus" />
            </p>
            <!--Add class "upload2" to "step3" when 2nd image is uploaded-->
            <img
              v-else
              style="cursor: pointer"
              :src="previewBack"
              alt="Preview"
              @click="$refs.documentBack.click()"
            />
            <input
              ref="documentBack"
              type="file"
              style="display: none"
              @change="updatePreview('Back')"
            />
          </div>
          <div class="fullRow">
            <InputButton
              :isLoading="isLoading"
              label="Next"
              color="primary"
              :disabled="!documentFront"
              type="submit"
            />
            <InputButton color="secondary" label="Back" type="button" @click="back" />
            <InputButton label="Cancel" color="secondary" type="button" @click="exited()" />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped lang="scss">
  @import '../../../../../@assets/styles/variables.scss';

  .cancelCTA,
  .cyanCTA {
    margin: 0 auto;
    display: block;
  }
  .photoWrapper {
    width: 265px;
    height: 185px;
    margin: 0 auto;
    border-radius: 10px;
    background-image: url('../../../../../@assets/img/driversample.jpg');
    background-position: center center;
    background-size: cover;
  }
  .licenceErrors {
    float: left;
    width: 80%;
    margin: 30px 10%;
    text-align: center;
    .photoError {
      display: inline-block;
      margin: 5px 10px;
      i {
        font-size: 20px;
      }
    }
    span {
      color: $black;
      opacity: 0.6;
      font-size: 13px;
      font-weight: 500;
      float: right;
      padding-top: 2px;
      margin-left: 10px;
    }
  }
  .step3 {
    height: 670px;
    margin-top: -335px;
  }
  .step3.upload1 {
    height: 870px;
    margin-top: -435px;
  }
  .step3.upload2 {
    height: 990px;
    margin-top: -495px;
  }
  .imageValidation,
  .imageUpload {
    display: none;
  }
  .imageValidation.active,
  .imageUpload.active {
    display: block;
  }
  .iconWrapper {
    width: 72px;
    height: 72px;
    background: $white;
    box-shadow: 0px 4px 24px 0px rgba(1, 50, 164, 0.3);
    border-radius: 200px;
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .iconWrapper:after {
    content: '';
    width: 85px;
    height: 85px;
    border-radius: 200px;
    border: 1px solid $black;
    opacity: 0.1;
    position: absolute;
    top: -6px;
    left: -6px;
  }
  .uploadBox {
    float: left;
    width: 78%;
    text-align: center;
    background: rgba(255, 255, 255, 0.6);
    border: 1px dashed $lightblue;
    border-radius: 10px;
    margin: 0 11%;
    padding: 50px;
    span {
      float: left;
      width: 100%;
      color: $black;
      font-size: 20px;
      text-align: center;
      font-weight: 600;
      margin-top: 15px;
    }
    p {
      font-size: 18px;
      color: $lightblue;
      font-weight: 400;
      margin-top: 5px;
      margin-bottom: 30px;
      float: left;
      width: 100%;
      transition: $small;
    }
    p:hover {
      color: $petrol;
    }
    img {
      max-height: 200px;
    }
  }
  .imageUpload {
    .icon-car {
      font-size: 21px;
      position: relative;
      top: 23px;
      color: $petrol;
    }

    .icon-passport {
      font-size: 51px;
      position: relative;
      top: 10px;
      color: $petrol;
    }
  }

  @media screen and (max-width: 640px) {
    .uploadBox {
      width: 100%;
      margin: 0;
    }
  }

  @media screen and (max-height: 900px) {
    .modalWrapper {
      height: 90% !important;
      top: 5%;
      margin-top: 0 !important;
      overflow: auto;
    }
  }
  @media screen and (max-height: 995px) {
    .modalWrapper.step3.upload2 {
      height: 90% !important;
      top: 5%;
      margin-top: 0 !important;
      overflow: auto;
    }
  }
</style>

<script>
  import ExitIconButton from '../../../../@components/ExitIconButton.vue';
  import InputButton from '../../../../@components/InputButton.vue';
  import httpClient from '../../../../../@libs/httpClient';
  import apiEndpoints from '../../../../../@enums/apiEndpoints';
  import Banner from '../../../../@components/Banner.vue';

  export default {
    name: 'Step3',
    props: {
      documentChoice: {
        type: String,
        required: true,
      },
      back: {
        type: Function,
        default: () => {},
      },
      next: {
        type: Function,
        default: () => {},
      },
    },
    components: { Banner, InputButton, ExitIconButton },
    data() {
      return {
        exit: false,
        documentFront: null,
        documentBack: null,
        previewFront: null,
        previewBack: null,
        isLoading: false,
        showTip: true,
        errorMessage: null,
        showBanner: false,
      };
    },
    watch: {
      documentChoice() {
        this.documentFront = null;
        this.documentBack = null;
        this.previewFront = null;
        this.previewBack = null;
      },
    },
    methods: {
      async uploadSelectedFiles() {
        const formData = new FormData();
        formData.append('DocumentFront', this.documentFront);

        if (this.documentBack) formData.append('DocumentBack', this.documentBack);

        try {
          this.isLoading = true;

          const userDetail = await httpClient.post(apiEndpoints.userDetails.kycDocument, {
            replace: {
              id: this.$store.state.userDetails.userDetail.id,
              documentType: this.documentChoice,
            },
            includeContentType: false,
            form: formData,
          });

          await this.$store.commit('userDetails/updateUser', {
            userDetail,
          });

          if (userDetail.kyc.documentsUploaded && !userDetail.kyc.documentsResubmit) this.next();
        } catch (error) {
          this.showBanner = true;
          this.errorMessage = error.toString();
        } finally {
          this.isLoading = false;
        }
      },
      exited() {
        this.exit = !this.exit;
        this.$router.push({ path: '/dashboard' });
      },
      updatePreview(whichPreview) {
        this[`document${whichPreview}`] = this.$refs[`document${whichPreview}`].files[0];
        this[`preview${whichPreview}`] = URL.createObjectURL(this[`document${whichPreview}`]);
      },
    },
  };
</script>
