<template>
  <div :class="['input', { 'completed-input': value || value !== '' }]">
    <label :for="id">
      <span>{{ label }}</span>
    </label>
    <input
      :id="id"
      ref="inputValue"
      :placeholder="placeholder"
      :type="type"
      :value="value"
      @input="updateValue"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      v-mask="mask"
      :readonly="readonly"
      :disabled="disabled"
      :required="required"
    />
  </div>
</template>

<style lang="scss" scoped>
  @import '../../@assets/styles/variables.scss';

  .input {
    position: relative;

    label {
      // what is up with this crazy css? I assume this was supposed to be on the
      // input's outline but since that is not the case anymore this should be
      // cleaned up and not use absolute, justify-content and all those conflicting
      // styles
      position: absolute;
      top: -1.7rem;
      left: 1.5rem;
      font-size: 1.3rem;
      line-height: 1.3rem;
      color: #00d8d2;
      padding: 0 0.5rem;
      text-transform: uppercase;
      height: 2.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    label:before {
      position: absolute;
      left: 0;
      width: 100%;
      height: 1.2rem;
      top: 0;
      // background-color: #f7f9fc;
      z-index: -1;
    }

    input {
      width: 100%;
      padding: 20px;
      background: $white;
      box-shadow: 1px 4px 10px rgba(1, 50, 164, 0.08);
      border-radius: 5px;
      border: solid 1px transparent;
      font-style: normal;
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 1.9rem;
      // letter-spacing: 0.2ems;
      color: #0134aa;
    }

    input:focus {
      outline-style: solid !important;
      outline-color: #00d8d2;
      outline-width: 1px;
      box-shadow: 2px 14px 17px rgba(0, 216, 210, 0.1);
    }
  }

  .input input::-webkit-input-placeholder {
    /* Edge */
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #abc4ff;
  }

  .input input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #abc4ff;
  }

  .input input::placeholder {
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #abc4ff;
  }

  .input.field-error {
    label {
      color: red;
      display: flex !important;
    }
  }

  // .input:not(.completed-input) {
  //   label {
  //     display: none;
  //   }
  // }

  .input.completed-input input:disabled {
    color: $lightblue;
  }

  /*-------- Changing label to white for pages with white bg -------*/

  .createGC .input label:before,
  .balanceGC .input label:before,
  .redeemCards .input label:before,
  .ledgers .input label:before,
  .verifyPhone .input label:before,
  .verifyMail .input label:before,
  .accountDetails .input label:before,
  .transferGC .input label:before,
  .recreateGC .input label:before {
    background-color: #fff;
  }

  .accountDetails .modalWrapper .input label:before {
    background: #f7f9fc;
  }

  .addressBook .topActions .input input {
    box-shadow: none !important;
    transition: $small;
  }

  .addressBook .topActions .input:hover {
    box-shadow: 1px 4px 10px rgba(1, 50, 164, 0.08);
  }

  .transfer .input input {
    text-align: center;
  }

  @media screen and (max-width: 640px) {
    .input {
      margin-bottom: 25px !important;
      label {
        top: 0;
        transform: translateY(-100%);
      }
    }
  }
</style>

<script>
  import { v4 as uuidv4, validate } from 'uuid';

  export default {
    name: 'InputText',
    props: {
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
      },
      type: {
        type: String,
        default: 'text',
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      mask: {
        type: String,
        default: '',
        required: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        id: '',
      };
    },
    methods: {
      updateValue() {
        this.$emit('input', this.$refs.inputValue.value);
      },
    },
    created() {
      this.id = uuidv4();
    },
  };
</script>
