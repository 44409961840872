<template>
  <div class="mainContainer">
<!--    <ClosingBanner />-->
    <div class="innerContainer">
      <div class="titleBox">
        <h2>
          Support Tickets
          <span> View existing or create new tickets </span>
        </h2>
      </div>

      <div v-if="!tickets">loading...</div>
      <div v-else>
        <h3>Your Tickets</h3>
        <ul>
          <li v-for="ticket in tickets" :key="ticket.id">
            <router-link :to="`/tickets/${ticket.id}`">
              {{ ticket.title }}
              <sup>{{ ticket.status }}</sup>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- <span v-if="value"> ${{ value.toFixed(2) }} </span> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .input-button {
    margin-top: 15px;
  }
</style>

<script>
  import services from '../../../@libs/services';
  import ClosingBanner from '../../../@@layouts/Default/ClosingBanner/ClosingBanner.vue';

  export default {
    name: 'TicketsList',
    components: { ClosingBanner },
    props: {},
    data() {
      return {
        tickets: null,
        isSubmitting: false,
        data: {
          title: '',
          message: '',
        },
      };
    },
    async created() {
      await this.loadData();
    },
    computed: {
      formValid() {
        return this.data.title && this.data.message;
      },
      loggedIn() {
        return this.$store.state.userDetails.loggedIn;
      },
      emailAddress() {
        if (!this.loggedIn) return '';
        return this.$store.state.userDetails.userDetail.emailAddress;
      },
      pageUrl() {
        return this.$route.query.pageUrl || '';
      },
    },
    methods: {
      async loadData() {
        const tickets = await services.ticketService.getTickets();
        this.tickets = tickets;
        console.log({ tickets });
      },
    },
  };
</script>
