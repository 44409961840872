<template>
  <div class="generalMainContainer">
    <form v-if="groupDetails">
      <div class="loginInnerContainer">
        <div class="headerTitle">
          <h2>Group Details</h2>
        </div>
        <div class="inputText">
          <InputText v-model.trim="groupDetailsEdit.id" disabled label="Domain ID" />
        </div>
        <div class="inputText">
          <InputText v-model.trim="groupDetailsEdit.domain" :disabled="!edit" label="Domain Name" />
        </div>
        <div class="inputText">
          <InputText
            :disabled="!edit"
            v-model.trim="groupDetailsEdit.groupName"
            label="Group Name"
          />
        </div>
        <!-- Edit CheckBox -->
        <div class="editBox">
          <InputCheck
            style="font-size: larger"
            :showHelp="false"
            v-model.trim="edit"
            label="Edit"
          />
        </div>

        <InputButton
          :isLoading="isLoading"
          label="Submit"
          type="submit"
          color="primary"
          @click="updateGroupDetails"
        />
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';

  .editBox {
    span {
      width: 3rem;
    }
    display: flex;
    justify-content: center;
    margin: 30px 0px;
  }
</style>

<script>
  import cloneDeep from 'lodash/cloneDeep';
  import services from '../../@libs/services';
  import InputText from '../@components/InputText.vue';
  import InputButton from '../@components/InputButton.vue';
  import InputCheck from '../@components/InputCheck.vue';

  export default {
    name: 'GroupDetails',
    components: { InputText, InputButton, InputCheck },
    data() {
      return {
        edit: false,
        isLoading: false,
        groupDetailsEdit: {
          id: null,
          domain: null,
          groupName: null,
        },
      };
    },
    methods: {
      async updateGroupDetails() {
        try {
          this.isLoading = true;

          const { id: groupId } = this.groupDetails;
          await services.groupDetailService.putById(groupId, this.groupDetailsEdit);
        } catch (error) {
          await services.groupDetailService.getConfig();
          this.edit = false;
        } finally {
          this.isLoading = false;
        }
      },
    },
    computed: {
      groupDetails() {
        return this.$store.state.groupDetails.groupDetails;
      },
    },
    watch: {
      groupDetails() {
        this.groupDetailsEdit = cloneDeep(this.groupDetails);
      },
    },
    mounted() {
      this.groupDetailsEdit = cloneDeep(this.groupDetails);
    },
  };
</script>
