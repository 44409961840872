<template>
  <div :class="['input', { 'completed-input': value || value !== '' }]">
    <label :for="id">
      <span>{{ label }}</span>
    </label>
    <textarea
      :id="id"
      ref="inputValue"
      :placeholder="placeholder"
      :value="value"
      @input="updateValue"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      :disabled="disabled"
    />
  </div>
</template>

<style lang="scss" scoped>
  @import '../../@assets/styles/variables.scss';

  .input {
    position: relative;

    label {
      font-size: 1.3rem;
      padding: 1.3rem 0 0.5rem 2rem;
      line-height: 1.3rem;
      color: #00d8d2;
      text-transform: uppercase;
      display: block;
    }

    textarea {
      width: 100%;
      padding: 20px;
      background: $white;
      box-shadow: 1px 4px 10px rgba(1, 50, 164, 0.08);
      border-radius: 5px;
      border: solid 1px transparent;
      font-style: normal;
      font-weight: 300;
      font-size: 1.4rem;
      line-height: 1.9rem;
      // letter-spacing: 0.2ems;
      color: #0134aa;
    }

    input:focus {
      outline-style: solid !important;
      outline-color: #00d8d2;
      outline-width: 1px;
      box-shadow: 2px 14px 17px rgba(0, 216, 210, 0.1);
    }
  }
</style>

<script>
  import { v4 as uuidv4, validate } from 'uuid';

  export default {
    name: 'InputTextarea',
    props: {
      label: {
        type: String,
        default: '',
        validator(value) {
          return value;
        },
      },
      placeholder: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: '',
      },
      type: {
        type: String,
        default: 'text',
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      mask: {
        type: String,
        default: '',
        required: false,
      },
    },
    data() {
      return {
        id: '',
      };
    },
    methods: {
      updateValue() {
        this.$emit('input', this.$refs.inputValue.value);
      },
    },
    created() {
      this.id = uuidv4();
    },
  };
</script>
