<template>
  <div class="generalMainContainer">
    <div class="generalInnerContainer">
      <div>
        <h2>Disable 2FA</h2>
      </div>
      <form id="disable2FaForm" @submit.prevent="handleLoginOrRegisterNew">
        <div>
          <div v-if="hasSentResetCode" class="inputText">
            <InputText v-model.trim="pin" label="Reset Pin" placeholder="Reset Pin" />
          </div>

          <InputButton
            :isLoading="isLoading"
            v-if="hasSentResetCode"
            color="primary"
            @click="disable2Fa"
            label="Disable 2FA"
            :disabled="disabledSMSCodeButton"
          />

          <InputButton
            :isLoading="isLoading"
            v-else
            color="primary"
            @click="sendResetCode"
            label="Send SMS Reset Code"
          />

          &nbsp;

          <InputButton color="secondary" @click="cancel" label="Cancel" />
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';
</style>

<script>
  import services from '../../@libs/services';
  import httpClient from '../../@libs/httpClient';
  import { apiEndpoints } from '../../@enums';
  import InputText from '../@components/InputText.vue';
  import InputButton from '../@components/InputButton.vue';

  export default {
    name: 'Disable2fa',
    components: { InputText, InputButton },
    data() {
      return {
        hasSentResetCode: false,
        pin: '',
        isLoading: false,
      };
    },
    computed: {
      userId() {
        return this.$store.state.userDetails.userDetail.id;
      },
      disabledSMSCodeButton() {
        return this.pin.length < 6;
      },
    },
    methods: {
      async sendResetCode() {
        try {
          this.isLoading = true;
          await httpClient.post(apiEndpoints.userDetails.reset2fa, {
            parseJson: false,
            replace: {
              id: this.userId,
            },
          });
          this.hasSentResetCode = true;
        } finally {
          this.isLoading = false;
        }
      },
      async disable2Fa() {
        try {
          this.isLoading = true;
          await services.userDetailService.disable2Fa(this.pin);
          await this.$router.push('/login');
        } finally {
          this.isLoading = false;
        }
      },
      cancel() {
        this.$router.push('/login');
        // this.$store.state.userDetails.requires2Fa = false;
      },
    },
  };
</script>
