<template>
  <div class="mainContainer recreateGC">
    <Banner
      :show.sync="showBanner"
      :autohide="true"
      :color="bannerData.color"
      :label="bannerData.label"
      :message="bannerData.message"
    />
    <div class="innerContainer">
      <div class="fullRow titleBox">
        <img alt="present" src="../../../@assets/img/dashboard/redeem-1.svg" />
        <h2>Recreate Gift Card<span>Please enter details</span></h2>
        <div v-if="newGiftCardCode && newGiftCardPin" class="code-pin">
          <h3>
            Code: <span>{{ newGiftCardCode }}</span>
          </h3>
          <h3>
            PIN: <span>{{ newGiftCardPin }}</span>
          </h3>
        </div>
      </div>
      <div class="fullRow">
        <div class="form">
          <InputText v-model.trim="code" label="Code" placeholder="Code" />
          <InputText v-model.trim="pin" label="PIN" placeholder="PIN" />
          <InputButton
            :isLoading="isLoading"
            :disabled="disableSubmitButton"
            type="button"
            @click="transfer"
            color="primary"
            label="Recreate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';

  .code-pin {
    display: flex;
    align-items: center;
    flex-direction: column;
    h3 {
      margin-bottom: 0px;
      padding: 0px;
      font-size: 2.5rem;
      color: $black;
      span {
        color: $lightblue;
        font-style: italic;
        font-weight: 900;
      }
    }
  }
  .recreateGC img {
    display: inline-block;
    margin-right: 20px;
    top: 10px;
    position: absolute;
    left: 0;
  }

  .recreateGC .form {
    margin-top: 50px;
    .input {
      margin-bottom: 30px;
    }
  }
</style>

<script>
  import services from '../../../@libs/services';
  import Banner from '../../@components/Banner.vue';
  import InputButton from '../../@components/InputButton.vue';
  import InputText from '../../@components/InputText.vue';

  export default {
    components: { InputButton, Banner, InputText },
    data() {
      return {
        displayCongrats: false,
        displayError: false,
        code: '',
        pin: '',
        newGiftCardCode: null,
        newGiftCardPin: null,
        isLoading: false,
        showBanner: false,
        bannerData: {
          label: '',
          message: '',
          color: 'success',
        },
      };
    },
    computed: {
      disableSubmitButton() {
        return this.code === '' || this.pin === '';
      },
    },
    methods: {
      async transfer() {
        try {
          this.isLoading = true;

          const result = await services.giftCardService.recreateCard(this.code, this.pin);

          this.newGiftCardCode = result.code;
          this.newGiftCardPin = result.pin;

          this.bannerData.color = 'success';
          this.bannerData.label = 'Recreate Gift Card Success';
          this.bannerData.message = "Success! You've created a new  Gift Code and PIN!";
        } catch (error) {
          this.bannerData.color = 'error';
          this.bannerData.label = 'Recreate Gift Card Error';
          this.bannerData.message =
            // eslint-disable-next-line no-multi-str
            'Something went wrong re-creating gift card, please verify the information you entered is\
          correct!';
        } finally {
          this.isLoading = false;
          this.showBanner = true;
        }
      },
    },
    mounted() {
      if (!this.$store.state.userDetails.userDetail.isLevel2) {
        this.$router.push('/giftCard/pii');
      }
    },
  };
</script>
