import baseService from '../baseService';
import store from '../../../@store';
import { apiEndpoints } from '../../../@enums';
import eventBus from '../../eventBus';
import services from '../index';

/**
 * Contains all user detail service methods
 */
export default {
  ...baseService,
  apiEndpoints: apiEndpoints.userDetails,

  /**
   * Perform a user login and update the token
   * @param emailAddress Required email address to login
   * @param password Required password to login with
   * @returns {Promise<void>}
   */
  async login(emailAddress, password) {
    const result = await this.httpClient.post(apiEndpoints.userDetails.login, {
      payload: {
        emailAddress,
        password,
      },
    });

    return this.verifyLoginResult(result);
  },

  /**
   * Login with 2fa pin
   * @param pin Required pin for challenge
   * @returns {Promise<*|null>}
   */
  async loginWith2Fa(pin) {
    const result = await this.httpClient.post(apiEndpoints.userDetails.loginWith2Fa, {
      payload: {
        pin,
      },
    });

    return this.verifyLoginResult(result);
  },

  async initialize() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) return;

    try {
      await this.loginWithRefreshToken(refreshToken);
    } catch (e) {
      eventBus.raiseEvent({ name: eventBus.eventTypes.app.error, payload: e.message });
    }
  },

  /**
   * Login using a refresh token
   * @param refreshToken Required refresh token to pass to API
   * @returns {Promise<*|null>}
   */
  async loginWithRefreshToken(refreshToken) {
    try {
      const result = await this.httpClient.post(apiEndpoints.userDetails.loginWithRefreshToken, {
        payload: refreshToken,
      });

      return this.verifyLoginResult(result);
    } catch {
      throw new Error('Unable to auto login, please manually login');
    }
  },

  /**
   * Verify login result from two login and loginWith2fa
   * @param result Required result from API
   * @returns {Promise<null|*>}
   */
  async verifyLoginResult(result) {
    const { authToken, refreshToken } = result || {};

    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }

    if (authToken) {
      this.httpClient.setBearerToken(authToken);
      eventBus.raiseEvent({ name: eventBus.eventTypes.app.loginSuccess, payload: result });

      await store.commit('userDetails/login', { loginResult: result });
      this.currentUser = result;

      return result;
    }
    eventBus.raiseEvent({ name: eventBus.eventTypes.app.loginFail });

    return null;
  },

  /**
   * Register a new user
   * @param emailAddress Required email address to register
   * @param password Required password for new user
   * @returns {Promise<boolean>}
   * @constructor
   */
  async registerNewUser(emailAddress, password) {
    const result = await this.httpClient.post(apiEndpoints.userDetails.post, {
      payload: {
        emailAddress,
        password,
      },
    });

    return !!result.id;

    // TODO: let the user know their registration failed
  },

  /**
   * Verify email address
   * @param key Required key from email
   * @returns {Promise<string|*>}
   */
  async verifyEmailAddress(key) {
    const userDetail = await this.httpClient.post(apiEndpoints.userDetails.verifyEmailAddress, {
      payload: key,
    });

    await store.commit('userDetails/updateUser', { userDetail });

    return userDetail;
  },

  async updatePhoneNumber(phoneNumber) {
    const userDetail = await this.httpClient.put(apiEndpoints.userDetails.updatePhoneNumber, {
      payload: phoneNumber,
      replace: {
        id: this.currentUser.id,
      },
    });

    await store.commit('userDetails/updateUser', { userDetail });

    return userDetail;
  },

  /**
   * Verify phone number
   * @param key Required key from text message
   * @returns {Promise<string|*>}
   */
  async verifyPhoneNumber(key) {
    const userDetail = await this.httpClient.post(apiEndpoints.userDetails.verifyPhoneNumber, {
      payload: key,
    });

    await store.commit('userDetails/updateUser', { userDetail });

    return userDetail;
  },

  /**
   * Resend phone code SMS for verification
   * @param key Required key from text message
   * @returns {Promise<string|*>}
   */
  async resendSMS() {
    const userDetail = await this.httpClient.post(apiEndpoints.userDetails.resendSMS, {
      replace: {
        id: this.currentUser.id,
      },
      parseJson: false,
    });

    return userDetail;
  },

  /**
   * Log user out
   * @returns {Promise<void>}
   */
  async logout() {
    localStorage.clear();
    this.httpClient.setBearerToken(null);

    await store.commit('userDetails/logout');
  },

  /**
   * Change a users password
   * @param currentPassword Required users current password
   * @param newPassword Required new users password
   * @returns {Promise<void>}
   */
  async changePassword(currentPassword, newPassword, twoFa) {
    const userDetail = await this.putById(this.currentUser.id, {
      ...this.currentUser,
      password: newPassword,
      currentPassword,
      twoFa,
    });

    await store.commit('userDetails/updateUser', { userDetail });

    return userDetail;
  },

  /**
   * Enables 2FA for current user
   * @param masterCode Optional masterCode that was initially received
   * @param pin Optional PIN challenge response to 2FA
   * @returns {Promise<{otpAuthUrl, secretKey}|*>}
   */
  async enable2Fa(masterCode = null, pin = null) {
    const { id } = this.currentUser;

    const result = await this.httpClient.post(apiEndpoints.userDetails.enable2Fa, {
      replace: {
        id,
      },
      payload: {
        secretKey: masterCode,
        pin,
      },
    });

    if (!result.id) return {};
    // TODO: let the user know the secret key failed

    await store.commit('userDetails/updateUser', { userDetail: result });

    const { secretKey, otpAuthUrl } = result;

    if (secretKey) {
      return { secretKey, otpAuthUrl };
    }
    return result;
  },

  /**
   * Disables 2FA for current user
   * @param pin Required PIN Number
   * @returns {Promise<object|*>}
   */
  async disable2Fa(pin) {
    const { id } = this.currentUser;

    const result = await this.httpClient.post(apiEndpoints.userDetails.disable2Fa, {
      replace: {
        id,
      },
      payload: {
        pin,
      },
    });

    if (!result.id) return {};
    // TODO: let the user know the secret key failed

    await store.commit('userDetails/updateUser', { userDetail: result });

    return result;
  },

  /**
   * Reset user password
   * @returns {Promise<string|*>}
   * @constructor
   */
  async resetPassword(emailAddress) {
    await this.httpClient.post(apiEndpoints.userDetails.resetPassword, {
      parseJson: false,
      payload: emailAddress,
    });
  },
  /**
   * Reset password with provided reset code
   * @param resetCode
   * @param password
   * @returns {Promise<void>}
   */
  async resetPasswordWithCode({ resetCode, password }) {
    await this.httpClient.post(apiEndpoints.userDetails.resetPasswordWithResetCode, {
      parseJson: false,
      replace: {
        resetCode,
      },
      payload: password,
    });
  },
};
