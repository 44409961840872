export default {
  namespaced: true,
  state: () => (
    {
      groupId: null,
      domain: null,
      groupName: null,
      groupDetails: null,
      maxBulkRedeemCount: 0,
      enableBlockChainTransfers: false,
    }
  ),
  mutations: {
    setGroupDetails(state, { groupDetails }) {
      const {
        id,
        groupName,
        domain,
        maxBulkRedeemCount,
        enableBlockChainTransfers,
      } = groupDetails;

      state.groupId = id;
      state.domain = domain;
      state.groupName = groupName;
      state.groupDetails = groupDetails;
      state.enableBlockChainTransfers = enableBlockChainTransfers;
      state.maxBulkRedeemCount = maxBulkRedeemCount;
    },
  },
};
