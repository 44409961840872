<template>
  <div class="mainContainer">
    <div class="innerContainer">
      <div v-if="!ticket">
        loading...
      </div>

      <div v-else>
        <div class="titleBox">
          <h2>
            {{ ticket && ticket.title }}
            <span> Opened at: {{ new Date(ticket.created).toLocaleString() }} </span>
          </h2>
        </div>
        <div>
          <div
            v-for="message in messages"
            :key="message.id"
            :class="['ticket-message', message.isFromEndUser ? 'my-message' : 'agent-message']"
          >
            <span class="message-date">{{ new Date(message.created).toLocaleString() }}</span>
            {{ message.message }}
          </div>
          <div class="chat-attachments">
            <div
              v-for="(attachment, index) in attachments"
              :key="index"
              class="attachment"
            >
              <a
                v-if="attachment.url"
                rel="noopener noreferrer"
                :href="attachment.url"
                target="_blank"
              >
                <img
                  style="cursor: pointer"
                  :src="attachment.url"
                  alt="Attachment Image"
                />
              </a>
            </div>
          </div>
          <div class="resolve-action">
            <div v-if="ticket.status === resolvedStatus" class="resolved-message">
              This ticket has been closed
            </div>
            <div v-else>
              <InputButton
                type="button"
                label="Mark as resolved"
                color="primary"
                :loading="isMarkingResolved"
                @click="markResolved()"
              />
            </div>
          </div>
        </div>
        <div v-if="ticket.status !== resolvedStatus">
          <h3>Add new reply</h3>
          <div>
            <p style="cursor: pointer" @click="$refs.documentFront.click()">
              Upload image to ticket <i class="icon-plus" />
            </p>
            <input
              ref="documentFront"
              type="file"
              style="display: none"
              @change="uploadFileToTicket"
            />
          </div>
          <div class="form">
            <form @submit.prevent.stop="addReply()">
              <InputTextarea v-model.trim="data.message" label="Information about your problem" placeholder="Message" />
              <InputButton
                type="submit"
                label="Send Message"
                color="primary"
                :disabled="!formValid"
                :loading="isSubmitting"
              />
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .resolved-message {
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
  }
  .input-button {
    margin-top: 15px;
  }
  .ticket-message {
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    .message-date {
      font-size: 1.3rem;
      padding: 0 0 0.5rem 0;
      line-height: 1.3rem;
      color: #00d8d2;
      display: block;
    }
    &.my-message {
      background: #c1e2ff;
      margin-left: 30px;
    }

    &.agent-message {
      background: #ededed;
      margin-right: 30px;
    }
  }
  .chat-attachments {
    display: flex;
    flex-wrap: wrap;
  }
  .attachment {
    img {
      width: 92px;
      padding-right: 4px;
    }
  }
</style>

<script>
  import services from '../../../@libs/services';
  import InputButton from '../../@components/InputButton.vue';
  import InputTextarea from '../../@components/InputTextarea.vue';

  export default {
    name: 'TicketDetails',
    components: {
      InputTextarea,
      InputButton,
    },
    props: {},
    data() {
      return {
        ticket: null,
        documentFront: null,
        isSubmitting: false,
        isMarkingResolved: false,
        data: {
          message: '',
        },
      };
    },
    watch: {
      documentChoice() {
        this.documentFront = null;
      },
    },
    async created() {
      await this.loadData();
    },
    computed: {
      id() {
        return this.$route.params.id;
      },
      formValid() {
        return this.data.message;
      },
      resolvedStatus() {
        return 'resolved';
      },
      messages() {
        return (this.ticket && this.ticket.ticketMessages) || [];
      },
      attachments() {
        return (this.ticket && this.ticket.attachments) || [];
      },
    },
    methods: {
      async loadData() {
        await this.fetchTicket();
      },
      async fetchTicket() {
        const ticket = await services.ticketService.getTicket(this.id);
        this.ticket = ticket;
      },
      async addReply() {
        if (this.formValid) {
          this.isSubmitting = true;
          try {
            const ticket = await services.ticketService.createTicketMessage(this.ticket.id, this.data.message);
            await this.fetchTicket();
            this.data.message = '';
          } finally {
            this.isSubmitting = false;
          }
        }
      },
      async markResolved() {
        if (window.confirm('Are you sure you want to mark this ticket as resolved?')) {
          this.isMarkingResolved = true;
          try {
            const ticket = await services.ticketService.changeTicketStatus(this.ticket.id, this.resolvedStatus);
            this.ticket = ticket;
          } finally {
            this.isMarkingResolved = false;
          }
        }
      },
      async uploadFileToTicket() {
        this.documentFront = this.$refs.documentFront.files[0];
        const formData = new FormData();
        formData.append('DocumentFront', this.documentFront);
        this.isSubmitting = true;
        try {
          const ticket = await services.ticketService.uploadFileToTicket(this.ticket.id, formData);
          await this.fetchTicket();
        } finally {
          this.isSubmitting = false;
        }
      },
    },
  };
</script>
