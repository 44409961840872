<template>
  <div>
    <div class="header">
      <img src="../../@assets/img/logo-blue.svg" alt="Home" />
    </div>
    <div class="offline-message">
      Site is currently offline for maintenance, please come back later.
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .header {
    margin: 30px;
  }
  .offline-message {
    margin: 30px;
    font-size: 24px;
    color: red;
  }
</style>

<script>

  export default {
    name: 'Offline',
    components: {
    },
    props: {},
    data() {
      return {
      };
    },
    created() {
    },
    computed: {
    },
    methods: {
    },
  };
</script>
