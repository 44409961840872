<template>
  <div :class="['dropdown-list', { opened }]" @click="opened = !opened">
    <div class="header">
      <label>
        <span>
          {{ label }}
        </span>
      </label>
      <div class="text">
        <span class="title-part">{{ prepend }}</span>
        <input
          class="wallet"
          @input="(e) => $emit('input', e.target.value)"
          :value="text"
          :disabled="!allowEdit"
          :placeholder="placeholder"
        />
      </div>
      <div class="icon-chevron" />
    </div>

    <ul class="list">
      <li v-for="(item, index) in items" :key="index" @click="selectionChanged(item)">
        <span class="details">
          <i v-if="item.prepend" :class="item.prepend" />
          <span class="title-part">{{ item.text || item }}</span>
        </span>
        <span class="fees">{{ item.append }}</span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @import '../../@assets/styles/variables.scss';
  .dropdown-list {
    float: left;
    position: relative;
    width: 48.5%;
    margin: 0 0 20px 0;
    border-radius: 5px;
    color: #abc4ff;
    box-shadow: 1px 4px 10px rgb(1 50 164 / 8%);
    border: none;
    background: #fff;
    &:hover {
      cursor: pointer;
    }
    & [disabled] {
      cursor: pointer;
    }
    .icon-chevron {
      font-size: 10px;
      position: absolute;
      right: 15px;
      transition: all ease-in-out 0.3s;
      top: 25px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 82%;
      cursor: pointer;
      margin: 22px 14.5px !important;

      label {
        display: none;
      }

      .text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
      }

      .title-part {
        margin-right: 0.5rem;
        font-size: 1.4rem;
        line-height: 104.74%;
        letter-spacing: 0.04em;
        display: inline-block;
        white-space: nowrap;
      }

      .icon {
        margin-right: 0.5rem;
      }

      .wallet {
        margin-right: 0.5rem;
        display: inline-block;
        font-size: 14px;
        line-height: 104.74%;
        font-weight: 400;
        width: 100% !important;
        color: #0134aa;
      }
      .wallet::placeholder {
        color: #abc4ff;
      }

      .amount {
        font-size: 1.3rem;
        line-height: 104.74%;
        color: #010e2d;
        font-weight: 600;
      }
    }

    .list {
      padding: 1rem;
      border-top: solid 1px #e2ebff;
      display: none;
      cursor: pointer;
      position: absolute;
      top: 59px;
      width: 100%;
      background: #fff;
      margin-top: 0 !important;
      border-radius: 5px;
      height: 160px;
      overflow: auto;
      z-index: 2;
      box-shadow: $boxshadow;

      li {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: #808696;
        line-height: 104.74%;
        font-weight: 600;
        font-size: 1.2rem;
        padding: 1rem 1rem;
        cursor: pointer;

        .details {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #abc4ff;
          font-weight: 400;
          width: 100%;
          :hover {
            color: #0134aa;
          }
          span {
            width: 100%;
            text-align: left;
          }

          .icon {
            margin-right: 0.5rem;
          }

          .title-part {
            font-size: 1.6rem;
          }
        }
      }

      li.selected {
        color: #010e2d;
        background: #ffffff;
        box-shadow: 0 0 31px -11px rgba(5, 29, 86, 0.16);
        border-radius: 10px;
      }
    }
  }

  .dropdown-list.opened {
    .header {
      .icon-chevron {
        transform: rotate(180deg);
        top: 23px;
      }
    }

    .list {
      display: block !important;
    }
  }

  /*--------- Currency Dropdown style-------*/

  .currencyStyle.dropdown-list {
    float: left;
    width: 100%;
    border: 1px solid $greyblue;
    border-radius: 10px;
    box-shadow: 0px 14px 31px 0px rgba(5, 29, 86, 0.06);
    .header .wallet::placeholder {
      color: $black;
      font-size: 14px;
      font-weight: 600;
    }
    .list {
      height: auto;
      border: 1px solid $greyblue;
      border-radius: 10px;
      top: 61px;
    }
    .list li {
      position: relative;
      border-radius: 10px;
      transition: $small;
    }
    .list li:hover {
      box-shadow: 0px 0px 31px -11px rgba(5, 29, 86, 0.16);
    }
    .list li .details .title-part {
      transition: $small;
    }
    .list li:hover .details .title-part {
      color: $black;
    }
    .list li .details {
      color: $grey;
      font-size: 16px;
      font-weight: 600;
      transition: $small;
    }
    .list i {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .list i:after {
      content: '';
      position: relative;
      display: block;
      width: 30px;
      height: 30px;
      background: #fff;
      border: 1px solid $greyblue;
      border-radius: 100%;
    }
    .list i:before {
      position: absolute;
      // top: 7px;
      // left: 10px;
      z-index: 1;
    }
    .list li i.icon-bitcoin:before {
      transform: rotate(-15deg);
      color: #ff9800;
    }
    .list li i.icon-eth:before {
      color: $black;
    }

    .icon-chevron {
      color: $black;
    }
  }

  .transfer .currencyStyle.dropdown-list,
  .editAnAddress .currencyStyle.dropdown-list,
  .addAnAddress .currencyStyle.dropdown-list {
    border: none;
    border-radius: 5px;
    .icon-chevron:before {
      color: $lightblue;
    }
    .wallet::placeholder {
      color: $lightblue;
      font-weight: 500;
    }
    .list i {
      text-align: center;
    }
    .list i:before {
      width: 30px;
      left: 0;
    }
  }

  /*---------End Currency Dropdown style-------*/
</style>

<script>
  export default {
    name: 'InputDropDown_V2',
    props: {
      items: {
        type: [Array, String],
        required: true,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      prepend: {
        type: String,
        default: '',
      },
      allowEdit: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        opened: false,
      };
    },
    computed: {
      text() {
        if (!this.value || this.value === '') return '';

        const item = this.items.find((a) => a.value === this.value || a === this.value);

        return item.text || item;
      },
    },
    methods: {
      selectionChanged(item) {
        // this.opened = !this.opened; now that the component is wrapped ina div this needs to be removed
        this.$emit('input', item.value || item);
        this.$emit('selectionChanged', item);
      },
    },
  };
</script>
