import groupDetailService from './groupDetailService';
import userDetailService from './userDetailService';
import giftCardService from './giftCardService';
import projectionService from './projectionService';
import ledgerService from './ledgerService';
import constactUsService from './contactUsService';
import ticketService from './ticketService';

export default {
  groupDetailService,
  userDetailService,
  giftCardService,
  projectionService,
  ledgerService,
  constactUsService,
  ticketService,
};
