import Vue from 'vue';

Vue.filter('toCurrency', (value, currency = 'USD', minimumFractionDigits = 0) => {
  let val = value;
  if (typeof value === 'string') {
    val = parseFloat(value);
  }
  if (typeof val !== 'number') {
    return value;
  }
  if (val === 0) {
    return '---';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits,
  });
  return formatter.format(val);
});
