<template>
  <div class="fullRow">
    <input
      type="checkbox"
      id="switch"
      @click="handleCheck"
      :checked="this.content"
    />
    <label for="switch">Toggle</label>
    <span>Enable 2 factor authentication</span>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/_variables.scss';

  span {
    display: inline-block;
    color: $grey;
    float: left;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
  }

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 80px;
    height: 40px;
    background: $greyblue;
    display: block;
    border-radius: 100px;
    position: relative;
    float: left;
    margin-right: 10px;
  }

  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: $petrol;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 45px;
  }
</style>

<script>
  export default {
    name: 'ToggleSwitch',
    props: {
      value: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    watch: {
      value(newValue) {
        this.content = newValue;
      },
    },
    data() {
      return {
        content: this.value,
      };
    },
    methods: {
      handleCheck() {
        this.content = !this.content;
        this.$emit('input', this.content);
      },
    },
  };
</script>
