<template>
  <component :is="layout">
    <router-view />
    <v-snackbar
      v-model="showSnackbar"
      :color="color"
    >
      <div style="font-size: 1.5rem">
        {{ snackMessage }}
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </component>
</template>

<style type="scss">
</style>

<script>
  import eventBus from '../@libs/eventBus';
  import config from '../../config';
  import './Offline';

  export default {
    name: 'App',
    data() {
      return {
        isOffline: config.isOffline,
        showSnackbar: false,
        snackMessage: null,
        color: 'red',
      };
    },
    computed: {
      layout() {
        if (this.isOffline) {
          return 'offline';
        }
        return this.$route.meta.layout || 'default-layout';
      },
    },
    methods: {
      showHttpException(message) {
        const { error: { msg } } = message;
        this.snackMessage = msg;
        this.color = 'red';
        this.showSnackbar = true;
      },
      showAppNotification(message) {
        this.snackMessage = message;
        this.color = 'green';
        this.showSnackbar = true;
      },
      showAppException(message) {
        this.snackMessage = message;
        this.color = 'red';
        this.showSnackbar = true;
      },
    },
    created() {
      eventBus.on({
        name: eventBus.eventTypes.http.authException,
        callback: (payload) => this.showHttpException(payload),
      });

      eventBus.on({
        name: eventBus.eventTypes.http.exception,
        callback: (payload) => this.showHttpException(payload),
      });

      eventBus.on({
        name: eventBus.eventTypes.app.error,
        callback: (payload) => this.showAppException(payload),
      });

      eventBus.on({
        name: eventBus.eventTypes.app.notification,
        callback: (payload) => this.showAppNotification(payload),
      });
    },
  };
</script>
