function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export default {
  namespaced: true,
  state: () => ({
    loggedIn: false,
    authToken: undefined,
    refreshToken: null,
    jwt: {
      permissions: [],
    },
    userDetail: {
      savedWallets: [],
      phoneVerified: false,
      emailVerified: false,
    },
    hasPendingCards: false,
  }),
  mutations: {
    updateHasPendingCards(state, value) {
      state.hasPendingCards = value;
    },
    updateUser(state, { userDetail }) {
      state.userDetail = { ...userDetail };
    },
    login(state, { loginResult }) {
      const { authToken, refreshToken, ...rest } = loginResult;

      state.loggedIn = true;
      state.authToken = authToken;
      state.refreshToken = refreshToken;
      state.jwt = parseJwt(authToken);
      state.userDetail = rest;
    },
    logout(state) {
      localStorage.clear();
      state.loggedIn = false;
      state.authToken = null;
      state.refreshToken = null;
      state.userDetail = {
        savedWallets: [],
        phoneVerified: false,
        emailVerified: false,
      };
    },
  },
  getters: {
    permissions: (state) => {
      if (!state.authToken) return [];

      const { permissions } = state.jwt;

      return Array.isArray(permissions) ? permissions : [permissions];
    },
  },
};
