const DEFAULT_MASK = '####-####';
module.exports = [
  // { code: 'AF', value: '+93', text: 'Afghanistan +93', mask: '###-#########' },
  { code: 'AL', value: '+355', text: 'Albania +355', mask: null },
  { code: 'DZ', value: '+213', text: 'Algeria +213', mask: null },
  { code: 'AD', value: '+376', text: 'Andorra +376', mask: null },
  { code: 'AO', value: '+244', text: 'Angola +244', mask: null },
  { code: 'AI', value: '+1', text: 'Anguilla +1', mask: null },
  { code: 'AG', value: '+1', text: 'Antigua and Barbuda +1', mask: null },
  { code: 'AR', value: '+54', text: 'Argentina +54', mask: null },
  { code: 'AM', value: '+374', text: 'Armenia +374', mask: null },
  { code: 'AW', value: '+297', text: 'Aruba +297', mask: null },
  { code: 'AU', value: '+61', text: 'Australia +61', mask: null },
  { code: 'AT', value: '+43', text: 'Austria +43', mask: null },
  { code: 'AZ', value: '+994', text: 'Azerbaijan +994', mask: null },
  { code: 'BS', value: '+1', text: 'Bahamas +1', mask: null },
  { code: 'BH', value: '+973', text: 'Bahrain +973', mask: null },
  { code: 'BD', value: '+880', text: 'Bangladesh +880', mask: null },
  { code: 'BB', value: '+1', text: 'Barbados +1', mask: null },
  // { code: 'BY', value: '+375', text: 'Belarus +375', mask: null },
  { code: 'BE', value: '+32', text: 'Belgium +32', mask: null },
  { code: 'BZ', value: '+501', text: 'Belize +501', mask: null },
  { code: 'BJ', value: '+229', text: 'Benin +229', mask: null },
  { code: 'BM', value: '+1', text: 'Bermuda +1', mask: null },
  { code: 'BT', value: '+975', text: 'Bhutan +975', mask: null },
  { code: 'BO', value: '+591', text: 'Bolivia +591', mask: null },
  { code: 'BA', value: '+387', text: 'Bosnia and Herzegovina +387', mask: null },
  { code: 'BW', value: '+267', text: 'Botswana +267', mask: null },
  { code: 'BR', value: '+55', text: 'Brazil +55', mask: null },
  { code: 'VG', value: '+1', text: 'British Virgin Islands +1', mask: null },
  { code: 'BN', value: '+673', text: 'Brunei +673', mask: null },
  { code: 'BG', value: '+359', text: 'Bulgaria +359', mask: null },
  { code: 'BF', value: '+226', text: 'Burkina Faso +226', mask: null },
  // { code: 'BI', value: '+257', text: 'Burundi +257', mask: null },
  { code: 'KH', value: '+855', text: 'Cambodia +855', mask: null },
  { code: 'CM', value: '+237', text: 'Cameroon +237', mask: null },
  { code: 'CA', value: '+1', text: 'Canada +1', mask: null },
  { code: 'CV', value: '+238', text: 'Cape Verde +238', mask: null },
  { code: 'KY', value: '+1', text: 'Cayman Islands +1', mask: null },
  // { code: 'CF', value: '+236', text: 'Central African Republic +236', mask: null },
  { code: 'TD', value: '+235', text: 'Chad +235', mask: null },
  { code: 'CL', value: '+56', text: 'Chile +56', mask: null },
  { code: 'CN', value: '+86', text: 'China +86', mask: null },
  // { code: 'CO', value: '+57', text: 'Colombia +57', mask: null },
  { code: 'KM', value: '+269', text: 'Comoros +269', mask: null },
  // { code: 'CD', value: '+243', text: 'Congo (DRC) +243', mask: null },
  // { code: 'CG', value: '+242', text: 'Congo (Republic) +242', mask: null },
  { code: 'CR', value: '+506', text: 'Costa Rica +506', mask: null },
  { code: 'CI', value: '+225', text: "Côte d'Ivoire +225", mask: null },
  { code: 'HR', value: '+385', text: 'Croatia +385', mask: null },
  // { code: 'CU', value: '+53', text: 'Cuba +53', mask: null },
  { code: 'CW', value: '+599', text: 'Curaçao +599', mask: null },
  { code: 'CY', value: '+357', text: 'Cyprus +357', mask: null },
  { code: 'CZ', value: '+420', text: 'Czech Republic +420', mask: null },
  { code: 'DK', value: '+45', text: 'Denmark +45', mask: null },
  { code: 'DJ', value: '+253', text: 'Djibouti +253', mask: null },
  { code: 'DM', value: '+1', text: 'Dominica +1', mask: null },
  { code: 'DO', value: '+1', text: 'Dominican Republic +1', mask: null },
  { code: 'EC', value: '+593', text: 'Ecuador +593', mask: null },
  { code: 'EG', value: '+20', text: 'Egypt +20', mask: null },
  { code: 'SV', value: '+503', text: 'El Salvador +503', mask: null },
  { code: 'GQ', value: '+240', text: 'Equatorial Guinea +240', mask: null },
  { code: 'ER', value: '+291', text: 'Eritrea +291', mask: null },
  { code: 'EE', value: '+372', text: 'Estonia +372', mask: null },
  { code: 'ET', value: '+251', text: 'Ethiopia +251', mask: null },
  { code: 'FO', value: '+298', text: 'Faroe Islands +298', mask: null },
  { code: 'FJ', value: '+679', text: 'Fiji +679', mask: null },
  { code: 'FI', value: '+358', text: 'Finland +358', mask: null },
  { code: 'FR', value: '+33', text: 'France +33', mask: null },
  { code: 'GF', value: '+594', text: 'French Guiana +594', mask: null },
  { code: 'PF', value: '+689', text: 'French Polynesia +689', mask: null },
  { code: 'GA', value: '+241', text: 'Gabon +241', mask: null },
  { code: 'GM', value: '+220', text: 'Gambia +220', mask: null },
  { code: 'GE', value: '+995', text: 'Georgia +995', mask: null },
  { code: 'DE', value: '+49', text: 'Germany +49', mask: null },
  { code: 'GH', value: '+233', text: 'Ghana +233', mask: null },
  { code: 'GI', value: '+350', text: 'Gibraltar +350', mask: null },
  { code: 'GR', value: '+30', text: 'Greece +30', mask: null },
  { code: 'GL', value: '+299', text: 'Greenland +299', mask: null },
  { code: 'GD', value: '+1', text: 'Grenada +1', mask: null },
  { code: 'GP', value: '+590', text: 'Guadeloupe +590', mask: null },
  { code: 'GU', value: '+1', text: 'Guam +1', mask: null },
  { code: 'GT', value: '+502', text: 'Guatemala +502', mask: null },
  { code: 'GG', value: '+44', text: 'Guernsey +44', mask: null },
  { code: 'GN', value: '+224', text: 'Guinea +224', mask: null },
  { code: 'GW', value: '+245', text: 'Guinea-Bissau +245', mask: null },
  { code: 'GY', value: '+592', text: 'Guyana +592', mask: null },
  // { code: 'HT', value: '+509', text: 'Haiti +509', mask: null },
  { code: 'HN', value: '+504', text: 'Honduras +504', mask: null },
  { code: 'HK', value: '+852', text: 'Hong Kong +852', mask: null },
  { code: 'HU', value: '+36', text: 'Hungary +36', mask: null },
  { code: 'IS', value: '+354', text: 'Iceland +354', mask: null },
  { code: 'IN', value: '+91', text: 'India +91', mask: null },
  { code: 'ID', value: '+62', text: 'Indonesia +62', mask: null },
  // { code: 'IR', value: '+98', text: 'Iran +98', mask: null },
  // { code: 'IQ', value: '+964', text: 'Iraq +964', mask: null },
  { code: 'IE', value: '+353', text: 'Ireland +353', mask: null },
  { code: 'IM', value: '+44', text: 'Isle of Man +44', mask: null },
  { code: 'IL', value: '+972', text: 'Israel +972', mask: null },
  { code: 'IT', value: '+39', text: 'Italy +39', mask: null },
  { code: 'JM', value: '+1', text: 'Jamaica +1', mask: null },
  { code: 'JP', value: '+81', text: 'Japan +81', mask: null },
  { code: 'JE', value: '+44', text: 'Jersey +44', mask: null },
  { code: 'JO', value: '+962', text: 'Jordan +962', mask: null },
  { code: 'KZ', value: '+7', text: 'Kazakhstan +7', mask: null },
  { code: 'KE', value: '+254', text: 'Kenya +254', mask: null },
  { code: 'KI', value: '+686', text: 'Kiribati +686', mask: null },
  { code: 'KW', value: '+965', text: 'Kuwait +965', mask: null },
  { code: 'KG', value: '+996', text: 'Kyrgyzstan +996', mask: null },
  { code: 'LA', value: '+856', text: 'Laos +856', mask: null },
  { code: 'LV', value: '+371', text: 'Latvia +371', mask: null },
  // { code: 'LB', value: '+961', text: 'Lebanon +961', mask: null },
  { code: 'LS', value: '+266', text: 'Lesotho +266', mask: null },
  { code: 'LR', value: '+231', text: 'Liberia +231', mask: null },
  // { code: 'LY', value: '+218', text: 'Libya +218', mask: null },
  { code: 'LI', value: '+423', text: 'Liechtenstein +423', mask: null },
  { code: 'LT', value: '+370', text: 'Lithuania +370', mask: null },
  { code: 'LU', value: '+352', text: 'Luxembourg +352', mask: null },
  { code: 'MO', value: '+853', text: 'Macau +853', mask: null },
  { code: 'MK', value: '+389', text: 'Macedonia +389', mask: null },
  { code: 'MG', value: '+261', text: 'Madagascar +261', mask: null },
  { code: 'MW', value: '+265', text: 'Malawi +265', mask: null },
  { code: 'MY', value: '+60', text: 'Malaysia +60', mask: null },
  { code: 'MV', value: '+960', text: 'Maldives +960', mask: null },
  { code: 'ML', value: '+223', text: 'Mali +223', mask: null },
  { code: 'MT', value: '+356', text: 'Malta +356', mask: null },
  { code: 'MH', value: '+692', text: 'Marshall Islands +692', mask: null },
  { code: 'MQ', value: '+596', text: 'Martinique +596', mask: null },
  { code: 'MR', value: '+222', text: 'Mauritania +222', mask: null },
  { code: 'MU', value: '+230', text: 'Mauritius +230', mask: null },
  { code: 'YT', value: '+262', text: 'Mayotte +262', mask: null },
  { code: 'MX', value: '+52', text: 'Mexico +52', mask: null },
  { code: 'FM', value: '+691', text: 'Micronesia +691', mask: null },
  { code: 'MD', value: '+373', text: 'Moldova +373', mask: null },
  { code: 'MC', value: '+377', text: 'Monaco +377', mask: null },
  { code: 'MN', value: '+976', text: 'Mongolia +976', mask: null },
  { code: 'ME', value: '+382', text: 'Montenegro +382', mask: null },
  { code: 'MS', value: '+1', text: 'Montserrat +1', mask: null },
  { code: 'MA', value: '+212', text: 'Morocco +212', mask: null },
  { code: 'MZ', value: '+258', text: 'Mozambique +258', mask: null },
  // { code: 'MM', value: '+95', text: 'Myanmar +95', mask: null },
  { code: 'NA', value: '+264', text: 'Namibia +264', mask: null },
  { code: 'NR', value: '+674', text: 'Nauru +674', mask: null },
  { code: 'NP', value: '+977', text: 'Nepal +977', mask: null },
  { code: 'NL', value: '+31', text: 'Netherlands +31', mask: null },
  { code: 'NC', value: '+687', text: 'New Caledonia +687', mask: null },
  { code: 'NZ', value: '+64', text: 'New Zealand +64', mask: null },
  // { code: 'NI', value: '+505', text: 'Nicaragua +505', mask: null },
  { code: 'NE', value: '+227', text: 'Niger +227', mask: null },
  { code: 'NG', value: '+234', text: 'Nigeria +234', mask: null },
  { code: 'NU', value: '+683', text: 'Niue +683', mask: null },
  { code: 'NF', value: '+672', text: 'Norfolk Island +672', mask: null },
  // { code: 'KP', value: '+850', text: 'North Korea +850', mask: null },
  { code: 'MP', value: '+1', text: 'Northern Mariana Islands +1', mask: null },
  { code: 'NO', value: '+47', text: 'Norway +47', mask: null },
  { code: 'OM', value: '+968', text: 'Oman +968', mask: null },
  // { code: 'PK', value: '+92', text: 'Pakistan +92', mask: null },
  { code: 'PW', value: '+680', text: 'Palau +680', mask: null },
  { code: 'PA', value: '+507', text: 'Panama +507', mask: null },
  { code: 'PG', value: '+675', text: 'Papua New Guinea +675', mask: null },
  { code: 'PY', value: '+595', text: 'Paraguay +595', mask: null },
  { code: 'PE', value: '+51', text: 'Peru +51', mask: null },
  { code: 'PH', value: '+63', text: 'Philippines +63', mask: null },
  { code: 'PL', value: '+48', text: 'Poland +48', mask: null },
  { code: 'PT', value: '+351', text: 'Portugal +351', mask: null },
  { code: 'PR', value: '+1', text: 'Puerto Rico +1', mask: null },
  { code: 'QA', value: '+974', text: 'Qatar +974', mask: null },
  { code: 'RE', value: '+262', text: 'Réunion +262', mask: null },
  { code: 'RO', value: '+40', text: 'Romania +40', mask: null },
  // { code: 'RU', value: '+7', text: 'Russia +7', mask: null },
  { code: 'RW', value: '+250', text: 'Rwanda +250', mask: null },
  { code: 'BL', value: '+590', text: 'Saint Barthélemy +590', mask: null },
  { code: 'SH', value: '+290', text: 'Saint Helena +290', mask: null },
  { code: 'KN', value: '+1', text: 'Saint Kitts and Nevis +1', mask: null },
  { code: 'LC', value: '+1', text: 'Saint Lucia +1', mask: null },
  { code: 'MF', value: '+590', text: 'Saint Martin +590', mask: null },
  { code: 'PM', value: '+508', text: 'Saint Pierre and Miquelon +508', mask: null },
  { code: 'VC', value: '+1', text: 'Saint Vincent and the Grenadines +1', mask: null },
  { code: 'WS', value: '+685', text: 'Samoa +685', mask: null },
  { code: 'SM', value: '+378', text: 'San Marino +378', mask: null },
  { code: 'ST', value: '+239', text: 'São Tomé and Príncipe +239', mask: null },
  { code: 'SA', value: '+966', text: 'Saudi Arabia +966', mask: null },
  { code: 'SN', value: '+221', text: 'Senegal +221', mask: null },
  { code: 'RS', value: '+381', text: 'Serbia +381', mask: null },
  { code: 'SC', value: '+248', text: 'Seychelles +248', mask: null },
  { code: 'SL', value: '+232', text: 'Sierra Leone +232', mask: null },
  { code: 'SG', value: '+65', text: 'Singapore +65', mask: null },
  { code: 'SX', value: '+1', text: 'Sint Maarten +1', mask: null },
  { code: 'SK', value: '+421', text: 'Slovakia +421', mask: null },
  { code: 'SI', value: '+386', text: 'Slovenia +386', mask: null },
  { code: 'SB', value: '+677', text: 'Solomon Islands +677', mask: null },
  // { code: 'SO', value: '+252', text: 'Somalia +252', mask: null },
  { code: 'ZA', value: '+27', text: 'South Africa +27', mask: null },
  { code: 'KR', value: '+82', text: 'South Korea +82', mask: null },
  // { code: 'SS', value: '+211', text: 'South Sudan +211', mask: null },
  { code: 'ES', value: '+34', text: 'Spain +34', mask: null },
  { code: 'LK', value: '+94', text: 'Sri Lanka +94', mask: null },
  { code: 'PS', value: '+970', text: 'State of Palestine +970', mask: null },
  // { code: 'SD', value: '+249', text: 'Sudan +249', mask: null },
  { code: 'SR', value: '+597', text: 'Suriname +597', mask: null },
  { code: 'SJ', value: '+47', text: 'Svalbard and Jan Mayen +47', mask: null },
  { code: 'SZ', value: '+268', text: 'Swaziland +268', mask: null },
  { code: 'SE', value: '+46', text: 'Sweden +46', mask: null },
  { code: 'CH', value: '+41', text: 'Switzerland +41', mask: null },
  // { code: 'SY', value: '+963', text: 'Syria +963', mask: null },
  { code: 'TW', value: '+886', text: 'Taiwan +886', mask: null },
  { code: 'TJ', value: '+992', text: 'Tajikistan +992', mask: null },
  { code: 'TZ', value: '+255', text: 'Tanzania +255', mask: null },
  { code: 'TH', value: '+66', text: 'Thailand +66', mask: null },
  { code: 'TL', value: '+670', text: 'Timor-Leste +670', mask: null },
  { code: 'TG', value: '+228', text: 'Togo +228', mask: null },
  { code: 'TK', value: '+690', text: 'Tokelau +690', mask: null },
  { code: 'TO', value: '+676', text: 'Tonga +676', mask: null },
  { code: 'TT', value: '+1', text: 'Trinidad and Tobago +1', mask: null },
  { code: 'TN', value: '+216', text: 'Tunisia +216', mask: null },
  { code: 'TR', value: '+90', text: 'Turkey +90', mask: null },
  { code: 'TM', value: '+993', text: 'Turkmenistan +993', mask: null },
  { code: 'TC', value: '+1', text: 'Turks and Caicos Islands +1', mask: null },
  { code: 'TV', value: '+688', text: 'Tuvalu +688', mask: null },
  { code: 'UG', value: '+256', text: 'Uganda +256', mask: null },
  // { code: 'UA', value: '+380', text: 'Ukraine +380', mask: null },
  { code: 'AE', value: '+971', text: 'United Arab Emirates +971', mask: null },
  { code: 'GB', value: '+44', text: 'United Kingdom +44', mask: null },
  { code: 'US', value: '+1', text: 'United States +1', mask: '###-###-####' },
  { code: 'UY', value: '+598', text: 'Uruguay +598', mask: null },
  { code: 'UZ', value: '+998', text: 'Uzbekistan +998', mask: null },
  { code: 'VU', value: '+678', text: 'Vanuatu +678', mask: null },
  { code: 'VA', value: '+39', text: 'Vatican City +39', mask: null },
  // { code: 'VE', value: '+58', text: 'Venezuela +58', mask: null },
  { code: 'VN', value: '+84', text: 'Vietnam +84', mask: null },
  { code: 'VI', value: '+1', text: 'U.S. Virgin Islands +1', mask: null },
  { code: 'WF', value: '+681', text: 'Wallis and Futuna +681', mask: null },
  { code: 'EH', value: '+212', text: 'Western Sahara +212', mask: null },
  // { code: 'YE', value: '+967', text: 'Yemen +967', mask: null },
  { code: 'ZM', value: '+260', text: 'Zambia +260', mask: null },
  // { code: 'ZW', value: '+263', text: 'Zimbabwe +263', mask: null },
];
