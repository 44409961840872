<template>
  <div
    class="mainContainer step4Landing"
    :style="{
      background: isRejected ? 'rgba(235, 87, 87, 0.55)' : 'rgba(39, 174, 96, 0.55)',
    }"
  >
    <div class="innerContainer">
      <div v-if="isRejected">
        <h2 class="rejectedTitle">Rejected!</h2>
        <div class="fullRow">
          <i class="icon-error" />
        </div>
        <h3>Your application has been rejected!</h3>
        <h3>Please contact <span class="contactEmailText">help@bitjem.com</span>!</h3>
      </div>
      <div v-else>
        <h2>Success!</h2>
        <div class="fullRow">
          <i class="icon-check" />
        </div>
        <h3>Your application has been submitted and is being reviewed.</h3>
        <h3>We will email you once approved.</h3>
        <p>Please note the application process can take up to two days!</p>
      </div>
      <InputButton
        style="margin-top: 3rem"
        @click="$router.push({ path: '/dashboard' })"
        color="blueCTA"
        type="button"
        label="Go back to Dashboard"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../../../@assets/styles/variables.scss';

  .contactEmailText {
    color: $blue;
    text-decoration: underline;
  }

  .infoColumn {
    float: left;
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .infoRow {
    width: 100%;
    float: left;
    margin-bottom: 15px;
    label {
      font-size: 14px;
      color: $black;
      opacity: 0.6;
      font-weight: 400;
      float: left;
      width: 50%;
      padding-right: 15px;
      line-height: 16px;
    }
    span {
      font-size: 14px;
      color: $black;
      font-weight: 600;
      float: left;
      width: 50%;
    }
  }
  .imageSummary {
    float: left;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    margin-top: 40px;
    margin-bottom: 50px;
    .imageBox {
      background-image: url('../../../../../@assets/img/driversample.jpg');
      background-position: center center;
      background-size: cover;
      width: 145px;
      height: 101px;
      display: inline-block;
      border-radius: 10px;
      margin: 0 5px;
      span {
        font-size: 12px;
        color: $black;
        opacity: 0.6;
        font-weight: 600;
        margin-top: 110px;
        float: left;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  .step4 {
    height: 760px;
    margin-top: -380px;
  }

  .step4Landing {
    text-align: center;
    h2 {
      float: left;
      width: 100%;
      text-align: center;
    }

    .icon-error {
      margin: 5rem 0rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-error::before {
      z-index: 1;
      font-size: 6rem;
      position: absolute;
    }

    .icon-error:after {
      content: '';
      width: 100px;
      height: 100px;
      background: #e2ebff;
      border-radius: 100px;
      opacity: 0.9;
      border: 1px solid $lightblue;
    }

    .icon-check {
      display: inline-block;
      position: relative;
      margin-top: 50px;
    }

    .icon-check:before {
      z-index: 1;
      position: absolute;
      top: 35px;
      left: 32px;
      font-size: 30px;
    }

    .icon-check:after {
      content: '';
      width: 100px;
      height: 100px;
      background: #e2ebff;
      position: relative;
      display: inline-block;
      border-radius: 100px;
      margin-bottom: 30px;
      opacity: 0.9;
      border: 1px solid $lightblue;
    }
    h3 {
      font-size: 27px;
      color: $black;
      margin: 0;
      text-align: center;
      width: 100%;
      float: left;
    }
    p {
      color: $grey;
      float: left;
      width: 100%;
      text-align: center;
    }
    a {
      display: inline-block;
      margin-top: 40px;
    }
  }
</style>

<script>
  import InputButton from '../../../../@components/InputButton.vue';

  export default {
    name: 'Step4',
    components: { InputButton },
    computed: {
      isRejected() {
        return this.$store.state.userDetails?.userDetail?.kyc?.suspended;
      },
    },
  };
</script>
