<template>
  <div class="outerContainer" v-if="isLoggedIn && shouldLoad">
    <div class="topPromo">
      <!-- STARTED -->
      <div v-if="getKycState === 'NOT_STARTED'">
        <span>We need to verify your identity. It will take only 5 minutes.</span>
        <router-link to="/giftCard/pii">Let's Start<i class="icon-chevron" /></router-link>
      </div>

      <div v-if="getKycState === 'STARTED'">
        <span>Almost there, let's finish verifying your identity</span>
        <router-link to="/giftCard/pii">Continue<i class="icon-chevron" /></router-link>
      </div>

      <!-- SUSPENDED -->
      <div v-if="getKycState === 'SUSPENDED'">
        <span>
          Your submission has been rejected. Please contact
          <span class="contactEmail"> help@bitjem.com </span>
        </span>
      </div>

      <!-- PENDING -->
      <div v-if="getKycState === 'PENDING'">
        <span>
          We are reviewing your submission. You will receive an email once you are approved!
        </span>
      </div>

      <!-- RESUBMIT -->
      <div v-if="getKycState === 'RESUBMIT'">
        <span>
          Please upload your identity documentation again
          <router-link class="hereLink" to="/giftCard/pii">here</router-link>.
        </span>
        <span style="margin-left: 5px">Make sure the images are clear. </span>
      </div>

      <div v-if="getKycState === 'COMPLETED'">
        <span> Your identity has been verified. </span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';
  .outerContainer {
    margin-bottom: 8rem;
  }

  .contactEmail {
    color: $petrol !important;
    float: none !important;
    text-decoration: underline;
  }

  .hereLink {
    float: none !important;
    color: $petrol !important;
    background: transparent !important;
    border: none !important;
    padding: 0rem !important;
    transition: $small;
    font-size: 25px !important;
    i {
      font-size: 14px !important;
      margin-left: 0px !important;
      transform: none;
      display: inline-block;
      position: relative;
    }
    i:before {
      transform: rotate(90deg);
    }
    &:hover {
      color: darken($petrol, 12%) !important;
    }
  }

  .topPromo {
    width: 100%;
    background: $pink;
    color: $blue;
    padding: 15px 40px;
    font-size: 25px;
    font-weight: 700;
    position: fixed;
    top: 100px;
    left: 0;
    z-index: 2;
    span {
      margin-top: 6px;
      float: left;
    }
    a {
      font-size: 14px;
      color: $blue;
      background: $white;
      border: 3px solid $blue;
      border-radius: 5px;
      padding: 15px;
      line-height: 5px;
      transition: $small;
      float: right;
      i {
        font-size: 9px;
        margin-left: 70px;
        transform: rotate(-90deg);
        display: inline-block;
        position: relative;
        top: -1px;
      }
      i:before {
        transform: rotate(90deg);
      }
    }
    a:hover {
      background: $petrol;
      color: $white;
      border: 3px solid $petrol;
    }
  }

  @media screen and (max-width: 640px) {
    .hereLink {
      font-size: 2.3rem !important;
    }

    .topPromo {
      z-index: 5;
      font-size: 2.3rem;
      padding: 1.3rem 3.8rem;
      a {
        padding: 11px;
      }
      span {
        margin: 6px 0;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .topPromo {
      top: 80px;
    }
  }
</style>

<script>
  import giftCardService from '../../../@libs/services/giftCardService';
  import userDetailService from '../../../@libs/services/userDetailService';

  const KYC_STATES = {
    NOT_STARTED: 'NOT_STARTED',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
    PENDING: 'PENDING',
    SUSPENDED: 'SUSPENDED',
    RESUBMIT: 'RESUBMIT',
  };

  export default {
    name: 'KYCBanner',
    data() {
      return {
        intervalID: null,
      };
    },
    computed: {
      userId() {
        return this.userDetail.id;
      },
      userDetail() {
        return this.$store.state.userDetails.userDetail || {};
      },
      isLevel2() {
        return this.userDetail.isLevel2 || false;
      },
      kyc() {
        return this.$store.state.userDetails?.userDetail?.kyc || {};
      },
      shouldLoad() {
        return (
          this.$store.state.userDetails.hasPendingCards &&
          !this.$store.state.userDetails.userDetail.isLevel2
        );
      },
      isLoggedIn() {
        return (
          this.$store.state.userDetails.loggedIn &&
          this.$store.getters['userDetails/permissions'].some((a) => a === 'RedeemGiftCard')
        );
      },
      getKycState() {
        const { kyc } = this;

        // SUSPENDED (FAILED) STATE
        if (kyc.suspended) {
          return KYC_STATES.SUSPENDED;
        }

        // COMPLETED STATE
        if (this.isLevel2) {
          return KYC_STATES.COMPLETED;
        }

        // RESUBMIT STATE
        if (kyc.documentsResubmit) {
          return KYC_STATES.RESUBMIT;
        }

        // PENDING STATE
        if (!this.isLevel2 && kyc.documentsUploaded) {
          return KYC_STATES.PENDING;
        }

        if (kyc.isLevel1 || kyc.isInternational) {
          return KYC_STATES.STARTED;
        }

        return KYC_STATES.NOT_STARTED;
      },
    },
    watch: {
      async isLoggedIn(newValue) {
        if (newValue) await this.loadData();
      },
    },
    methods: {
      async loadData() {
        const refreshToken = localStorage.getItem('refreshToken');

        if (this.isLevel2) clearInterval(this.intervalID);

        if (!this.isLoggedIn || this.isLevel2) return;

        await giftCardService.listAllUsersPendingCardsCards();

        // refresh user detail
        if (!refreshToken) return;
        const userDetail = await userDetailService.loginWithRefreshToken(refreshToken);
        this.$store.commit('userDetails/updateUser', { userDetail });
      },
    },
    mounted() {
      this.intervalID = setInterval(this.loadData, 1000 * 60 * 2);
    },
    async beforeMount() {
      await this.loadData();
    },
    beforeDestroy() {
      if (this.intervalID) clearInterval(this.intervalID);
    },
  };
</script>
