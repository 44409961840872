<template>
  <div class="mainContainer terms">
    <div class="insideContainer">
      <div class="termsBox">
        <div>
          <p class="c19 c22"><span class="c3 c20"></span></p>
        </div>
        <p class="c21">
          <span class="c23">BitJem</span><a id="id.gjdgxs"></a
          ><span class="c3 c0">&nbsp;Privacy Policy</span>
        </p>
        <p class="c7">
          <span class="c2">Last modified: </span><span class="c8">August</span
          ><span class="c2">&nbsp;</span><span class="c8">31</span><span class="c3 c2">, 2023</span>
        </p>
        <a id="id.30j0zll"></a>
        <ol class="c4 lst-kix_list_4-0 start" start="1">
          <li class="c1 c6"><span class="c13 c9 c0">Introduction</span></li>
        </ol>
        <p class="c1">
          <span class="c8">BitJem</span><span class="c2">&nbsp;LLC and its affiliates (</span
          ><span class="c0">&quot;Company&quot;</span><span class="c2">&nbsp;or</span
          ><span class="c0">&nbsp;&quot;We&quot;</span
          ><span class="c3 c2"
            >) respect your privacy and are committed to protecting it through our compliance with
            this policy.</span
          >
        </p>
        <p class="c1">
          <span class="c2"
            >This policy describes the types of information we may collect from you or that you may
            provide when you visit the website www.bitjem.com (our &quot;</span
          ><span class="c0">Website</span
          ><span class="c3 c2"
            >&quot;) and our practices for collecting, using, maintaining, protecting, and
            disclosing that information.</span
          >
        </p>
        <p class="c1"><span class="c3 c2">This policy applies to information we collect:</span></p>
        <ul class="c4 lst-kix_list_1-0 start">
          <li class="c5 li-bullet-0"><span class="c3 c2">On this Website.</span></li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >In email, text, and other electronic messages between you and this Website.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >When you interact with our advertising and applications on third-party websites and
              services, if those applications or advertising include links to this policy.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >When you interact with third party marketplaces or applications that offer our
              products for sale, if those marketplaces or applications include links to this
              policy.</span
            >
          </li>
        </ul>
        <p class="c1"><span class="c3 c2">It does not apply to information collected by:</span></p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Us offline or through any other means, including on any other website operated by
              Company or any third party (including our affiliates and subsidiaries); or
            </span>
          </li>
          <li class="c5 li-bullet-0" id="h.gjdgxs">
            <span class="c3 c2"
              >Any third party (including our affiliates and subsidiaries), including through any
              application or content (including advertising) that may link to or be accessible from
              or through the Website.</span
            >
          </li>
        </ul>
        <p class="c1">
          <span class="c2"
            >Please read this policy carefully to understand our policies and practices regarding
            your information and how we will treat it. If you do not agree with our policies and
            practices, your choice is not to use our Website. By accessing or using this Website,
            you agree to this privacy policy. This policy may change from time to time (see </span
          ><span class="c2 c9">&lrm;XI.&nbsp;</span
          ><span class="c2 c9 c11"
            ><a class="c12" href="#id.lnxbz9">Changes to Our Privacy Policy</a></span
          ><span class="c3 c2"
            >). Your continued use of this Website after we make changes is deemed to be acceptance
            of those changes, so please check the policy periodically for updates.
          </span>
        </p>
        <a id="id.1fob9te"></a>
        <ol class="c4 lst-kix_list_4-0" start="2">
          <li class="c1 c6"><span class="c13 c9 c0">Children Under the Age of 18</span></li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >Our Website is not intended for children under 18 years of age. No one under age 18 may
            provide any &nbsp;information to or on the Website. We do not knowingly collect personal
            information from children under 18. If you are under 18, do not use or provide any
            information on this Website or through any of its features, register on the Website,
            make any purchases through the Website, use any of the interactive or public comment
            features of this Website, or provide any information about yourself to us, including
            your name, address, telephone number, email address, or any screen name or user name you
            may use. If we learn we have collected or received personal information from a child
            under 18 without verification of parental consent, we will delete that information. If
            you believe we might have any information from or about a child under 18, please contact
            us at info@bitjem.com.</span
          >
        </p>
        <a id="id.3znysh7"></a>
        <ol class="c4 lst-kix_list_4-0" start="3">
          <li class="c1 c6">
            <span class="c13 c9 c0">Information We Collect About You and How We Collect It</span>
          </li>
        </ol>
        <p class="c1">
          <span class="c2 c13 c9"
            >&ldquo;Personal Information&rdquo; means information about an identifiable individual.
            It includes information that you have provided to us or was collected by us from other
            sources. It may include details such as your name and address, age and gender, personal
            financial records, identification numbers including your social security number and
            personal references, to the extent permitted by local laws.</span
          >
        </p>
        <p class="c1">
          <span class="c2"
            >We collect several types of information from and about users of our Website, including
            information:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >By which you may be personally identified, such as name, postal address, email
              address, telephone number, social security number, or website username;</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >That is about you but individually does not identify you, such as information that
              are collected as you navigate within the Website and use certain of its features, that
              could be contents of your public comments, postings, and any other information that,
              directly or indirectly, can identify you as through an identification number, location
              data, an online identifier or to one or more factors specific to your physical,
              physiological, genetic, mental, economic, cultural or social identity; and</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >About your internet connection, the equipment you use to access our Website, and
              usage details.</span
            >
          </li>
        </ul>
        <p class="c17">
          <span class="c3 c0"
            >Our third-party partners may collect similar or additional information from you through
            any application or content (including advertising) that may link to or be accessible
            from or through the Website. The types and use of such information are not governed by
            this Privacy Policy, and are instead governed by such third-party&rsquo;s Privacy
            Policy.</span
          >
        </p>
        <p class="c1"><span class="c3 c2">We collect this information:</span></p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2">Directly from you when you provide it to us.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Automatically as you navigate through the site. Information collected automatically
              may include usage details, IP addresses, and information collected through cookies,
              web beacons, and other tracking technologies.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2">From third parties, for example, our business partners.</span>
          </li>
        </ul>
        <a id="id.2et92p0"></a>
        <ol class="c4 lst-kix_list_4-1 start" start="1">
          <li class="c1 c15">
            <span class="c13 c9 c0">Information You Provide to Us &nbsp;</span>
          </li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >The information we collect on or through our Website may include:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Information that you provide by filling in forms on our Website. This includes
              information provided at the time of registering to use our Website, subscribing to our
              service, posting material, or requesting further services. We may also ask you for
              information &nbsp;when you report a problem with our Website.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Records and copies of your correspondence (including email addresses), if you contact
              us.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Your responses to surveys that we might ask you to complete for research
              purposes.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Details of transactions you carry out through our Website and of the fulfillment of
              your orders. You may be required to provide financial information before placing an
              order through our Website.</span
            >
          </li>
        </ul>
        <p class="c1">
          <span class="c2"
            >You also may provide information to be published or displayed (hereinafter,
            &quot;</span
          ><span class="c0">posted</span
          ><span class="c2"
            >&quot;) on public areas of the Website, or transmitted to other users of the Website or
            third parties (collectively, &quot;</span
          ><span class="c0">User Contributions</span
          ><span class="c3 c2"
            >&quot;). Your User Contributions are posted on and transmitted to others at your own
            risk. Although, please be aware that no security measures are perfect or impenetrable.
            Additionally, we cannot control the actions of other users of the Website with whom you
            may choose to share your User Contributions. Therefore, we cannot and do not guarantee
            that your User Contributions will not be viewed by unauthorized persons.</span
          >
        </p>
        <a id="id.tyjcwt"></a>
        <ol class="c4 lst-kix_list_4-1" start="2">
          <li class="c1 c15">
            <span class="c13 c9 c0"
              >Information We Collect Through Automatic Data Collection Technologies &nbsp;</span
            >
          </li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >As you navigate through and interact with our Website, we may use automatic data
            collection technologies to collect certain information about your equipment, browsing
            actions, and patterns, including:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >&nbsp;Details of your visits to our Website, including traffic data, location data,
              transactions, logs, and other communication data and the resources that you access and
              use on the Website.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Information about your computer and internet connection, including your IP address,
              operating system, and browser type.</span
            >
          </li>
        </ul>
        <p class="c1">
          <span class="c3 c2"
            >The information we collect automatically may include personal information, or we may
            maintain it or associate it with personal information we collect in other ways or
            receive from third parties. It helps us to improve our Website and to deliver a better
            and more personalized service, including by enabling us to:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2">Estimate our audience size and usage patterns.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Store information about your preferences, allowing us to customize our Website
              according to your individual interests.</span
            >
          </li>
          <li class="c5 li-bullet-0"><span class="c3 c2">Speed up your searches.</span></li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2">Recognize you when you return to our Website.</span>
          </li>
        </ul>
        <p class="c1">
          <span class="c3 c2"
            >The technologies we use for this automatic data collection may include:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c0">Web Beacons.</span
            ><span class="c2"
              >&nbsp;Pages of our Website and our emails may contain small electronic files known as
              web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that
              permit the Company, for example, to count users who have visited those pages or opened
              an email and for other related website statistics (for example, recording the
              popularity of certain website content and verifying system and server integrity).
            </span>
          </li>
        </ul>
        <ol class="c4 lst-kix_list_4-0" start="4">
          <li class="c1 c6">
            <span class="c13 c9 c0"
              >Third-Party Use of Cookies and Other Tracking Technologies</span
            >
          </li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >Some content or applications, including advertisements, on the Website are served by
            third-parties, including advertisers, ad networks and servers, content providers, and
            application providers. These third parties may use cookies alone or in conjunction with
            web beacons or other tracking technologies to collect information about you when you use
            our website. The information they collect may be associated with your personal
            information or they may collect information, including personal information, about your
            online activities over time and across different websites and other online services.
            They may use this information to provide you with interest-based (behavioral)
            advertising or other targeted content.
          </span>
        </p>
        <p class="c1">
          <span class="c2"
            >We do not control these third parties&#39; tracking technologies or how they may be
            used. If you have any questions about an advertisement or other targeted content, you
            should contact the responsible provider directly. For information about how you can opt
            out of receiving targeted advertising from many providers, see </span
          ><span class="c2 c9">&lrm;VII.&nbsp;</span
          ><span class="c2 c9 c11"
            ><a class="c12" href="#id.2s8eyo1"
              >Choices About How We Use and Disclose Your Information</a
            ></span
          ><span class="c3 c2">.</span>
        </p>
        <a id="id.1t3h5sf"></a>
        <ol class="c4 lst-kix_list_4-0" start="5">
          <li class="c1 c6"><span class="c13 c9 c0">How We Use Your Information</span></li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >We use information that we collect about you or that you provide to us, including any
            personal information:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2">To present our Website and its contents to you.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >To provide you with information, products, or services that you request from
              us.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2">To fulfill any other purpose for which you provide it.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >To provide you with notices about your account, including expiration and renewal
              notices.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >To carry out our obligations and enforce our rights arising from any contracts
              entered into between you and us, including for billing and collection.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >To notify you about changes to our Website or any products or services we offer or
              provide though it.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >To allow you to participate in interactive features on our Website.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >In any other way we may describe when you provide the information.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2">For any other purpose with your consent.</span>
          </li>
        </ul>
        <p class="c1">
          <span class="c2"
            >We may also use your information to contact you about our own and third-parties&#39;
            goods and services that may be of interest to you. If you do not want us to use your
            information in this way, please check the relevant box located on the form on which we
            collect your data (the order form/registration form)/adjust your user preferences in
            your account profile. For more information, see </span
          ><span class="c2 c9">&lrm;VII.&nbsp;</span
          ><span class="c2 c9 c11"
            ><a class="c12" href="#id.2s8eyo1"
              >Choices About How We Use and Disclose Your Information</a
            ></span
          ><span class="c3 c2">.</span>
        </p>
        <p class="c1">
          <span class="c3 c2"
            >We may use the information we have collected from you to enable us to display
            advertisements to our advertisers&#39; target audiences. Even though we do not disclose
            your personal information for these purposes without your consent, if you click on or
            otherwise interact with an advertisement, the advertiser may assume that you meet its
            target criteria.</span
          >
        </p>
        <a id="id.4d34og8"></a>
        <ol class="c4 lst-kix_list_4-0" start="6">
          <li class="c1 c6"><span class="c13 c9 c0">Disclosure of Your Information</span></li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >We may disclose aggregated information about our users, and information that does not
            identify any individual, without restriction.
          </span>
        </p>
        <p class="c1">
          <span class="c3 c2"
            >We may disclose personal information that we collect or you provide as described in
            this privacy policy:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2">To our subsidiaries and affiliates.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c2"
              >To contractors, service providers, and other third parties we use to support our
              business</span
            ><span class="c8">.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >To a buyer or other successor in the event of a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or all of Company&#39;s
              assets, whether as a going concern or as part of bankruptcy, liquidation, or similar
              proceeding, in which personal information held by Company about our Website users is
              among the assets transferred.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c2"
              >To third parties to market their products or services to you if you have not opted
              out of these disclosures. For more information, see </span
            ><span class="c2 c9">&lrm;VII.&nbsp;</span
            ><span class="c2 c9 c11"
              ><a class="c12" href="#id.2s8eyo1"
                >Choices About How We Use and Disclose Your Information</a
              ></span
            ><span class="c3 c2">.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2">To fulfill the purpose for which you provide it. </span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >For any other purpose disclosed by us when you provide the information.</span
            >
          </li>
          <li class="c5 li-bullet-0"><span class="c3 c2">With your consent.</span></li>
        </ul>
        <p class="c1"><span class="c3 c2">We may also disclose your personal information:</span></p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >To comply with any court order, law, or legal process, including to respond to any
              government or regulatory request.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c2"
              >To enforce or apply our terms of use and other agreements, including for billing and
              collection purposes. Our terms of use can b</span
            ><span class="c8">e found at </span
            ><span class="c8">https://bitjem.com/termsofservice.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >If we believe disclosure is necessary or appropriate to protect the rights, property,
              or safety of Company, our customers, or others. This includes exchanging information
              with other companies and organizations for the purposes of fraud protection and credit
              risk reduction.</span
            >
          </li>
        </ul>
        <a id="id.2s8eyo1"></a>
        <ol class="c4 lst-kix_list_4-0" start="7">
          <li class="c1 c6" id="h.30j0zll">
            <span class="c13 c9 c0">Choices About How We Use and Disclose Your Information</span>
          </li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >We strive to provide you with choices regarding the personal information you provide to
            us. We have created mechanisms to provide you with the following control over your
            information:
          </span>
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c0">Tracking Technologies and Advertising.</span
            ><span class="c2"
              >&nbsp;You can set your browser to refuse all or some browser cookies, or to alert you
              when cookies are being sent. If you disable or refuse cookies, please note that some
              parts of this site may then be inaccessible or not function properly.</span
            >
          </li>
        </ul>
        <p class="c1">
          <span class="c2"
            >We do not control third parties&#39; collection or use of your information to serve
            interest-based advertising. However these third parties may provide you with ways to
            choose not to have your information collected or used in this way. You can opt out of
            receiving targeted ads from members of the Network Advertising Initiative (&quot;</span
          ><span class="c0">NAI</span><span class="c3 c2">&quot;) on the NAI&#39;s website.</span>
        </p>
        <p class="c1">
          <span class="c2"
            >Residents of certain states, such as California, Nevada, Colorado, Connecticut,
            Virginia, and Utah may have additional personal information rights and choices. Please
            see </span
          ><span class="c2 c9">&lrm;IX.&nbsp;</span
          ><span class="c2 c9 c11"
            ><a class="c12" href="#id.3rdcrjn">Your State Privacy Rights</a></span
          ><span class="c3 c2">&nbsp;for more information.</span>
        </p>
        <a id="id.17dp8vu"></a>
        <ol class="c4 lst-kix_list_4-0" start="8">
          <li class="c1 c6">
            <span class="c13 c9 c0">Accessing and Correcting Your Information</span>
          </li>
        </ol>
        <p class="c1">
          <span class="c2"
            >You can review your personal information by logging into the Website and visiting your
            account profile page. You may also send us an email at </span
          ><span class="c8">info@bitjem.com</span
          ><span class="c3 c2"
            >&nbsp;to request access to, correct or delete any personal information that you have
            provided to us. We cannot delete your personal information except by also deleting your
            user account. We may not accommodate a request to change information if we believe the
            change would violate any law or legal requirement or cause the information to be
            incorrect.</span
          >
        </p>
        <p class="c1">
          <span class="c2"
            >Residents of certain states, such as California, Nevada, Colorado,
            Connecticut,Virginia, and Utah may have additional personal information rights and
            choices. Please see </span
          ><span class="c2 c9">&lrm;IX.&nbsp;</span
          ><span class="c2 c9 c11"
            ><a class="c12" href="#id.3rdcrjn">Your State Privacy Rights</a></span
          ><span class="c3 c2">&nbsp;for more information.</span>
        </p>
        <a id="id.3rdcrjn"></a>
        <ol class="c4 lst-kix_list_4-0" start="9">
          <li class="c1 c6" id="h.1fob9te">
            <span class="c13 c9 c0">Your State Privacy Rights</span>
          </li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >State consumer privacy laws may provide their residents with additional rights
            regarding our use of their personal information. To learn more about California
            residents&#39; privacy rights, visit https://oag.ca.gov/privacy/ccpa. California&#39;s
            &quot;Shine the Light&quot; law (Civil Code Section &sect; 1798.83) permits users of our
            Website that are California residents to request certain information regarding our
            disclosure of personal information to third parties for their direct marketing purposes.
            To make such a request, please send an email to info@bitjem.com.</span
          >
        </p>
        <p class="c1">
          <span class="c3 c2"
            >Colorado, Connecticut, Virginia, and Utah each provide their state residents with
            rights to:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2">Confirm whether we process their personal information.</span>
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2">Access and delete certain personal information.</span>
          </li>
          <li class="c5 li-bullet-0"><span class="c2 c3">Data portability.</span></li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Opt-out of personal data processing for targeted advertising and sales.</span
            >
          </li>
        </ul>
        <p class="c1">
          <span class="c3 c2"
            >Colorado, Connecticut, and Virginia also provide their state residents with rights
            to:</span
          >
        </p>
        <ul class="c4 lst-kix_list_1-0">
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Correct inaccuracies in their personal information, taking into account the
              information&#39;s nature processing purpose.</span
            >
          </li>
          <li class="c5 li-bullet-0">
            <span class="c3 c2"
              >Opt-out of profiling in furtherance of decisions that produce legal or similarly
              significant effects.
            </span>
          </li>
        </ul>
        <p class="c1">
          <span class="c3 c2"
            >To exercise any of these rights please contact us at info@bitjem.com. To appeal a
            decision regarding a consumer rights request an appeal by contacting us at
            info@bitjem.com.</span
          >
        </p>
        <p class="c1">
          <span class="c2"
            >Nevada provides its residents with a limited right to opt-out of certain personal
            information sales. Residents who wish to exercise this sale opt-out rights may submit a
            request to this designated address: </span
          ><span class="c8">info@bitjem.com</span
          ><span class="c3 c2"
            >. However, please know we do not currently sell data triggering that statute&#39;s
            opt-out requirements.</span
          >
        </p>
        <a id="id.26in1rg"></a>
        <ol class="c4 lst-kix_list_4-0" start="10">
          <li class="c1 c6"><span class="c13 c9 c0">Data Security</span></li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >We have implemented measures designed to secure your personal information from
            accidental loss and from unauthorized access, use, alteration, and disclosure. All
            information you provide to us is stored on our secure servers behind firewalls. Any
            payment transactions and other information you provide to us will be encrypted using SSL
            technology.
          </span>
        </p>
        <p class="c1">
          <span class="c3 c2"
            >The safety and security of your information also depends on you. Where we have given
            you (or where you have chosen) a password for access to certain parts of our Website,
            you are responsible for keeping this password confidential. We ask you not to share your
            password with anyone. We urge you to be careful about giving out information in public
            areas of the Website like message boards. The information you share in public areas may
            be viewed by any user of the Website.</span
          >
        </p>
        <p class="c1">
          <span class="c3 c2"
            >Unfortunately, the transmission of information via the internet is not completely
            secure. Although we do our best to protect your personal information, we cannot
            guarantee the security of your personal information transmitted to our Website. Any
            transmission of personal information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained on the Website.
          </span>
        </p>
        <a id="id.lnxbz9"></a>
        <ol class="c4 lst-kix_list_4-0" start="11">
          <li class="c1 c6" id="h.3znysh7">
            <span class="c13 c9 c0">Changes to Our Privacy Policy</span>
          </li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >It is our policy to post any changes we make to our privacy policy on this page. The
            date the privacy policy was last revised is identified at the top of the page. You are
            responsible for ensuring we have an up-to-date active and deliverable email address for
            you, and for periodically visiting our Website and this privacy policy to check for any
            changes.</span
          >
        </p>
        <a id="id.35nkun2"></a>
        <ol class="c4 lst-kix_list_4-0" start="12">
          <li class="c1 c6"><span class="c9 c0 c13">Contact Information</span></li>
        </ol>
        <p class="c1">
          <span class="c3 c2"
            >To ask questions or comment about this privacy policy and our privacy practices,
            contact us at:
          </span>
        </p>
        <p class="c1"><span class="c3 c2">info@bitjem.com </span></p>
        <p class="c1 c19"><span class="c3 c2"></span></p>
        <div>
          <p class="c14"><span class="c3 c20"></span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/_variables.scss';

  .terms {
    .termsBox {
      float: left;
      width: 100%;
      height: 70vh;
      overflow-y: scroll;
    }
    a.inactive {
      opacity: 0.3;
    }
    a.inactive:hover {
      background: transparent;
      cursor: not-allowed;
      opacity: 0.3;
      color: $blue !important;
    }
    h2 {
      text-align: center !important;
      float: left;
      width: 100%;
    }
    h3 {
      font-size: 20px;
      margin-top: 40px;
      margin-bottom: 0;
      color: $black;
      font-weight: 700;
      float: left;
      width: 100%;
      text-align: center;
    }
    ul {
      float: left;
      width: 100%;
      li {
        float: left;
        width: 100%;
        margin-bottom: 15px;
        position: relative;
        padding-left: 20px;
        font-size: 1.5rem;
        line-height: 20px;
      }
      li:after {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 10px;
        height: 10px;
        border: 2px solid $petrol;
        border-radius: 100px;
      }
    }
    p {
      float: left;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      strong {
        font-weight: 600;
      }
    }
    a:not(.blueCTA) {
      color: $petrol;
      transition: $small;
      position: relative;
    }
    a:not(.blueCTA):after {
      content: '';
      width: 0;
      height: 4px;
      background: $petrol;
      opacity: 0.35;
      position: absolute;
      left: 0;
      bottom: 0px;
      transition: $small;
    }
    a:not(.blueCTA):hover {
      color: $blue;
    }
    a:not(.blueCTA):hover:after {
      width: 100%;
      background: $blue;
    }
    .date {
      float: left;
      width: 100%;
      font-size: 14px;
      margin-top: 50px;
      margin-bottom: -20px;
      text-align: center;
      color: $lightblue;
      span {
        font-weight: 600;
      }
    }
  }
</style>

<script>
  export default {
    name: 'TermsOfService',
  };
</script>
