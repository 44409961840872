<template>
  <div class="mainContainer">
    <div class="innerContainer">
      <Banner
        @dismiss="showBanner = !showBanner"
        :show.sync="showBanner"
        :autohide="true"
        :label="bannerData.label"
        :message="bannerData.message"
        :color="bannerData.color"
      />
      <div class="headerTexts">
        <h2>E-mail Verification</h2>
        <h5>
          Please enter the code found in the email sent to
          <br />
          <span class="email-address-text">{{ emailAddress }}</span>
        </h5>
      </div>
      <div class="inputText">
        <InputText v-model.trim="emailCode" :label="`Enter the code`" placeholder="Email Code" />
      </div>
      <div class="inputButton">
        <InputButton
          :isLoading="isLoading"
          class="input-button"
          :disabled="disableVerifyEmailCodeButton"
          label="Confirm Email Address"
          color="primary"
          @click="verifyEmailCode"
          type="button"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';
  .headerTexts {
    text-align: center;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    h2 {
      font-size: 5rem;
      color: $petrol;
      text-shadow: 0.5px 0.5px black;
    }
    h5 {
      margin-top: -10px;
      font-size: 2.5rem;
      font-weight: 300;
      color: $black;
    }
    .email-address-text {
      font-weight: 700;
    }
  }
  .inputText {
    margin-top: 30px;
  }

  .inputButton {
    margin-top: 30px;
  }

  @media screen and (max-width: 500px) {
    .headerTexts {
      h2 {
        font-size: 3.4rem;
        text-shadow: 0.5px 0.5px black;
      }
      h5 {
        font-size: 2.1rem;
      }
    }
    .input-button {
      display: flex;
      justify-content: center;
      min-width: 20rem !important;
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 450px) {
    .headerTexts {
      h5 {
        font-size: 1.9rem;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .headerTexts {
      h2 {
        font-size: 2.6rem;
        text-shadow: 1.5px 0.5px black;
      }
      h5 {
        font-size: 1.6rem;
      }
    }
    .input-button {
      display: flex;
      justify-content: center;
      min-width: 20rem !important;
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 300px) {
    .headerTexts {
      h5 {
        font-size: 1.2rem;
      }
    }
  }
</style>

<script>
  import InputText from '../../@components/InputText.vue';
  import services from '../../../@libs/services';
  import InputButton from '../../@components/InputButton.vue';
  import Banner from '../../@components/Banner.vue';

  export default {
    name: 'VerifyEmailAddress',
    components: { InputText, InputButton, Banner },
    data() {
      return {
        isLoading: false,
        emailCode: '',
        showBanner: false,
        bannerData: {
          label: '',
          message: '',
          color: 'success',
        },
      };
    },
    computed: {
      disableVerifyEmailCodeButton() {
        return this.emailCode === '';
      },
      emailAddress() {
        return this.$store.state.userDetails?.userDetail?.emailAddress;
      },
    },
    methods: {
      async verifyEmailCode() {
        this.isLoading = true;
        try {
          await services.userDetailService.verifyEmailAddress(this.emailCode);
          this.bannerData.label = 'Verify Email Success';
          this.bannerData.message = 'Your email verified successfully!';
          this.bannerData.color = 'success';
        } catch (error) {
          this.bannerData.label = 'Verify Email Error';
          this.bannerData.message = error.toString();
          this.bannerData.color = 'error';
        } finally {
          this.showBanner = true;
          this.isLoading = false;
        }
      },
    },
  };
</script>
