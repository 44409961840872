<template>
  <div class="mainContainer accountDetails">
    <ClosingBanner />
    <div :class="show2FaDisableChallenge ? 'innerContainerBlur' : 'innerContainer'">
      <div class="fullRow">
        <h2>Account Details<span>Please enter details</span></h2>
      </div>
      <InputText v-model="userDetails.id" disabled label="Id" />
      <InputText v-model="userDetails.emailAddress" disabled label="Email Address" />
      <InputText v-model="passwordChangeDate" disabled label="Password Last Changed On" />
      <InputText
        v-model.trim="password"
        label="New Password"
        type="password"
        placeholder="New password"
      />
      <InputText
        v-model.trim="confirmPassword"
        label="Confirm Password"
        type="password"
        placeholder="Confirm password"
      />
      <InputText
        v-model.trim="currentPassword"
        label="Current Password"
        type="password"
        placeholder="Current password"
      />
      <InputText
        v-if="twoFaRequired"
        v-model.trim="twoFa"
        label="2FA"
        placeholder="2FA Required To Change Password"
      />
      <InputButton
        :isLoading="isLoading"
        type="button"
        :disabled="changePasswordDisabled"
        label="Change Password"
        color="blueCTA"
        @click="changeUsersPassword"
      />

      <!--Country Code dropdown-->
      <div class="fullRow updatePhone">
        <InputText :disabled="true" v-model="phoneNumber" label="Phone Number" />
        <div>
          <InputDropDown_V2
            v-model.trim="country"
            label="Country"
            placeholder="Country"
            :items="phoneCodeList"
            @selectionChanged="updatePhoneMask"
          />
          <InputText
            class="phoneInput"
            v-model="newPhoneNumber"
            label="New Phone Number"
            :placeholder="phoneMask || 'New Phone Number'"
            :mask="phoneMask"
            :type="phoneMask ? 'text' : 'number'"
          />
        </div>
        <InputButton
          :isLoading="isLoading"
          type="button"
          :disabled="changePhoneNumberDisabled"
          label="Update Phone number"
          color="blueCTA"
          @click="updatePhoneNumber"
        />
      </div>

      <!--New 2fa toggle-->
      <div class="fullRow faSwitch">
        <ToggleSwitch v-model="requires2Fa" />
      </div>

      <div class="overlay" v-if="showBarcodeChallenge" />
      <div class="modalWrapper" v-if="showBarcodeChallenge">
        <ExitIconButton @click="cancel2FaDialogs" />
        <div class="modalTitle">
          <div class="big">Enable 2FA</div>
          <div class="small">Enable 2FA for maximum security. You can also do this later.</div>
        </div>
        <!--2fa QR and master code-->
        <vue-qr :text="barcode" />
        <div class="masterCode">
          <span>Master Code:</span> <br />
          {{ masterCode }}
        </div>
        <InputText
          v-model.trim="pin"
          label="6 Digit Code"
          type="number"
          placeholder="Enter 6 digit code"
        />
        <InputButton
          :isLoading="isLoading"
          type="button"
          label="Verify Token"
          color="primary"
          @click="send2FaEnableChallenge"
        />
        <InputButton type="button" label="Cancel" color="secondary" @click="cancel2FaDialogs" />
      </div>

      <div class="fullRow updatePhone" v-if="!userDetails.phoneVerified">
        <InputText v-model.trim="smsCode" label="SMS Code" placeholder="SMS Code" />
        <InputButton
          :isLoading="isLoading"
          class="smsCodeButton"
          :disabled="verifySmsCodeDisabled"
          label="Verify SMS Code"
          color="primary"
          type="button"
          @click="verifySmsCode"
        />
      </div>
    </div>

    <!-- Disable2FA Dialog Modal -->
    <div class="disable2fa-container">
      <dialog class="dialog" :open="show2FaDisableChallenge">
        <div class="card">
          <div class="cardTitle">
            <h6>Disable 2FA</h6>
          </div>
          <div class="cardText">
            <InputText
              placeholder="6 Digit Code"
              v-model.trim="pin"
              label="6 Digit Code"
              id="pin"
            />
          </div>
        </div>
        <div class="classAction">
          <br />
          <InputButton
            :isLoading="isLoading"
            label="Verify Token"
            color="primary"
            @click="send2FaDisableChallenge"
            type="button"
            :disabled="disabledVerifyTokenButton"
          />
          <InputButton label="Cancel" color="secondary" @click="cancel2FaDialogs" type="button" />
        </div>
      </dialog>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';
  .smsCodeButton {
    margin-top: 13rem;
  }

  // Disable2FA container styles
  .disable2fa-container {
    display: grid;
    .dialog {
      border-radius: 5px;
      border-width: 1px;
      margin: -120px 0% 0px 25%;
      transition: 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
      width: 50%;
      z-index: inherit;
      box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 0.12);
    }
    .card {
      display: grid;
      grid-template-columns: 100%;
      gap: 10px 10px;
      margin-bottom: 10px;
      text-align: center;
      .cardTitle {
        border: 1px;
        background-color: #0134aa;
      }
      h6 {
        font-size: 22px;
        color: white;
        margin-left: 1ch;
      }
      .cardText {
        margin: 0ch 1ch 0ch 1ch;
        .digitCodeInput {
          font-size: 18px;
          text-align: center;

          width: 100%;
          height: 45px;
          border: 1px solid #ccc;
          padding: 5px;
          border-radius: 4px;
        }
      }

      .cardAction {
        margin-top: 20px;
      }
    }
  }

  .input {
    float: left;
    width: 100%;
    margin-top: 30px;
  }

  .modalWrapper {
    height: 780px;
    margin-top: -390px;
    text-align: center;
    .input {
      margin-bottom: 60px;
    }
    .masterCode {
      float: left;
      width: 100%;
      font-weight: 600;
      color: $blue;
      font-size: 18px;
      span {
        width: 100%;
        float: left;
        color: $grey;
        font-size: 14px;
      }
    }
  }

  .blueCTA {
    margin-top: 30px;
  }
  .blueCTA:disabled {
    opacity: 0.3;
  }
  .blueCTA:disabled:hover {
    cursor: default;
  }

  .dropdown-list {
    width: 38%;
    top: 31px;
    float: left;
  }

  .phoneInput {
    float: right;
    width: 60%;
  }

  .updatePhone {
    margin-top: 50px;
  }

  .dropdown-list.opened .list {
    height: 150px;
  }

  .faSwitch {
    margin-top: 50px;
  }
</style>

<script>
  import Moment from 'moment';
  import VueQr from 'vue-qr';
  import _orderBy from 'lodash/orderBy';
  import ExitIconButton from '../@components/ExitIconButton.vue';
  import services from '../../@libs/services';
  import eventBus from '../../@libs/eventBus';
  import InputText from '../@components/InputText.vue';
  import InputDropDown_V2 from '../@components/InputDropDown_V2.vue';
  import ToggleSwitch from '../@components/ToggleSwitch.vue';
  import InputButton from '../@components/InputButton.vue';
  // Enable this when we have the correct phone number masks
  // import { phoneCodes } from '../../@enums';
  import { phoneCodesV2 } from '../../@enums';
  import ClosingBanner from '../../@@layouts/Default/ClosingBanner/ClosingBanner.vue';

  export default {
    name: 'AccountDetails',
    data() {
      return {
        twoFa: '',
        countryCode: '+1',
        country: phoneCodesV2.findIndex((a) => a.text === 'United States +1'),
        phoneMask: '',
        phoneNumber: '',
        newPhoneNumber: '',
        currentPassword: '',
        password: '',
        confirmPassword: '',
        requires2Fa: false,
        showBarcodeChallenge: false,
        show2FaDisableChallenge: false,
        masterCode: '',
        barcode: '',
        pin: '',
        smsCode: '',
        isLoading: false,
      };
    },
    components: {
      ClosingBanner,
      ToggleSwitch,
      InputText,
      VueQr,
      InputDropDown_V2,
      InputButton,
      ExitIconButton,
    },
    computed: {
      disabledVerifyTokenButton() {
        return this.pin.length < 6;
      },
      twoFaRequired() {
        return this.userDetails.requires2Fa;
      },
      userDetails() {
        return this.$store.state.userDetails.userDetail;
      },
      verifySmsCodeDisabled() {
        return this.smsCode.length !== 8;
      },
      changePasswordDisabled() {
        const { password, confirmPassword } = this;
        return !(password !== '' && confirmPassword !== '' && password === confirmPassword);
      },
      passwordChangeDate() {
        return new Moment(this.userDetails.passwordChangeDate).format('dddd, MMMM Do YYYY');
      },
      changePhoneNumberDisabled() {
        const oldNumber = this.userDetails.phoneNumber
          ? this.userDetails.phoneNumber.replace(this.countryCode, '').replace(/-/g, '')
          : '';

        return (
          this.newPhoneNumber === oldNumber ||
          this.newPhoneNumber.length < 4 ||
          this.newPhoneNumber.length > 12
        );
      },
      phoneCodeList() {
        return _orderBy(
          phoneCodesV2.map((countryCode, index) => ({
            ...countryCode,
            value: index,
            code: countryCode.value,
          })),
          [({ text }) => (text === 'United States +1' ? 0 : 1), ({ text }) => text],
        );
      },
    },
    watch: {
      async requires2Fa(val) {
        if (val === this.userDetails.requires2Fa) return;

        if (val === true) {
          try {
            this.isLoading = true;

            const { secretKey, otpAuthUrl } = await services.userDetailService.enable2Fa();

            this.masterCode = secretKey;
            this.barcode = otpAuthUrl;

            this.showBarcodeChallenge = true;
          } finally {
            this.isLoading = false;
          }
        } else if (val === false) {
          if (this.userDetails.requires2Fa) {
            this.show2FaDisableChallenge = true;
          }
        }
      },
    },
    methods: {
      async changeUsersPassword() {
        try {
          this.isLoading = true;
          await services.userDetailService.changePassword(
            this.currentPassword,
            this.password,
            this.twoFa,
          );
          eventBus.raiseEvent({
            name: eventBus.eventTypes.app.notification,
            payload: 'Password changed successfully',
          });
          this.password = '';
          this.confirmPassword = '';
          this.currentPassword = '';
        } finally {
          this.isLoading = false;
        }
      },
      async updatePhoneNumber() {
        try {
          this.isLoading = true;
          await services.userDetailService.updatePhoneNumber(
            this.countryCode + this.newPhoneNumber,
          );
          this.phoneNumber = this.countryCode + this.newPhoneNumber;
        } finally {
          this.isLoading = false;
        }
      },
      async verifySmsCode() {
        try {
          this.isLoading = true;
          await services.userDetailService.verifyPhoneNumber(this.smsCode);
          eventBus.raiseEvent({
            name: eventBus.eventTypes.app.notification,
            payload: 'Successfully updated phone number',
          });
        } finally {
          this.isLoading = false;
        }
      },
      async send2FaEnableChallenge() {
        try {
          this.isLoading = true;
          await services.userDetailService.enable2Fa(this.masterCode, this.pin);
          this.cancel2FaDialogs();
        } finally {
          this.isLoading = false;
        }
      },
      async send2FaDisableChallenge() {
        try {
          this.isLoading = true;

          await services.userDetailService.disable2Fa(this.pin);
          this.cancel2FaDialogs();
        } finally {
          this.isLoading = false;
        }
      },
      cancel2FaDialogs() {
        this.showBarcodeChallenge = false;
        this.show2FaDisableChallenge = false;
        this.masterCode = '';
        this.pin = '';
        this.requires2Fa = this.userDetails.requires2Fa;
      },
      updatePhoneMask(selectedItem) {
        this.countryCode = selectedItem.code;
        this.phoneMask = selectedItem.mask;
      },
    },

    mounted() {
      this.requires2Fa = this.userDetails.requires2Fa;

      this.phoneNumber = this.userDetails.phoneNumber;

      this.phoneMask = phoneCodesV2.find(({ value }) => value === this.countryCode).mask;
    },
  };
</script>
