<template>
  <footer class="site-footer" id="site-footer" role="banner">
    <div class="flex-row top-items">
      <div class="flex-col flex-col-1">
        <ul>
          <li><router-link to="/faq">About BitJem</router-link></li>
          <li><router-link to="/privacypolicy">Privacy Policy</router-link></li>
        </ul>
      </div>
      <div class="flex-col flex-col-2">
        <a href="#hero" class="logo">
          <img src="../../@assets/img/logo-white.svg" alt="bitjem" />
        </a>
        <a href="mailto:info@bitjem.com" class="email">info@bitjem.com</a>
      </div>
    </div>

    <div class="flex-row bottom-items">
      <div class="flex-col flex-col-1" />
      <div class="flex-col flex-col-2">
        <div class="copy">
          <p>
            &copy; BitJem 2021<br />
            All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  @import '../../@assets/styles/_footer.scss';
</style>

<script>
  export default {
    name: 'Footer',
  };
</script>
