<template>
  <div v-if="!exit">
    <div class="overlay" />
    <div class="modalWrapper step2">
      <Banner
        :show.sync="showTip"
        color="info"
        label="Info"
        message="We strongly recommend non US-based users submit a passport to verify their identity. Other forms of identification may result in failing verification and locking your account."
        @dismiss="showTip = !showTip"
      />
      <br />
      <ExitIconButton @click="exited()" />
      <div class="modalTitle">
        <div class="big">Verify your identity</div>
        <div class="small">Upload your ID or Passport</div>
      </div>
      <div id="leftSide" :class="['verifyBox', { active: selected[1] }]" @click="select(1)">
        <div class="radioSelect" />
        <div class="iconWrapper">
          <i class="icon-passport" />
        </div>
        <div class="idTitle">
          Upload
          <br />
          Passport
        </div>
        <div class="idSubtitle">(Face photo page)</div>
      </div>
      <div id="rightSide" :class="['verifyBox', { active: selected[0] }]" @click="select(0)">
        <div class="radioSelect" />
        <div class="iconWrapper">
          <i class="icon-car" />
        </div>
        <div class="idTitle">
          Upload
          <br />
          Driver's Licence or Govt. Issued Id
        </div>
        <div class="idSubtitle">(Front and back)</div>
      </div>
      <div class="fullRow">
        <InputButton
          label="Next"
          type="button"
          color="primary"
          @click="
            $emit('input', selectedValue);
            next();
          "
        />
        <InputButton
          color="secondary"
          label="Back"
          type="button"
          @click="back"
          :disabled="disableBackButton"
        />
        <InputButton label="Cancel" color="secondary" @click="exited()" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../../../@assets/styles/variables.scss';

  .verifyBox {
    width: 48%;
    margin-left: 4%;
    float: left;
    box-shadow: 1px 4px 10px 0px rgba(1, 50, 164, 0.08);
    background: $white;
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    position: relative;
    margin-top: 30px;
    margin-bottom: 70px;
    transition: all ease-in-out 0.3s;
    :hover {
      cursor: pointer;
    }
    .iconWrapper {
      width: 135px;
      height: 135px;
      background: $white;
      box-shadow: 0px 4px 24px 0px rgba(1, 50, 164, 0.3);
      border-radius: 200px;
      position: relative;
      display: block;
      margin: 0 auto;
      text-align: center;
    }
    .iconWrapper:after {
      content: '';
      width: 159px;
      height: 159px;
      border-radius: 200px;
      border: 1px solid #010e2d;
      opacity: 0.1;
      position: absolute;
      top: -12px;
      left: -12px;
    }
    .idTitle {
      font-weight: 600;
      font-size: 22px;
      line-height: 24px;
      float: left;
      width: 100%;
      margin-bottom: 10px;
      text-align: center;
      margin-top: 45px;
      letter-spacing: -1px;
    }
    .idSubtitle {
      font-size: 16px;
      line-height: 18px;
      float: left;
      width: 100%;
      text-align: center;
      color: #9fafd2;
      font-weight: 500;
    }
    .radioSelect {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30px;
      height: 30px;
      border: 1px solid $lightblue;
      border-radius: 100px;
    }
    .radioSelect:after {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      border-radius: 50px;
      top: 6px;
      left: 6px;
      background: $petrol;
      transition: all ease-in-out 0.3s;
      opacity: 0;
    }
    .icon-car {
      font-size: 40px;
      position: relative;
      top: 45px;
      color: $petrol;
    }
    .icon-passport {
      font-size: 70px;
      position: relative;
      top: 28px;
      color: $petrol;
    }
  }
  .verifyBox:nth-child(odd) {
    margin-left: 0;
  }
  .verifyBox.active {
    box-shadow: 1px 24px 30px 0px rgb(1 50 164 / 10%);
  }
  .verifyBox.active .radioSelect:after {
    opacity: 1;
  }
  .cancelCTA,
  .cyanCTA {
    margin: 0 auto;
    display: block;
  }
  .step2 {
    height: 780px;
    margin-top: -390px;
  }

  @media screen and (max-width: 640px) {
    .step2 {
      display: flex;
      flex-direction: column;
      height: 90vh;
    }

    .verifyBox {
      width: 100%;
      margin-top: 0;
    }
  }
</style>

<script>
  import InputButton from '../../../../@components/InputButton.vue';
  import ExitIconButton from '../../../../@components/ExitIconButton.vue';
  import Banner from '../../../../@components/Banner.vue';

  export default {
    name: 'Step2',
    props: {
      documentChoice: {
        type: String,
        required: true,
      },
      next: {
        type: Function,
        default: () => {},
      },
      back: {
        type: Function,
        default: () => {},
      },
    },
    components: { Banner, InputButton, ExitIconButton },
    data() {
      return {
        exit: false,
        selected: [false, true],
        showTip: true,
      };
    },
    computed: {
      selectedValue() {
        return this.selected[0] ? 'License' : 'Passport';
      },
      disableBackButton() {
        return this.$store.state.userDetails.userDetail.isLevel1;
      },
    },
    methods: {
      select(index) {
        this.selected = [false, false];
        this.selected[index] = true;
      },
      exited() {
        this.exit = !this.exit;
        this.$router.push({ path: '/dashboard' });
      },
    },
  };
</script>
