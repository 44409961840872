<template>
  <div class="mainContainer redeemCards">
    <ClosingBanner />
    <Banner
      @dismiss="showBanner = !showBanner"
      :show.sync="showBanner"
      :autohide="true"
      :label="bannerData.label"
      :message="bannerData.message"
      :color="bannerData.color"
    />
    <div class="tableContainer">
      <div class="formContainer">
        <PendingCardsBanner />
        <h2>
          <img alt="present" src="../../../@assets/img/dashboard/redeem-1.svg" />
          Redeem your cards
          <span>
            Please find your card and type in the code you see on the back side. <br />
            You can add as many cards as you want
          </span>
        </h2>
        <div class="form">
          <div v-for="(card, index) in cards" :key="index" class="formRow">
            <span class="cardIndex"># {{ index + 1 }}</span>
            <InputText
              v-model.trim="card.code"
              class="code"
              label="Code"
              placeholder="Code"
              :disabled="index !== cards.length - 1"
              @blur="checkCard(index)"
            />
            <InputText
              v-model.trim="card.pin"
              class="pin"
              label="PIN"
              placeholder="PIN"
              :disabled="index !== cards.length - 1"
              @blur="checkCard(index)"
            />
            <div :class="{ 'not-visible': index === 0 }" class="actions">
              <i class="icon-error" title="Delete card" @click="removeRow(index)" />
            </div>
          </div>
        </div>
        <a class="blueCTA" href="#" @click="addRow" v-if="isLevel2">
          <i class="icon-plus" />
          <span>Add another card</span>
        </a>
      </div>
      <div class="redeemDetails">
        <h3>Summary</h3>
        <InputDropDown_V2
          v-model.trim="currency"
          label="Currency"
          class="currencyStyle"
          placeholder="Currency"
          :items="currencyList"
        />
        <div class="detailRow">
          <span>Number of cards</span>
          <em>{{ numberOfCards }}</em>
        </div>
        <div class="detailRow">
          <span>Gift Cards</span>
          <em>{{ usdTotal | toCurrency }}</em>
        </div>
        <div class="detailRow">
          <span>Price of {{ currency.toUpperCase() }}</span>
          <em>{{ rates?.price | toCurrency }}</em>
        </div>
        <div class="detailRow">
          <span>BitJem Fee</span>
          <em>{{ rates?.feePercentage || '---' }} %</em>
        </div>
        <div class="bottomSummary">
          <div class="detailRow">
            <span>Estimated Total ({{ currency.toUpperCase() }})</span>
            <em>{{ total }}</em>
          </div>
          <InputButton
            :isLoading="isLoading"
            label="Redeem"
            type="button"
            color="primary"
            :disabled="disableRedeem"
            @click="redeem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';

  .disabled {
    opacity: 0.2;
    background: $grey;
    cursor: default;
  }

  .tableContainer {
    float: left;
    width: 100%;
    display: table;
    height: calc(100vh - 300px);
  }

  .redeemCards {
    min-height: calc(100vh - 300px);
  }

  .redeemCards h2 {
    position: relative;
    margin-left: 70px;
  }

  .redeemCards h2 img {
    position: absolute;
    left: -70px;
    top: 0;
  }

  .formContainer {
    float: left;
    width: 90%;
    display: table-cell;

    .formRow {
      width: 100%;
      float: left;
      margin-top: 40px;

      .cardIndex {
        color: $lightblue;
        font-weight: 600;
        float: left;
        margin-top: 20px;
        width: 25px;
        text-align: center;
      }

      .input {
        float: left;
      }

      .input.code {
        width: calc(61% - 25px);
        margin: 0 2%;
      }

      .input.pin {
        width: calc(31% - 25px);
        margin: 0 2%;
      }

      .actions {
        float: left;
        width: 25px;
        margin-top: 19px;
        text-align: center;

        .icon-error {
          font-size: 20px;
          border-radius: 7px;
          transition: $small;
          border: 1px solid $red;
          padding: 5px;
          background-color: #eb5757;

          &:before {
            background-color: #eb5757;
            color: $white;
          }
        }

        .icon-error:hover {
          cursor: pointer;
          background: rgba(235, 87, 87, 0.25);

          &::before {
            background: rgba(235, 87, 87, 0.05);
            color: #eb5757;
          }
        }
      }
    }

    .blueCTA {
      margin-top: 50px;

      i {
        position: relative;
        margin-right: 10px;
        font-size: 14px;
        top: 1px;
      }
    }
  }

  .redeemDetails {
    width: 30%;
    min-height: calc(100vh - 300px);
    position: relative;
    display: table-cell;
    vertical-align: top;

    h3 {
      font-size: 24px;
      color: $black;
      font-weight: bold;
    }

    .currencyStyle {
      margin-bottom: 50px;
    }

    .detailRow {
      float: left;
      width: 100%;
      color: $black;
      font-size: 18px;
      margin-bottom: 15px;

      span {
        float: left;
        font-weight: 600;
      }

      em {
        float: right;
        font-weight: 400;
        font-style: normal;
      }
    }

    .cyanCTA {
      color: $white !important;
      width: 100%;
      display: inline-block;
      text-align: center;
      padding: 22px 15px;
    }

    .cyanCTA.disabled:hover {
      background: inherit;
      background: $grey;
    }

    .bottomSummary {
      float: left;
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      /*margin-top:calc(82vh - 500px);*/
      .detailRow {
        font-size: 25px;

        em,
        span {
          font-weight: 700;
        }
      }
    }
  }

  @media screen and (orientation: landscape) and (max-height: 767px) {
    .bottomSummary {
      position: relative !important;
      margin-top: 50px;
    }
  }

  @media screen and (max-width: 479px) {
    .formContainer {
      .formRow {
        .actions {
          .icon-error {
            &::after {
              color: $white;
            }

            &:hover {
              &::after {
                color: red;
              }
            }
          }
        }
      }
    }
  }
</style>

<script>
  import _map from 'lodash/map';
  import services from '../../../@libs/services';
  import store from '../../../@store';
  import Banner from '../../@components/Banner.vue';
  import InputButton from '../../@components/InputButton.vue';
  import InputText from '../../@components/InputText.vue';
  import InputDropDown_V2 from '../../@components/InputDropDown_V2.vue';
  import giftCardService from '../../../@libs/services/giftCardService';
  import PendingCardsBanner from '../../@components/PendingCards.vue';
  import { currencies } from '../../../@enums';
  import ClosingBanner from '../../../@@layouts/Default/ClosingBanner/ClosingBanner.vue';

  export default {
    name: 'GCRedemption',
    components: { ClosingBanner, PendingCardsBanner, Banner, InputText, InputDropDown_V2, InputButton },
    data() {
      return {
        pendingCards: [],
        usdValue: [0],
        rateRefresh: null,
        rates: {},
        showBanner: false,
        isLoading: false,
        bannerData: {
          color: null,
          label: '',
          message: '',
        },
        cards: [
          {
            code: '',
            pin: '',
          },
        ],
        currency: 'btc',
        redeemedCard: null,
      };
    },
    computed: {
      currencyList() {
        return _map(currencies, (a) => ({
          text: a.display,
          prepend: `icon-${a.icon}`,
          value: a.currency,
        })).filter((a) => a.value !== 'usd');
      },
      userDetail() {
        return this.$store.state.userDetails.userDetail;
      },
      isLevel2() {
        return this.$store.state.userDetails.userDetail.isLevel2;
      },
      numberOfCards() {
        const count = this.cards.filter((a) => !!a.code && !!a.pin).length;
        return count !== 0 ? count.toString() : '---';
      },
      disableRedeem() {
        return this.cards.filter((a) => !!a.code && !!a.pin).length === 0;
      },
      usdTotal() {
        return this.usdValue.reduce((total, newValue) => total + newValue, 0);
      },
      total() {
        if (!this.rates || !this.rates || !this.rates.perUsd || !this.rates.feeRate) {
          return '---';
        }

        if (this.usdTotal === 0) return '---';
        return (this.usdTotal * this.rates.perUsd * this.rates.feeRate).toFixed(8);
      },
    },
    watch: {
      async currency() {
        await this.refreshRates();
      },
    },
    methods: {
      async checkCard(index) {
        const { code, pin } = this.cards[index];
        if (code && pin) {
          const cardBalance = await giftCardService.checkBalance(code, pin);
          this.usdValue = [
            ...this.usdValue.slice(0, index),
            cardBalance.value,
            ...this.usdValue.slice(index + 1),
          ];
        }
      },
      async redeem() {
        let result = null;

        if (this.disableRedeem) return;

        try {
          this.isLoading = true;
          this.displayError = false;

          if (this.cards.length === 1) {
            result = await services.giftCardService.redeem(
              this.cards[0].code,
              this.cards[0].pin,
              this.currency,
            );
          } else {
            result = await services.giftCardService.bulkRedeem(this.cards, this.currency);
          }

          if (result.status === 'PendingRedemption') {
            this.$router.push({ path: '/giftCard/pii' }).catch((msg) => console.error(msg));
          } else {
            this.$router
              .push({ path: '/dashboard', query: { showBanner: true } })
              .catch((msg) => console.error(msg));
          }
        } catch (error) {
          this.bannerData.label = 'Redeem Error';
          this.bannerData.message = error.toString();
          this.bannerData.color = 'error';
        } finally {
          this.showBanner = true;

          this.isLoading = false;
          window.scrollTo(0, 0);
        }
      },
      removeRow(index) {
        this.cards = [...this.cards.slice(0, index), ...this.cards.slice(index + 1)];
        this.usdValue = [...this.usdValue.slice(0, index), ...this.usdValue.slice(index + 1)];
      },
      addRow() {
        if (!this.userDetail.isLevel2) return;
        if (this.cards.length >= store.state.groupDetails.maxBulkRedeemCount) return;
        this.cards.push({ code: '', pin: '' });
        this.usdValue.push(0);
      },
      async refreshRates() {
        const rates = await services.giftCardService.getCurrentRates(this.currency);
        this.rates = rates;
      },
      async loadData() {
        // CALL SHOULD BE FIRST AND SEPARATE TO NOT CAUSE DELAY IN SEEING BANNER
        this.pendingCards = await giftCardService.listAllUsersPendingCardsCards();

        await this.refreshRates();
      },
    },
    async beforeMount() {
      await this.loadData();

      if (!this.userDetail.isLevel2 && this.pendingCards.length > 0) {
        await this.$router.push({ path: '/giftCard/pii' }).catch((e) => console.error(e));
      }
    },
    mounted() {
      this.rateRefresh = setInterval(() => this.refreshRates(), 60000);
    },
    beforeDestroy() {
      clearInterval(this.rateRefresh);
    },
  };
</script>
