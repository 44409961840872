<template>
  <div>
    Total Users: {{ groupDashboard.totalUsers }}
    <br />
    Verified Users: {{ groupDashboard.verifiedUsers }}
    <br />
    UnRedeemed Gift Cards: {{ groupDashboard.unRedeemedCount }}
    <br />
    UnRedeemed Gift Cards Value: ${{ groupDashboard.unRedeemedValue }}
    <br />
    <br />
    <table>
      <tr>
        <th>Code</th>
        <th>Pin</th>
        <th>Value</th>
      </tr>
      <tr />
      <tr v-for="card in groupDashboard.unRedeemedGiftCards" :key="card.code">
        <td>{{ card.code }}</td>
        <td>{{ card.pin }}</td>
        <td>${{ card.value }}</td>
        <td>
          <input
            :disabled="!!card.cancelled"
            type="button"
            value="Cancel Card"
            @click="cancelCard(card)"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="scss">
  table {
    margin-left: auto;
    margin-right: auto;
  }

  th,
  tr,
  td {
    border: 1px solid black;
    padding-left: 5px;
    padding-right: 5px;
  }
</style>

<script>
  export default {
    name: 'AdminDashboard',
    props: {
      groupDashboard: {
        type: Object,
        default() {
          return {
            totalUsers: {
              type: Number,
              default: 0,
              required: true,
            },
            verifiedUsers: {
              type: Number,
              default: 0,
              required: true,
            },
            unRedeemedCount: {
              type: Number,
              default: 0,
              required: true,
            },
            unRedeemedValue: {
              type: Number,
              default: 0,
              required: true,
            },
            unRedeemedGiftCards: {
              type: Array,
              default: 0,
              required: true,
            },
          };
        },
      },
    },
    methods: {
      cancelCard(card) {
        this.$emit('cancel', card);
      },
    },
  };
</script>
