<template>
  <div>
    <component :is="componentName" />
  </div>
</template>

<style lang="scss">
</style>

<script>
  export default {
    name: 'HtmlTemplates',
    components: {
    },
    props: {},
    data() {
      return {
      };
    },
    created() {
    },
    computed: {
      componentName() {
        const name = this.$route.params.componentName;
        return () => import(`./@templates/${name}`);
      },
    },
    methods: {
    },
  };
</script>
