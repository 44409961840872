<template>
  <i class="icon-close" @click="$emit('click')" />
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/common';

  .icon-close {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 20px;
    transition: $small;
  }
  .icon-close:before {
    transition: $small;
  }
  .icon-close:hover:before {
    cursor: pointer;
    color: $white;
  }
</style>

<script>
  export default {
    name: 'ExitIconButton',
  };
</script>
