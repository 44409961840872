<template>
  <div class="mainContainer">
    <div class="innerContainer">
      <Banner
        @dismiss="showBanner = !showBanner"
        :show.sync="showBanner"
        :autohide="true"
        :label="bannerData.label"
        :message="bannerData.message"
        :color="bannerData.color"
      />
      <div class="headerTexts">
        <h2>Phone Verification</h2>
        <h5>
          {{
            initialPhoneVerifyPage
              ? 'Please enter the code found in the SMS sent to'
              : 'Please provide us with your phone number'
          }}

          <br />
          <h5 v-if="initialPhoneVerifyPage" class="phone-number-text">{{ phoneNumber }}</h5>
        </h5>
      </div>
      <div v-if="initialPhoneVerifyPage" class="edit-phone-button">
        <InputButton
          type="button"
          label="Edit Phone Number"
          color="primary"
          @click="editPhoneNumber"
        />
      </div>
      <div v-if="initialPhoneVerifyPage">
        <div class="inputText">
          <InputText
            v-model.trim="phoneCode"
            label="Please enter the code"
            placeholder="SMS Code"
          />
        </div>
        <div class="inputButton">
          <InputButton
            label="Confirm Phone Number"
            color="primary"
            @click="verifyPhoneCode"
            type="button"
            :disabled="disableVerifyPhoneCodeButton"
          />
        </div>
        <div class="inputButton" style="margin-top: 1rem">
          <InputButton
            label="Resend Code"
            color="blueCTA"
            @click="resendCodeNumber"
            type="button"
            :disabled="disableResendCodeButton"
          />
        </div>
      </div>
      <div v-else class="phoneProvideForm">
        <div>
          <InputDropDownV2
            v-model.trim="country"
            :items="phoneCodeList"
            label="Country"
            placeholder="Country"
            @selectionChanged="updatePhoneMask"
          />
        </div>
        <div class="inputText phoneNumberInputText">
          <InputText
            v-model.trim="phone"
            label="Please enter your phone number to continue"
            placeholder="Phone Number"
            :mask="phoneMask"
            :type="phoneMask ? 'text' : 'number'"
          />
        </div>
        <div class="inputButton">
          <InputButton
            :disabled="disableChangePhoneNumButton"
            color="primary"
            type="button"
            label="Continue"
            @click="updatePhoneNumber"
          />
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../../@assets/styles/variables.scss';

  .phoneProvideForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .phoneNumberInputText {
      float: left;
      width: 100%;
    }
    .dropdown-list {
      width: 100%;
    }
  }

  .headerTexts {
    text-align: center;
    overflow-wrap: break-word;
    h2 {
      font-size: 5rem;
      color: $petrol;
      text-shadow: 0.5px 0.5px black;
    }
    h5 {
      margin-top: -1rem;
      font-size: 2.4rem;
      font-weight: 300;
      color: $black;
    }
    .phone-number-text {
      font-weight: 700;
    }
  }
  .inputText {
    margin-top: 30px;
  }

  .inputButton {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }

  .edit-phone-button {
    margin-bottom: 6rem;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    .headerTexts {
      h2 {
        font-size: 3.4rem;
        text-shadow: 1.5px 0.5px black;
      }
      h5 {
        font-size: 2.1rem;
      }
    }
    .input-button {
      display: flex;
      justify-content: center;
      min-width: 20rem !important;
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 450px) {
    .headerTexts {
      h5 {
        font-size: 1.9rem;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .headerTexts {
      h2 {
        font-size: 2.6rem;
        text-shadow: 1.5px 0.5px black;
      }
      h5 {
        font-size: 1.6rem;
      }
    }
    .input-button {
      display: flex;
      justify-content: center;
      min-width: 20rem !important;
      font-size: 1.4rem;
    }
  }
</style>

<script>
  import _orderBy from 'lodash/orderBy';
  import services from '../../../@libs/services';
  import InputDropDownV2 from '../../@components/InputDropDown_V2.vue';
  import InputButton from '../../@components/InputButton.vue';
  import InputText from '../../@components/InputText.vue';
  import Banner from '../../@components/Banner.vue';
  // import { phoneCodes } from '../../../@enums';
  import { phoneCodesV2 } from '../../../@enums';

  export default {
    name: 'VerifyPhoneNumber',
    components: { Banner, InputDropDownV2, InputText, InputButton },
    data() {
      return {
        isLoading: false,
        phoneCode: '',
        phone: '',
        country: phoneCodesV2.findIndex((a) => a.text === 'United States +1'),
        countryCode: '+1',
        phoneMask: '###-###-####',
        editPhone: false,
        resendCode: true,
        showBanner: false,
        bannerData: {
          label: '',
          message: '',
          color: 'success',
        },
      };
    },
    computed: {
      disableChangePhoneNumButton() {
        return this.phone === '' || this.phone.length < 4 || this.phone.length > 12;
      },
      disableVerifyPhoneCodeButton() {
        return this.phoneCode.length !== 8;
      },
      disableResendCodeButton() {
        return !this.resendCode;
      },
      userId() {
        return this.$store.state.userDetails.userDetail.id;
      },
      phoneNumber() {
        return this.$store.state.userDetails.userDetail.phoneNumber;
      },
      phoneCodeList() {
        return _orderBy(
          phoneCodesV2.map((countryCode, index) => ({
            ...countryCode,
            value: index,
            code: countryCode.value,
          })),
          [({ text }) => (text === 'United States +1' ? 0 : 1), ({ text }) => text],
        );
      },
      initialPhoneVerifyPage() {
        return !this.editPhone && this.phoneNumber;
      },
    },
    methods: {
      async resendCodeNumber() {
        this.resendCode = false;
        try {
          await services.userDetailService.resendSMS();
          setTimeout(() => {
            this.resendCode = true;
          }, 30000);
        } finally {
          this.clearData();
        }
      },
      editPhoneNumber() {
        this.editPhone = true;
        this.countryCode = '+1';
        this.phone = '';
      },
      async verifyPhoneCode() {
        this.isLoading = true;
        try {
          await services.userDetailService.verifyPhoneNumber(this.phoneCode);
          this.bannerData.label = 'Verify Phone Number Success';
          this.bannerData.message = 'Your phone number verified successfully!';
          this.bannerData.color = 'success';
        } catch (error) {
          this.bannerData.label = 'Verify Phone Number Error';
          this.bannerData.message = error.toString();
          this.bannerData.color = 'error';
        } finally {
          this.clearData();
        }
      },
      async updatePhoneNumber() {
        this.isLoading = true;
        try {
          await services.userDetailService.updatePhoneNumber(this.countryCode + this.phone);
          this.bannerData.label = 'Update Phone Number Success';
          this.bannerData.message = 'Your phone number updated successfully!';
          this.bannerData.color = 'success';
        } catch (error) {
          this.bannerData.label = 'Update Phone Number Error';
          this.bannerData.message = error.toString();
          this.bannerData.color = 'error';
        } finally {
          this.clearData();
        }
      },
      updatePhoneMask(selectedItem) {
        this.countryCode = selectedItem.code;
        this.phoneMask = selectedItem.mask;
      },
      clearData() {
        this.showBanner = true;
        this.isLoading = false;
        this.editPhone = false;
      },
    },
    mounted() {
      this.phoneMask = phoneCodesV2.find(({ value }) => value === this.countryCode).mask;
    },
  };
</script>
