<template>
  <div class="generalMainContainer">
<!--    <ClosingBanner />-->
    <Banner
      @dismiss="showBanner = !showBanner"
      :show.sync="showBanner"
      :label="bannerData.label"
      :message="bannerData.message"
      :color="bannerData.color"
      :autohide="bannerData.autohide"
    />
    <div>
      <h2>{{ headerTitle }}</h2>
    </div>
    <form id="loginForm" @submit.prevent="handleLoginOrRegisterNew">
      <div class="generalInnerContainer" v-if="displayLogin">
        <div class="2faLogin" v-if="requires2Fa">
          <div class="inputText">
            <InputText label="2FA Pin" v-model.trim="pin" placeholder="2FA Pin" id="pin" />
            <InputButton
              :isLoading="isLoading"
              style="margin-top: 20px"
              :disabled="submit2FAButtonEnabled"
              label="Submit"
              color="primary"
              type="submit"
            />
          </div>
          <div class="disable2faBtn">
            <InputButton
              v-if="requires2Fa"
              label="Disable 2FA"
              :disabled="loginButtonEnabled"
              @click="disable2Fa"
              color="blueCTA"
              type="button"
            />
          </div>
        </div>
        <div v-else>
          <div class="inputText">
            <InputText
              label="Email Address"
              v-model.trim="emailAddress"
              placeholder="Email Address"
              id="emailAddress"
            />
          </div>
          <div class="inputText">
            <InputText
              label="Password"
              v-model.trim="password"
              placeholder="Password"
              id="password"
              type="password"
            />
          </div>

          <div class="inputText" v-if="registerNew">
            <InputText
              type="password"
              label="Confirm Password"
              id="confirmPassword"
              placeholder="Confirm Password"
              v-model.trim="confirmPassword"
            />
          </div>

          <!-- Register New CheckBox -->
          <div class="register-new">
            <InputCheck
              style="font-size: larger"
              :showHelp="false"
              v-model.trim="registerNew"
              label="Register New"
              value="registerNew"
            />
          </div>

          <InputButton
            :label="!this.registerNew ? 'Login' : 'Register'"
            :disabled="loginButtonEnabled"
            color="primary"
            type="submit"
            :isLoading="isLoading"
          />

          <!-- Password Reset Button -->
          <div style="margin-top: 15px">
            <InputButton
              style="margin: 0 auto; display: block"
              label="Forgot Password"
              color="blueCTA"
              :disabled="resetPasswordButtonEnabled"
              @click="resetPassword"
              type="button"
              :isLoading="isLoading"
            />
          </div>
        </div>
      </div>
      <div v-else>
        Congrats, please check your email {{ emailAddress }} for your verification email!
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';
  .register-new {
    display: flex;
    justify-content: center;
    margin: 25px 0px;
    text-wrap: nowrap;
  }

  .disable2faBtn {
    display: flex;
    justify-content: center;
    margin: -20px 0px;
  }

  @media screen and (max-width: 500px) {
    .register-new {
      .flex-row {
        font-size: medium !important;
      }
    }
  }
</style>

<script>
  import services from '../../@libs/services';
  import config from '../../../config';
  import InputButton from '../@components/InputButton.vue';
  import InputText from '../@components/InputText.vue';
  import InputCheck from '../@components/InputCheck.vue';
  import Banner from '../@components/Banner.vue';
  import ClosingBanner from '../../@@layouts/Default/ClosingBanner/ClosingBanner.vue';

  export default {
    name: 'Login',
    components: { ClosingBanner, InputButton, InputText, InputCheck, Banner },
    data() {
      return {
        isLoading: false,
        showBanner: false,
        enableResetButton: true,
        bannerData: {
          label: '',
          message: '',
          color: 'success',
          autohide: true,
        },
        emailAddress: config.username || '',
        password: config.password || '',
        confirmPassword: config.password || '',
        registerNew: false,
        displayLogin: true,
        pin: '',
      };
    },
    computed: {
      headerTitle() {
        // eslint-disable-next-line no-nested-ternary
        return this.requires2Fa ? '2FA PIN' : !this.registerNew ? 'Sign-In' : 'Register';
      },
      loginButtonEnabled() {
        const { emailAddress, registerNew, confirmPassword, password } = this;

        return (
          emailAddress === '' ||
          password === '' ||
          (registerNew && (confirmPassword === '' || confirmPassword !== password))
        );
      },
      resetPasswordButtonEnabled() {
        return !this.enableResetButton || this.emailAddress === '';
      },
      submit2FAButtonEnabled() {
        const { pin } = this;

        return pin.length < 6;
      },
      requires2Fa() {
        if (Array.isArray(this.$store.state.userDetails.jwt.permissions)) {
          return this.$store.state.userDetails.jwt.permissions.some((a) => a === 'OtpLogin');
        }
        return this.$store.state.userDetails.jwt.permissions === 'OtpLogin';
      },
    },
    methods: {
      async handleLogin() {
        let userDetail = null;
        try {
          this.isLoading = true;
          if (!this.requires2Fa) {
            userDetail = await services.userDetailService.login(this.emailAddress, this.password);
          } else {
            userDetail = await services.userDetailService.loginWith2Fa(this.pin);
          }

          if (userDetail && !this.requires2Fa) await this.redirectAfterLogin();
        } catch (error) {
          this.bannerData.label = 'Login Error';
          this.bannerData.message = error.toString();
          this.bannerData.color = 'error';
          this.bannerData.autohide = true;
          this.showBanner = true;
        } finally {
          this.isLoading = false;
        }
      },
      async handleRegisterNew() {
        try {
          this.isLoading = true;
          const newUser = await services.userDetailService.registerNewUser(
            this.emailAddress,
            this.password,
          );

          this.displayLogin = !newUser;
          return true;
        } catch (error) {
          this.bannerData.label = 'Register Error';
          this.bannerData.message = error.toString();
          this.bannerData.color = 'error';
          this.bannerData.autohide = true;
          this.showBanner = true;
        } finally {
          this.isLoading = false;
        }

        return false;
      },
      async handleLoginOrRegisterNew() {
        if (!this.registerNew) {
          await this.handleLogin();
        } else {
          if (await this.handleRegisterNew()) await this.handleLogin();
        }
      },
      async resetPassword() {
        try {
          this.isLoading = true;

          await services.userDetailService.resetPassword(this.emailAddress);

          this.bannerData.label = 'Password Reset Success';
          this.bannerData.message =
            'An email has been sent to reset your password. Please check your inbox and follow the instructions';
          this.bannerData.color = 'success';
          this.bannerData.autohide = false;

          this.enableResetButton = false;
        } catch (error) {
          this.bannerData.label = 'Password Reset Error';
          this.bannerData.message = error.toString();
          this.bannerData.color = 'error';
          this.bannerData.autohide = true;
        } finally {
          this.showBanner = true;
          this.isLoading = false;
        }
      },

      async redirectAfterLogin() {
        const path = this.$router.currentRoute.query.redirect || '/dashboard';
        await this.$router.push({ path });
      },
      disable2Fa() {
        this.$router.push('/disable2fa');
      },
    },
    async mounted() {
      this.registerNew = this.$router.currentRoute.query?.registerNew ?? false;
    },
  };
</script>
