<template>
  <div>
    <div class="mainContent wallet">
      <ClosingBanner />
      <PendingCardsBanner />
      <Banner
        :show.sync="showPendingTransactionBanner"
        :autohide="false"
        color="info"
        label="Pending Transactions"
        message="You have pending transactions. Your wallet balance will be updated once the transactions are complete."
      />
      <Banner
        :show.sync="showSuccessRedeemCardBanner"
        :autohide="true"
        color="success"
        label="Redeem Card"
        message="You have successfully redeem a card. Please check your new balance!"
      />
      <div class="myBalance">
        <div class="dashHeader">
          <label>Balance</label>
          <i class="icon-refresh" @click="loadBalances" />
        </div>
        <template v-if="isLoadingBalances"> loading...</template>
        <template>
          <CurrencyWallet
            v-for="balance in filteredBalances"
            :amount="balance.amount"
            :currency="balance.currency"
            :key="balance.currency"
            @click="startTransfer(balance)"
            @cancel="cancelTransfer()"
          />
          <router-link to="giftCard/redemption" class="redeemCard">
            <i class="icon-card" />REDEEM CARD(s)
          </router-link>
        </template>
      </div>
      <div class="myTrans">
        <div class="dashHeader">
          <label>Transactions</label>
          <i class="icon-refresh" @click="loadTransactions" />
        </div>
        <template v-if="isLoadingTransactions"> loading...</template>
        <template>
          <span v-if="!hasTransactions">No transactions have been made yet.</span>
          <TransactionRow
            v-else
            v-for="transaction in transactions"
            :tx-type="transaction.paymentType === 'Withdrawal' ? 'send' : 'receive'"
            :card="transaction.paymentId"
            :date="formatDate(transaction.createdAt)"
            :description="transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)"
            :amount="transaction.amount"
            :key="transaction.paymentId"
            :canceled="transaction.status === 'cancelled'"
            :currency="transaction.currency"
            :comment="transaction.comment"
          />
        </template>
      </div>
    </div>

    <Transfer
      v-if="showTransfer"
      savedWallets="wall"
      @cancel="cancelTransfer"
      :balance="selectedBalance.amount"
      :currency="selectedBalance.currency"
    />
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';

  .mainContent {
    width: 74%;
    margin: 140px auto 0 auto;
  }

  .myBalance,
  .myTrans {
    background: $white;
    padding: 30px;
    border-radius: 20px;
    box-shadow: -1px 45px 89px 0px rgba(1, 52, 170, 0.11);
  }

  .myBalance {
    float: left;
    width: 47%;
    margin-right: 0.5%;
    position: relative;

    .redeemCard {
      width: 100%;
      background: $petrol;
      position: absolute;
      left: 0;
      bottom: -95px;
      text-align: center;
      color: $white;
      font-weight: 600;
      font-size: 20px;
      border-radius: 10px;
      padding: 17px 0;
      box-shadow: 0px 14px 26px 0px rgba(1, 52, 170, 0.25);
      transition: $small;

      i {
        font-size: 30px;
        margin-right: 10px;
        position: relative;
        top: 7px;
      }
    }

    .redeemCard:hover {
      background: $blue;
    }
  }

  .myTrans {
    float: right;
    width: 52%;
    margin-left: 0.5%;
    max-height: calc(100vh - 180px);
    overflow: auto;
  }

  .dashHeader {
    float: left;
    width: 100%;
    position: relative;

    label {
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: $grey;
      position: relative;
      padding-right: 20px;
      background: $white;
      z-index: 1;
    }

    i {
      float: right;
      color: $blue;
      position: relative;
      cursor: pointer;
      padding: 1rem;

      &:hover {
        &::after {
          background: darken($greyblue, 7%);
        }
      }

      &::before {
        z-index: 1;
        position: relative;
        font-size: 14px;
        border-radius: 50%;
      }

      &::after {
        transition: $small;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: $greyblue;
        opacity: 0.5;
      }

      span {
        font-family: 'Averta', sans-serif;
        margin-left: 10px;
        font-size: 12px;
        font-weight: 600;
        color: $blue;
      }
    }
  }

  .dashHeader:after {
    content: '';
    width: 87%;
    height: 1px;
    background: $greyblue;
    position: absolute;
    top: 9px;
    right: 70px;
  }

  .walletBox {
    width: 100%;
    float: left;
    border: 1px solid $greyblue;
    border-radius: 20px;
    box-shadow: 0px 0px 74px 0px rgba(1, 52, 170, 0.09);
    padding: 1.56vw;
    margin-top: 15px;

    i {
      float: left;
      position: relative;
    }

    i:after {
      content: '';
      position: relative;
      float: left;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border: 1px solid $greyblue;
    }

    i:before {
      position: absolute;
      z-index: 1;
      font-size: 20px;
      top: 9px;
      left: 14px;
    }

    .walletAmount {
      float: left;
      color: $black;
      font-size: 37px;
      line-height: 37px;
      font-weight: 300;
      position: relative;
      top: 4px;

      span {
        font-size: 16px;
        line-height: 16px;
        position: relative;
        top: -6px;
        margin-left: 10px;
      }
    }

    a {
      font-size: 15px;
      padding: 10px 15px;
      line-height: 15px;
      border: 1px solid $petrol;
      font-weight: 500;
      color: $petrol;
      border-radius: 5px;
      float: right;
      transition: $small;
    }

    a:hover {
      background: $petrol;
      color: $white;
    }

    .icon-bitcoin:after {
      background: #f7931a;
    }

    .icon-bitcoin:before {
      left: 12px !important;
      transform: rotate(-12deg);
      color: $white;
    }

    .icon-btc:after {
      background: #f7931a;
    }

    .icon-btc:before {
      left: 12px !important;
      transform: rotate(-12deg);
      color: $white;
    }

    .icon-eth:after {
      background: #f7f9fc;
    }
  }

  .transBox {
    float: left;
    width: 100%;
    margin-top: 30px;
    position: relative;

    i {
      float: left;
      position: relative;
    }

    i:after {
      content: '';
      position: relative;
      float: left;
      border-radius: 100%;
      width: 47px;
      height: 47px;
      background: $greyblue;
      opacity: 0.5;
      margin-right: 10px;
      border: 1px solid $greyblue;
    }

    i:before {
      position: absolute;
      z-index: 1;
      font-size: 10px;
      top: 17px;
      left: 15px;
    }

    .transDetails {
      font-size: 18px;
      color: $black;
      font-weight: 600;
      line-height: 16px;
      float: left;
      width: 70%;
      margin-top: 5px;

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        opacity: 0.6;
        color: $black;
        float: left;
        width: 100%;
        margin-top: 5px;
      }
    }

    .transAmount {
      float: right;
      font-size: 18px;
      font-weight: 600;
      line-height: 16px;
      margin-top: 14px;
    }
  }

  .transBox.send .transAmount {
    color: $red;
  }

  .transBox.receive .transAmount {
    color: $green;
  }

  .transBox.canceled .transAmount {
    color: $grey;
    text-decoration: line-through;
  }

  .hasPromo .mainContent {
    margin: 240px auto 0 auto;
  }

  .hasPromo .myTrans {
    max-height: calc(100vh - 260px);
  }
</style>

<script>
  import CurrencyWallet from './CurrencyWallet.vue';
  import TransactionRow from './TransactionRow.vue';
  import services from '../../@libs/services';
  import Transfer from '../GiftCards/Transfer';
  import PendingCardsBanner from '../@components/PendingCards.vue';
  import Banner from '../@components/Banner.vue';
  import ClosingBanner from '../../@@layouts/Default/ClosingBanner/ClosingBanner.vue';

  export default {
    name: 'Dashboard',
    components: { ClosingBanner, Banner, PendingCardsBanner, Transfer, TransactionRow, CurrencyWallet },
    data() {
      return {
        balances: [],
        transactions: [],
        isLoading: false,
        isLoadingBalances: false,
        isLoadingTransactions: false,
        showTransfer: false,
        selectedBalance: null,
        showSuccessRedeemCardBanner: false,
        bannerData: {
          label: '',
          message: '',
          color: 'info',
        },
      };
    },
    computed: {
      filteredBalances() {
        const emptyBalances = [
          { amount: 0, currency: 'eth' },
          { amount: 0, currency: 'btc' },
          { amount: 0, currency: 'usdc' },
          { amount: 0, currency: 'usdt' },
        ];

        const unfilteredBalances = this.balances.length > 0 ? this.balances : emptyBalances;

        if (this.containsPermission('PurchaseCrypto')) return unfilteredBalances;

        return unfilteredBalances.filter((a) => a.currency !== 'usd');
      },
      isVerified() {
        return (
          this.$store.state.userDetails.userDetail.isLevel2 ||
          this.$store.state.userDetails.userDetail.hasUploadedDocuments
        );
      },
      userId() {
        return this.$store.state.userDetails.userDetail.id;
      },
      showPendingTransactionBanner() {
        return (
          this.transactions &&
          this.transactions.filter((transaction) => transaction.status === 'pending').length > 0
        );
      },
      hasBalances() {
        return this.balances && this.balances.length > 0;
      },
      hasTransactions() {
        return this.transactions && this.transactions.length > 0;
      },
    },
    methods: {
      containsPermission(permission) {
        return this.$store.getters['userDetails/permissions'].some(
          (a) => a.toLowerCase() === permission.toLowerCase(),
        );
      },
      formatDate(dateString) {
        const date = new Date(dateString);

        return date.toLocaleString();
      },
      startTransfer(selectedBalance) {
        if (selectedBalance.currency === 'usd') this.$router.push({ path: '/purchase-crypto' });

        this.selectedBalance = selectedBalance;
        this.showTransfer = true;
      },
      async cancelTransfer() {
        this.showTransfer = false;
        await this.loadData();
      },
      async loadData() {
        await Promise.all([this.loadBalances(), this.loadTransactions()]);
      },
      async loadBalances() {
        if (!this.isVerified) return;
        try {
          this.isLoadingBalances = true;
          const balances = await services.projectionService.getAccountBalances(this.userId);
          this.balances = balances;
        } finally {
          this.isLoadingBalances = false;
        }
      },
      async loadTransactions() {
        if (!this.isVerified) return;
        try {
          this.isLoadingTransactions = true;
          const transactions = await services.projectionService.getTransactions(this.userId);
          this.transactions = transactions;
        } finally {
          this.isLoadingTransactions = false;
        }
      },
    },
    async mounted() {
      await this.loadData();
      if (this.$route.query?.showBanner) {
        this.showSuccessRedeemCardBanner = !!this.$route.query?.showBanner;
        this.$router.replace({ path: '/dashboard' });
      }
    },
  };
</script>
