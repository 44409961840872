import Vue from 'vue';
import Vuex from 'vuex';

import userDetailsStore from './modules/userDetailsStore';
import groupDetailsStore from './modules/groupDetailsStore';

Vue.use(Vuex);

const store = new Vuex.Store(
  {
    strict: process.env.NODE_ENV !== 'production', // enable string during development
    modules: {
      userDetails: userDetailsStore,
      groupDetails: groupDetailsStore,
    },
  },
);

export default store;
