<template>
  <div v-if="show">
    <div
      class="banner-container"
      :style="{
        background: mapColors().background,
        borderColor: mapColors().main,
        color: mapColors().main,
        boxShadow: '0px 15px 24px ' + mapColors().background,
      }"
    >
      <i class="banner-close" @click="$emit('dismiss')" :style="{ color: mapColors().main }" />
      <div class="texts">
        <label for="message" :style="{ color: mapColors().main }">
          {{ label }}
        </label>
        <h3 :style="{ color: mapColors().main }">{{ message }}</h3>
      </div>
      <div
        v-if="autohide"
        class="line-animation"
        :style="{ 'animation-duration': timeOut + 'ms' }"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';
  @import '../../@assets//styles//icons.scss';

  .line-animation {
    height: 2px;
    background-color: $white;
    width: 100%;
    animation-name: shrinkLine;
    animation-timing-function: linear;
    animation-duration: 5s;
    margin-top: 7px;
  }

  @keyframes shrinkLine {
    0% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }

  .banner-close {
    align-self: end;
    color: $black;
    position: relative;
    top: -10px;
    margin-bottom: -40px;
    transition: $small;
    &::before {
      transition: $small;
      font-size: 3.5rem;
      content: 'x';
      font-style: normal;
    }
    &:hover {
      cursor: pointer;
      color: $white;
    }
  }

  .banner-close:hover:before {
    cursor: pointer;
    color: $white;
  }

  .banner-container {
    display: flex;
    flex-direction: column;
    color: white;
    padding: 15px;
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 15px 24px 0px rgba(39, 174, 96, 0.15);
    border: 2px solid rgba(39, 174, 96, 0.5);
    .texts {
      width: 90%;
    }
    label {
      font-size: 2.6rem;
      font-weight: 800;
      line-height: 2.3rem;
      color: $white;
      word-break: break-word;
    }
    h3 {
      line-height: 2.5rem;
      font-weight: 600;
      font-size: 2.2rem;
      color: $white;
      word-break: break-word;
    }
  }
</style>

<script>
  import debounce from 'lodash/debounce';
  import isBoolean from 'lodash/isBoolean';

  export default {
    name: 'Banner',
    props: {
      autohide: {
        type: [Boolean, Number],
        default: false,
      },
      show: {
        type: Boolean,
        required: true,
      },
      label: {
        type: String,
        default: '',
      },
      message: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'success' || 'info' || 'error',
        validator(color) {
          return ['success', 'info', 'error'].includes(color);
        },
      },
    },
    methods: {
      mapColors() {
        const colorMapping = {
          success: {
            background: 'rgba(39, 174, 96, 0.15)',
            main: 'rgba(39, 174, 96, 1)',
          },
          info: {
            background: 'rgba(242, 153, 74, 0.15)',
            main: 'rgba(242, 153, 74, 1)',
          },
          error: {
            background: 'rgba(235, 87, 87, 0.15)',
            main: 'rgba(235, 87, 87, 1)',
          },
        };
        return colorMapping[this.color];
      },
      updateShow() {
        this.$emit('update:show', false);
      },
      timeOut() {
        return isBoolean(this.autohide) ? 5000 : this.autohide;
      },
    },
    watch: {
      show: {
        handler(newVal) {
          if (this.autohide && newVal) {
            debounce(this.updateShow, this.timeOut())();
          }
        },
        immediate: true,
      },
    },
  };
</script>
