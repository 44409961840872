<template>
  <div class="container">
    <div v-if="showMenu" class="popover-menu">
      <div class="menu-header">
        <ExitIconButton style="font-size: medium" @click="closeMenu" />
        <h3>Help Assistant</h3>
      </div>
      <div class="inner-menu-container">
        <div v-if="newTicket" class="new-ticket-container">
          <form @submit.prevent.stop="createTicket()">
            <h3 style="font-size: 3rem; margin-top: 0px; margin-bottom: 30px">
              New Support Ticket
            </h3>
            <div class="new-ticket-subject-input">
              <p class="subject-input-header">Subject</p>
              <input class="subject-input" placeholder="Subject" v-model.trim="userInputSubject" />
            </div>
            <div class="text-area-inner">
              <p class="text-area-header">Message</p>
              <textarea v-model.trim="userInputMessage" class="text-area" placeholder="Message" />
            </div>
            <div class="send-button">
              <InputButton
                type="submit"
                color="primary"
                :isLoading="isLoading"
                label="Create Ticket"
                :disabled="disabledSendMessageButton"
              />
            </div>
          </form>
        </div>
        <div v-else>
          <p>Did you try our FAQ page?</p>
          <ul>
            <li style="list-style-type: disc">
              <a class="links" href="/faq">Frequently Asked Questions (FAQ)</a>
            </li>
          </ul>
          <p>Your support tickets</p>
          <ul>
            <li style="list-style-type: disc">
              <router-link class="links" :to="`/tickets?pageUrl=${this.pageUrl}`">
                Show tickets
              </router-link>
            </li>
          </ul>

          <div class="open-ticket-container">
            <p>Have a question?</p>
            <div class="open-ticket-btn">
              <InputButton
                @click="newTicket = true"
                type="button"
                label="Open New Ticket"
                color="blueCTA"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="help-icon-button" @click="toggleMenu" type="button">
      <i class="icon-question" />
    </button>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';
  @import '../../@assets/styles/icons.scss';

  .container {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }
  .icon-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  p {
    line-height: 1.5rem;
  }
  .popover-menu {
    position: absolute;
    min-width: 40rem;
    min-height: 50rem;
    bottom: 9.5rem;
    right: 10rem;
    background-color: $white;
    box-shadow: $boxshadow;
    border: 1px solid #ccc;
    border-radius: 15px;
    z-index: 1000;
    .inner-menu-container {
      padding: 15px 15px;
      min-height: 43rem;
      .success-message {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        bottom: 40%;
        right: 0;
        left: 0;
        .success-text {
          color: $grey;
          font-size: 2.4rem;
        }
        .icon-check {
          font-size: 2.4rem;
        }
      }
    }
    .menu-header {
      padding: 10px 20px;
      background-color: $blue;
      overflow: hidden;
      border-radius: 15px 15px 0px 0px;
      width: 100%;
      z-index: 1010;
      h3 {
        font-size: 2rem;
        color: $white;
      }
    }

    .open-ticket-container {
      position: absolute;
      bottom: 1.5rem;
      width: 91%;
      .open-ticket-btn {
        display: flex;
        justify-content: center;
      }
    }
  }

  .links {
    display: block;
    margin: 2rem 0px 3rem 0px;
    font-size: 1.8rem;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }

  .new-ticket-container {
    width: 100%;
    height: auto;
    padding: 5px;

    .new-ticket-subject-input {
      margin-bottom: 30px;
      .subject-input-header {
        margin-left: 3px;
        line-height: 0.5px;
      }
      .subject-input {
        width: 100%;
        padding: 12px;
        background: $white;
        border-radius: 10px;
        border: solid 1px #ccc;
        font-style: normal;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #0134aa;
        &:focus {
          outline: 1px solid $petrol;
        }
      }
    }

    .text-area-header {
      line-height: 0.5px;
      margin-left: 3px;
    }
    .text-area-inner {
      width: 100%;
      .text-area {
        min-height: 80px;
        width: 100%;
        height: 120px;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid #ccc;
        &:focus {
          outline: 1px solid $petrol;
        }
      }
    }

    .send-button {
      margin-top: 20px;
      .cyanCTA {
        font-size: 1.5rem;
        min-width: 40px;
      }
    }
  }

  .help-icon-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5rem;
    border-radius: 1rem;
    background-color: $petrol;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: $blue !important;
    }
    .icon-question {
      font-size: 4rem;
    }
  }

  @media screen and (max-width: 700px) {
    .popover-menu {
      min-width: 20rem;
      min-height: 30rem;
      bottom: 10rem;
      right: 9rem;
    }
  }
</style>

<script>
  import services from '../../@libs/services';
  import ExitIconButton from './ExitIconButton.vue';
  import InputButton from './InputButton.vue';

  export default {
    name: 'HelpButton',
    components: { ExitIconButton, InputButton },
    data() {
      return {
        showMenu: false,
        userInputMessage: '',
        userInputSubject: '',
        isLoading: false,
        newTicket: false,
      };
    },
    computed: {
      disabledSendMessageButton() {
        return this.userInputMessage === '' || this.userInputSubject === '';
      },
      pageUrl() {
        return this.$route.fullPath;
      },
      loggedIn() {
        return this.$store.state.userDetails.loggedIn;
      },
      emailAddress() {
        if (!this.loggedIn) return '';
        return this.$store.state.userDetails.userDetail.emailAddress;
      },
    },
    watch: {
      $route(to, from) {
        this.showMenu = false;
      },
    },
    methods: {
      async createTicket() {
        this.isLoading = true;
        try {
          const ticket = await services.ticketService.createTicket({
            title: this.userInputSubject,
            source: 'bitjem',
            pageUrl: '/tickets',
            email: this.emailAddress,
          });

          const ticketMessage = await services.ticketService.createTicketMessage(
            ticket.id,
            this.userInputMessage,
          );
          await this.$router.push({ path: `/tickets/${ticket.id}` });
        } finally {
          this.isLoading = false;
          this.clearData();
        }
      },
      clearData() {
        this.userInputMessage = null;
        this.showMenu = false;
        this.showSuccess = false;
        this.newTicket = false;
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
        this.newTicket = false;
      },
      closeMenu() {
        this.showMenu = false;
        this.newTicket = false;
      },
    },
  };
</script>
