<template>
  <div>
    <VerifyEmailAddress v-if="requiresEmailVerification" />
    <VerifyPhoneNumber v-else-if="requiresPhoneVerification" />
    <div
      v-if="!requiresEmailVerification && !requiresPhoneVerification"
      class="mainContainer successVerification"
    >
      <div class="innerContainer">
        <div class="confirmation">
          <i class="icon-check" />
          <h2>
            Thank you for validating your email and phone number
            <span>
              Please
              <a class="textLink" @click="logout"> log out </a>
              and back in to continue
            </span>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';

  .successVerification {
    text-align: center;
    width: 50%;
    position: absolute;
    right: 11%;

    h2 {
      font-size: 30px;
      color: $black;
      text-align: center;
      font-weight: 500;
      span {
        text-align: center;
      }
    }
    .icon-check {
      display: inline-block;
      position: relative;
    }

    .icon-check:before {
      z-index: 1;
      position: absolute;
      top: 35px;
      left: 32px;
      font-size: 30px;
    }

    .icon-check:after {
      content: '';
      width: 100px;
      height: 100px;
      background: #e2ebff;
      position: relative;
      display: inline-block;
      border-radius: 100px;
      margin-bottom: 30px;
      opacity: 0.4;
      border: 1px solid $lightblue;
    }

    .confirmation {
      float: left;
      font-size: 30px;
      line-height: 34px;
      width: 100%;

      span {
        font-size: 20px;
        float: left;
        width: 100%;
        color: $grey;
        margin-top: 50px;
      }
    }
  }

  @media screen and (max-width:990px){
    .successVerification{
      width:unset;
      right:unset;
    }
  }
</style>

<script>
  import VerifyEmailAddress from './VerifyEmailAddress';
  import VerifyPhoneNumber from './VerifyPhoneNumber';
  import services from '../../@libs/services';

  export default {
    name: 'Verification.vue',
    components: { VerifyPhoneNumber, VerifyEmailAddress },
    computed: {
      requiresEmailVerification() {
        return !this.userDetail?.emailVerified || false;
      },
      requiresPhoneVerification() {
        return !this.userDetail?.phoneVerified || false;
      },
      userDetail() {
        return this.$store.state.userDetails?.userDetail;
      },
    },
    methods: {
      async logout() {
        await services.userDetailService.logout();
        await this.$router.push({ path: '/login' });
      },
    },
  };
</script>
