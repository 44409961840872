var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.exit)?_c('div',[_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"modalWrapper step2"},[_c('Banner',{attrs:{"show":_vm.showTip,"color":"info","label":"Info","message":"We strongly recommend non US-based users submit a passport to verify their identity. Other forms of identification may result in failing verification and locking your account."},on:{"update:show":function($event){_vm.showTip=$event},"dismiss":function($event){_vm.showTip = !_vm.showTip}}}),_c('br'),_c('ExitIconButton',{on:{"click":function($event){return _vm.exited()}}}),_vm._m(0),_c('div',{class:['verifyBox', { active: _vm.selected[1] }],attrs:{"id":"leftSide"},on:{"click":function($event){return _vm.select(1)}}},[_c('div',{staticClass:"radioSelect"}),_vm._m(1),_vm._m(2),_c('div',{staticClass:"idSubtitle"},[_vm._v("(Face photo page)")])]),_c('div',{class:['verifyBox', { active: _vm.selected[0] }],attrs:{"id":"rightSide"},on:{"click":function($event){return _vm.select(0)}}},[_c('div',{staticClass:"radioSelect"}),_vm._m(3),_vm._m(4),_c('div',{staticClass:"idSubtitle"},[_vm._v("(Front and back)")])]),_c('div',{staticClass:"fullRow"},[_c('InputButton',{attrs:{"label":"Next","type":"button","color":"primary"},on:{"click":function($event){_vm.$emit('input', _vm.selectedValue);
          _vm.next();}}}),_c('InputButton',{attrs:{"color":"secondary","label":"Back","type":"button","disabled":_vm.disableBackButton},on:{"click":_vm.back}}),_c('InputButton',{attrs:{"label":"Cancel","color":"secondary"},on:{"click":function($event){return _vm.exited()}}})],1)],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modalTitle"},[_c('div',{staticClass:"big"},[_vm._v("Verify your identity")]),_c('div',{staticClass:"small"},[_vm._v("Upload your ID or Passport")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconWrapper"},[_c('i',{staticClass:"icon-passport"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idTitle"},[_vm._v(" Upload "),_c('br'),_vm._v(" Passport ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconWrapper"},[_c('i',{staticClass:"icon-car"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idTitle"},[_vm._v(" Upload "),_c('br'),_vm._v(" Driver's Licence or Govt. Issued Id ")])
}]

export { render, staticRenderFns }