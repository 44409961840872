<template>
  <div>
    <table>
      <tr>
        <th>
          Card Code
        </th>
        <th>
          Value
        </th>
        <th>
          Date Redeemed
        </th>
        <th>
          Wallet Address
        </th>
        <th>
          Tx Id
        </th>
      </tr>
      <tr v-for="card in cards" :key="card.id">
        <td>
          {{ card.code }}
        </td>
        <td>
          ${{ card.value }}
        </td>
        <td>
          {{ card.redeemed }}
        </td>
        <td>
          {{ card.walletAddress }}
        </td>
        <td>
          {{ card.transactionId }}
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped>

</style>

<script>
  import services from '../../../@libs/services';

  export default {
    name: 'RedeemedCards',
    data() {
      return {
        loading: false,
        cards: [],
      };
    },
    methods: {
      async loadData() {
        this.loading = true;

        try {
          const cards = await services.giftCardService.listAllUsersRedeemedCards();
          this.cards = cards;
        } finally {
          this.loading = false;
        }
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>
