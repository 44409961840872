<template>
  <div id="content">
    <Header />
    <slot />
    <Footer />
  </div>
</template>

<script>
  import Header from '../../App/Layout/Header.vue';
  import Footer from '../../App/Layout/Footer.vue';
  import services from '../../@libs/services';
  import { refreshInterval } from '../../@libs/eventBus/globalEventHandlers';

  export default {
    name: 'Default',
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        drawer: false,
      };
    },
    methods: {
      async logout() {
        await services.userDetailService.logout();
        this.$router.push({ path: '/' });
      },
    },
    computed: {
      loggedIn() {
        return this.$store.state.userDetails.loggedIn;
      },
      emailAddress() {
        if (!this.$store.state.userDetails.loggedIn) return '';

        return this.$store.state.userDetails.userDetail.emailAddress;
      },
      groupId() {
        return this.$store.state.groupDetails.groupId;
      },
      groupName() {
        return this.$store.state.groupDetails.groupName;
      },
    },
    async mounted() {
      await services.groupDetailService.getConfig();
    },
    beforeDestroy() {
      clearInterval(refreshInterval);
    },
  };
</script>

<style lang="scss">
  @import '../../@assets/styles/_global.scss';
  @import '../../@assets/styles/_common.scss';
</style>
