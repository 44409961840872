import baseService from '../baseService';
import { apiEndpoints } from '../../../@enums';

export default {
  ...baseService,
  apiEndpoints: apiEndpoints.projections,

  /**
   * Get user dashboard
   * @returns {Promise<string|*>}
   */
  async getGroupDashboard() {
    const result = await this.httpClient.get(apiEndpoints.projections.groupDashboard);

    return result;
  },

  async getAccountBalances(userId) {
    const result = await this.httpClient.get(apiEndpoints.userDetails.balances, {
      replace: {
        id: userId,
      },
    });

    return result;
  },

  async getTransactions(userId) {
    const result = await this.httpClient.get(apiEndpoints.userDetails.transactions, {
      replace: {
        id: userId,
      },
    });

    return result;
  },
};
