module.exports = {
  // BTC
  btc: {
    currency: 'btc',
    decimalPlaces: 8,
    icon: 'bitcoin',
    display: 'Bitcoin',
  },
  usd: {
    currency: 'usd',
    decimalPlaces: 2,
    icon: 'dollar',
    display: 'USD',
  },
  usdt: {
    currency: 'usdt',
    decimalPlaces: 6,
    icon: 'tether',
    display: 'USDT (ETHEREUM NETWORK)',
  },
  usdc: {
    currency: 'usdc',
    decimalPlaces: 6,
    icon: 'usdc',
    display: 'USDC (ETHEREUM NETWORK)',
  },
  eth: {
    currency: 'eth',
    decimalPlaces: 9,
    icon: 'eth',
    display: 'Ethereum',
  },
  // matic: {
  //   currency: 'matic',
  //   decimalPlaces: 9,
  //   icon: 'matic',
  //   display: 'Matic (POLYGON NETWORK)',
  // },
  // Add more currencies ...
};
