<template>
  <div>
    <v-app>
      <div id="header">
        <button type="button" class="mobileToggle" @click="mobileOpen = !mobileOpen">
          <i class="icon-menu" />
        </button>
        <a href="/dashboard">
          <img class="logo" src="../../@assets/img/dashboard/logo.svg" alt="logo" />
        </a>
        <span class="current-page">{{ currentPage }}</span>
        <span class="app-name">{{ groupName }}</span>
        <div
          v-if="loggedIn"
          :class="['userAccount', { open: userAccountOpen }]"
          @click="userAccountOpen = !userAccountOpen"
          id="userAccount"
          @focusout="userAccountOpen = false"
          tabindex="0"
        >
          <div class="userImg">{{ initial }}</div>
          <span>{{ emailAddress }}</span>
          <i class="icon-chevron" />
          <ul class="userMenu">
            <li>
              <router-link to="/account-details"><i class="icon-profile" />Account</router-link>
            </li>
            <li>
              <router-link to="/giftCard/redemption"><i class="icon-cards" />Cards</router-link>
            </li>
            <li>
              <a @click="logout"><i class="icon-logout" />Log out</a>
            </li>
          </ul>
        </div>
      </div>

      <div :class="{ hasPromo: hasKycBanner }">
        <Navigation v-if="loggedIn && !requires2Fa" :mobile-open.sync="mobileOpen" />
        <KycBanner v-if="loggedIn && !requires2Fa" />
        <slot />
      </div>
      <HelpButton v-if="loggedIn && !requires2Fa" />
      <v-footer app>
        <!-- -->
      </v-footer>
    </v-app>
  </div>
</template>

<style lang="scss">
  @import '../../@assets/styles/_global.scss';
  @import '../../@assets/styles/_common.scss';
  @import '../../@assets/styles/_dashboard.scss';
</style>

<script>
  import services from '../../@libs/services';
  import { refreshInterval } from '../../@libs/eventBus/globalEventHandlers';
  import Navigation from './Navigation';
  import HelpButton from '../../App/@components/HelpButton.vue';
  import KycBanner from './KycBanner/KycBanner.vue';

  export default {
    name: 'Default',
    components: { KycBanner, Navigation, HelpButton },
    data() {
      return {
        drawer: false,
        userAccountOpen: false,
        mobileOpen: false,
      };
    },
    computed: {
      hasKycBanner() {
        return (
          this.$store.state.userDetails.hasPendingCards &&
          !this.$store.state.userDetails.userDetail.isLevel2
        );
      },
      currentPage() {
        return this.$route.name || '';
      },
      loggedIn() {
        return this.$store.state.userDetails.loggedIn;
      },
      requires2Fa() {
        if (Array.isArray(this.$store.state.userDetails.jwt.permissions)) {
          return this.$store.state.userDetails.jwt.permissions.some((a) => a === 'OtpLogin');
        }
        return this.$store.state.userDetails.jwt.permissions === 'OtpLogin';
      },
      emailAddress() {
        if (!this.loggedIn) return '';

        return this.$store.state.userDetails.userDetail.emailAddress;
      },
      initial() {
        if (!this.loggedIn) return '';

        return this.emailAddress.split('')[0].toUpperCase();
      },
      groupId() {
        return this.$store.state.groupDetails.groupId;
      },
      groupName() {
        return this.$store.state.groupDetails.groupName;
      },
    },
    async mounted() {
      await services.groupDetailService.getConfig();
    },
    beforeDestroy() {
      clearInterval(refreshInterval);
    },
    methods: {
      async logout() {
        await this.$store.commit('userDetails/logout');
        await this.$router.push({ path: '/login' });
      },
    },
  };
</script>
