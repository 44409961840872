import baseService from '../baseService';
import { apiEndpoints } from '../../../@enums';

// just for mocking
const pause = (pauseMs) => new Promise((resolve) => {
  setTimeout(() => resolve(), pauseMs);
});
// just for mocking
const tHour = 60 * 60 * 1000;

const mockTickets = [
  {
    id: '1',
    status: 'open',
    title: 'Failed to pass KYC',
    pageUrl: '/kyc',
    email: 'user@bitjem.com',
    source: 'bitjem',
    assignedUserId: '123918204192031',
    category: 'Account Creation',
    created: 1693139007937 - tHour,
    updated: 1693139007937,
    messages: [
      {
        id: '1',
        ticketId: '1',
        message: 'For some reason I am not able to get past step 2',
        isFromEndUser: true,
        userId: null,
        created: 1693139007937 - tHour,
        updated: 1693139007937 - tHour,
      },
      {
        id: '2',
        ticketId: '1',
        message: 'This happened because you are not uploading a valid passport photo',
        isFromEndUser: false,
        userId: null,
        created: 1693139007937 - tHour,
        updated: 1693139007937 - tHour,
      },
    ],
  },
  {
    id: '2',
    status: 'closed',
    title: 'Not able to verify phone number',
    pageUrl: '/login',
    email: 'user@bitjem.com',
    source: 'bitjem',
    assignedUserId: '123918204192031',
    category: 'Account Creation',
    created: 1693139007937 - 2 * tHour,
    updated: 1693139007937 - 2 * tHour,
    messages: [],
  },
];

export default {
  ...baseService,
  apiEndpoints: apiEndpoints.ledgers,

  async createTicket(ticket) {
    if (!ticket) {
      throw new Error('Missing required params');
    }
    const result = await this.httpClient.post(apiEndpoints.tickets.post, {
      payload: ticket,
    });

    return result;
  },
  async createTicketMessage(ticketId, message) {
    if (!ticketId || !message) {
      throw new Error('Missing required params');
    }
    const result = await this.httpClient.post(apiEndpoints.tickets.postTicketReply, {
      payload: {
        ticketId,
        message,
        isFromEndUser: true,
      },
    });

    return result;
  },
  async changeTicketStatus(ticketId, status) {
    if (!ticketId || !status) {
      throw new Error('Missing required params');
    }
    const result = await this.httpClient.put(apiEndpoints.tickets.putTicketStatus, {
      replace: {
        ticketId,
      },
      payload: {
        status,
        id: ticketId,
      },
    });

    return result;
  },
  async getTicket(id) {
    if (!id) {
      throw new Error('Missing required params');
    }
    const result = await this.httpClient.get(apiEndpoints.tickets.get, {
      replace: {
        id,
      },
    });

    return result;
  },
  async getTickets() {
    const result = await this.httpClient.get(apiEndpoints.tickets.root);

    return result;
  },
  async uploadFileToTicket(ticketId, formData) {
    if (!ticketId || !formData) {
      throw new Error('Missing required params');
    }
    const result = await this.httpClient.put(apiEndpoints.tickets.putTicketFileUpload, {
      replace: {
        ticketId,
      },
      includeContentType: false,
      form: formData,
    });

    return result;
  },
};
