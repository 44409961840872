/**
 * Base class to give access to standard entity methods
 */
import httpClient from '../httpClient';

export default {
  httpClient,

  /**
   * Get an entity by its id
   * @param id Required id to retrieve
   * @returns {Promise<string|*>}
   */
  async findById(id) {
    const url = this.apiEndpoints.get;
    const result = await this.httpClient.get(url, {
      replace: {
        id,
      },
    });

    return result;
  },

  async getGasFees(id) {
    const url = this.apiEndpoints.getGasFees;
    const result = await this.httpClient.get(url, {
      replace: {
        id,
      },
    });

    return result;
  },

  /**
   * List all items for a given domain
   * @returns {Promise<string|*>}
   */
  async listAll() {
    const url = this.apiEndpoints.listAll;
    const result = await this.httpClient.get(url, {});

    return result;
  },

  /**
   * Put an entity by id
   * @param id Required id of entity to pu
   * @param entity Required entity to pass for put
   * @returns {Promise<string|*>}
   */
  async putById(id, entity) {
    const url = this.apiEndpoints.put;
    const result = await this.httpClient.put(url, {
      payload: entity,
      replace: {
        id,
      },
    });

    return result;
  },

  /**
   * Delete an entity by id
   * @param id Required id of entity to delete
   * @returns {Promise<string|*>}
   */
  async deleteById(id) {
    const url = this.apiEndpoints.delete;
    const result = await this.httpClient.delete(url, {
      replace: {
        id,
      },
    });

    return result;
  },

  /**
   * Post a new entity
   * @param entity Required new entity to post
   * @returns {Promise<string|*>}
   */
  async postNew(entity) {
    const url = this.apiEndpoints.post;
    const result = await this.httpClient.post(url, {
      payload: entity,
    });

    return result;
  },
};
