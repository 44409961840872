import baseService from '../baseService';
import { apiEndpoints } from '../../../@enums';

export default {
  ...baseService,
  apiEndpoints: apiEndpoints.ledgers,

  async searchLedgers(startDate, endDate) {
    const filterString = `?page=1&filters[0][column]=timestamp&filters[0][type]=$gte&filters[0][value]=${startDate}&filters[1][column]=timestamp&filters[1][type]=$lte&filters[1][value]=${endDate}`;
    const result = await this.httpClient.get(apiEndpoints.ledgers.searchLedgers + filterString, {});

    return result;
  },
  async searchLedgerEntries(startDate, endDate) {
    const filterString = `?page=1&limit=2147483647&filters[0][column]=timestamp&filters[0][type]=$gte&filters[0][value]=${startDate}&filters[1][column]=timestamp&filters[1][type]=$lte&filters[1][value]=${endDate}`;
    const result = await this.httpClient.get(apiEndpoints.ledgers.searchLedgerEntries + filterString, {});

    return result;
  },
};
