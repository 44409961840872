import baseService from '../baseService';
import store from '../../../@store';
import { apiEndpoints } from '../../../@enums';

export default {
  ...baseService,
  apiEndpoints: apiEndpoints.groupDetails,

  /**
   * Gets group details
   * @returns {Promise<void>}
   */
  async getConfig() {
    const result = await this.httpClient.get(apiEndpoints.groupDetails.config);

    const { id } = result || {};

    if (id) await store.commit('groupDetails/setGroupDetails', { groupDetails: result });

    // TODO: handle the group not being found...
  },
};
