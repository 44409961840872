<template>
  <div class="walletBox">
    <div class="content">
      <i :class="`icon-${currencyData.icon}`" />

      <div class="walletAmount">
        {{ parseAmount }}
        <span>{{ currencyData.currency }}</span>
      </div>
    </div>
    <div class="withdraw-button">
      <InputButton
        class="input-button"
        :label="currencyLabel"
        type="button"
        color="primary"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '../../@assets/styles/variables.scss';

  .walletBox {
    width: 100%;
    float: left;
    // border: 1px solid $greyblue;
    // border-radius: 20px;
    // box-shadow: 0px 0px 74px 0px rgba(1, 52, 170, 0.09);
    // padding: 1.56vw;
    // margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input-button {
      min-width: 10vw;
      font-size: 1.6rem;
    }
    i {
      float: left;
      position: relative;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    i:after {
      content: '';
      position: relative;
      float: left;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      border: 1px solid $greyblue;
    }
    i:before {
      position: absolute;
      z-index: 1;
      font-size: 20px;
    }
    .walletAmount {
      float: left;
      color: $black;
      font-size: 1.93vw;
      line-height: 1.93vw;
      font-weight: 300;
      position: relative;
      top: 4px;
      span {
        font-size: 16px;
        line-height: 16px;
        position: relative;
        top: -6px;
        margin-left: 10px;
      }
    }
    a {
      font-size: 15px;
      padding: 10px 15px;
      line-height: 15px;
      border: 1px solid $petrol;
      font-weight: 500;
      color: $petrol;
      border-radius: 5px;
      float: right;
      transition: $small;
    }
    a:hover {
      background: $petrol;
      color: $white;
    }

    .icon-bitcoin:after {
      background: #f7931a;
    }
    .icon-bitcoin:before {
      left: 12px !important;
      transform: rotate(-12deg);
      color: $white;
    }
    .icon-eth:after {
      background: #f7f9fc;
    }
  }
  @media screen and (max-width: 1300px) {
    .input-button {
      min-width: 12rem !important;
      font-size: 1.5rem;
    }
    .myBalance .walletBox {
      flex-direction: column;
      gap: 20px;
      top: 0;
    }

    .myBalance .walletBox .content {
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 1100px) {
    .input-button {
      min-width: 12rem !important;
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width: 1000px) {
    .input-button {
      min-width: 25rem !important;
    }
  }

  @media screen and (max-width: 600px) {
    .input-button {
      min-width: 15rem !important;
    }
  }
</style>

<script>
  import InputButton from '../@components/InputButton.vue';
  import Currencies from '../../@enums/currencies';

  export default {
    name: 'CurrencyWallet',
    components: { InputButton },
    props: {
      currency: {
        type: String,
        required: true,
      },
      amount: {
        type: String,
        default: '0.0',
        required: true,
      },
    },
    data() {
      return {
        currencies: Currencies,
      };
    },
    computed: {
      currencyLabel() {
        return this.currency !== 'usd' ? 'Withdraw' : 'Purchase Crypto';
      },
      parseAmount() {
        return parseFloat(this.amount).toFixed(this.currencyData.decimalPlaces);
      },
      currencyData() {
        return this.currencies[this.currency];
      },
    },
  };
</script>
