import Moment from 'moment';
import eventBus from './eventBus';
import services from '../services';

const REFRESH_IN_MINUTES = 30;

const registerGlobalEventHandlers = () => {
  const nextRefresh = new Moment();
  nextRefresh.add(REFRESH_IN_MINUTES, 'minutes');

  /**
   * Display login to console just to exercise the event bus
   */
  eventBus.on({
    name: eventBus.eventTypes.app.loginSuccess,
    callback: (payload) => {
      console.log('Login Successful: ', payload);
    },
  });

  /**
   * Update active timeout on http requests
   */
  eventBus.on({
    name: eventBus.eventTypes.http.loadingStarted,
    callback: () => localStorage.setItem('lastActiveTime', new Moment().format()),
  });

  /**
   * update active timeout
   */
  eventBus.on({
    name: eventBus.eventTypes.navigation.succeeded,
    callback: () => localStorage.setItem('lastActiveTime', new Moment().format()),
  });

  /**
   * Refresh app login token
   */
  eventBus.on({
    name: eventBus.eventTypes.app.refreshLogin,
    callback: async () => {
      const lastActive = new Moment(localStorage.getItem('lastActiveTime'));
      const activeWindow = new Moment();

      activeWindow.add(-1, 'minutes');
      if (!activeWindow.isBefore(lastActive) || nextRefresh.isAfter(new Moment())) return;

      nextRefresh.add(REFRESH_IN_MINUTES, 'minutes');
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) return;
      await services.userDetailService.loginWithRefreshToken(refreshToken);
    },
  });
};

export const refreshInterval = setInterval(
  () => eventBus.raiseEvent({ name: eventBus.eventTypes.app.refreshLogin }),
  10000, // 10 seconds
);

export default registerGlobalEventHandlers;
